import React, { useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, CardActions, Box, Chip, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import HomeNavbar from '../components/home/HomeNavbar';
import FAQ from '../components/service/FAQ';
import Footer from '../components/home/Footer';
import { Check } from '@mui/icons-material';
import empowerBusinessImage from "../assets/images/empower_business.jpg"
import { useNavigate } from 'react-router-dom';

const Pricing = () => {

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const plans = [
    {
      title: 'Starter',
      description:"Get started with full access to all essential tools including image models, perfect for individuals.",
      price: '$ 195/mo',
      features: [
        '1 user seat',
        "Unlimited models",
        "1M dataset rows",
        "CSV only",
        "100 MB image data",
        "Unlimited tabular model trainings",
        "15 image model trainings",
        "1.500 predictions",
        'Classification & regression models',
        "Image Classification & Object Detection",
        '1 model API deployment',
        "E-mail support (24-hour response)",
        "Cancel anytime",
      ],
      buttonText: 'Start Free Trial',
      buttonVariant: 'outlined',
      textColor: "#383a6e",
      backgroundColor: "transparent",
      onclick: () => navigate("/platform/signup")
    },
    {
      title: 'Professional',
      description:"Scale up your projects with more advanced tasks and expanded data capacities.",
      price: '$ 995/mo',
      features: [
        '5 user seat',
        "Unlimited models",
        "10M dataset rows",
        "CSV and DB connectors",
        "1 GB image data",
        "Unlimited tabular model trainings",
        "150 image model trainings",
        "150.000 predictions",
        'Classification & Regression & Clustering & Time Series Models',
        "Image Classification & Object Detection & Segmentation",
        '5 model API deployment',
        "Team collaboration with in-app Kanban board",
        "Chat or Call support (Slack, Zoom, Teams)",
        "Cancel anytime",
      ],
      buttonText: 'Book a Demo',
      buttonVariant: 'contained',
      textColor: "white",
      backgroundColor: "#383a6e",
      onclick: () => navigate("/request_demo")
    },
    {
      title: 'Enterprise',
      description:"Unlimited data handling and fully customizable features to meet your enterprise's specific needs.",
      price: 'Custom',
      features: [
        'Custom number of user seat',
        "Unlimited models",
        "Custom number of dataset rows",
        "Custom connectors",
        "Custom size of image data",
        "Unlimited tabular model trainings",
        "Custom number of image model trainings",
        'Custom number of predictions',
        'Classification & Regression & Clustering & Time Series Models',
        "Image Classification & Object Detection & Segmentation",
        'Custom number of model API deployment',
        "Team collaboration with in-app Kanban board",
        "Dedicated account manager",
        "White-labeling",
        "Cancel anytime",
      ],
      buttonText: 'Contact Sales',
      buttonVariant: 'contained',
      textColor: "white",
      backgroundColor: "#383a6e",
      onclick: () => navigate("/request_demo")
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
        <HomeNavbar />
        <Box sx={{ width: "100%", height:{xs:"200px", md:"270px"}, display:"flex", flexDirection:"column", alignItems:"center", background: 'linear-gradient(to bottom, #2e1866, #000000)'}}>
            <Box sx={{width:{xs:"90%", md:"60%"}, display:"flex", flexDirection:"column", alignItems:"center", marginTop:{xs:"50px", md:"100px"} }}>
                <Typography variant='h2' textAlign={"center"} width={"80%"} fontWeight={900} color={"white"} fontSize={{xs:"10vw", md:"3.5vw"}}>Pricing and Features</Typography>
            </Box>
        </Box>
        <Box sx={{width:"120%", overflowX:"hidden", height:"100px", marginTop:"-50px", marginLeft:"-10%", background:"#2e1866", transform:"rotate(-2deg);"}}></Box>
        <Container maxWidth="xxl" sx={{ marginTop: {xs:"50px", md:'100px'}, display:"flex", flexDirection:"column", alignItems:"center"}}>
            <Grid container spacing={4} justifyContent="center">
                {plans.map((plan) => (
                    <Grid item key={plan.title} md={3}>
                        <Card elevation={3}>
                            <CardContent>
                                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", paddingRight:"20px"}}>
                                    <Typography variant='h4' fontWeight={900}>{plan.title}</Typography>
                                </Box>
                                <Typography variant='h6' sx={{fontWeight:400, fontSize:"14pt", marginTop:"10px", marginBottom:"10px", color:"#555555"}}>{plan.description}</Typography>
                                <hr />
                                <Typography variant='h4' fontWeight={400} color="primary" fontSize={"32pt"} sx={{marginTop:"20px"}}>{plan.price}</Typography>
                                {plan.title == "Starter" ? (
                                    <Typography color="primary" fontWeight={400} fontSize={"14pt"} sx={{textAlign:"left", marginTop:"10px"}}>+ $495/mo for optional dedicated data scientist service</Typography>
                                ) : (plan.title == "Professional" ? (
                                    <Typography color="primary" fontWeight={400} fontSize={"14pt"} sx={{textAlign:"left", marginTop:"10px"}}>+ $495/mo for optional dedicated data scientist service</Typography>
                                ) : (
                                    <></>
                                ))}
                                {plan.title == "Starter" ? (
                                    <Typography sx={{fontSize:"11pt", textAlign:"center", marginTop:"10px", color:"#555555"}}>7 days FREE trial. No credit card required.</Typography>
                                ) : (
                                    <Typography sx={{fontSize:"11pt", textAlign:"center", marginTop:"10px"}}>&nbsp;</Typography>
                                )}
                                <Button fullWidth variant={plan.buttonVariant} color="primary" sx={{marginTop:"10px", height:"50px", backgroundColor:plan.backgroundColor, color:plan.textColor, borderColor:"#383a6e", ":hover":{backgroundColor:"#686dba", color:"white"}}} onClick={plan.onclick}>
                                    {plan.buttonText}
                                </Button>
                                <TableContainer sx={{marginTop:"10px"}}>
                                    <Table>
                                        <TableBody>
                                            {plan.features.map((line, index) => (
                                                <TableRow>
                                                    <TableCell sx={{display:"flex", flexDirection:"row", gap:"10px", paddingY:"10px"}}>
                                                        <Check color="success" />
                                                        <Typography>{line}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={plan.buttonVariant} sx={{height:"50px", backgroundColor:plan.backgroundColor, color:plan.textColor, borderColor:"#383a6e", ":hover":{backgroundColor:"#686dba", color:"white"}}} onClick={plan.onclick}>
                                    {plan.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, width:{xs:"95%", md:"65%"}, marginLeft:"5%", marginTop:"150px"}}>
                <Box>
                    <Typography variant='h3' fontWeight={400} fontSize={{xs:"7vw", md:"2.7vw"}}>Empower your business with our dedicated data scientists</Typography>
                    <Typography variant='h5' fontWeight={300} color={"textSecondary"} marginTop={"20px"} fontSize={{xs:"5vw", md:"1.5vw"}}>Our experts help you leverage advanced analytics, machine learning, and AI to drive informed decisions, optimize operations, and fuel innovation.</Typography>
                </Box>
                <Box sx={{height:{xs:"55vw", md:"250px" }, marginLeft:{xs:"0", md:"50px"}, marginTop:{xs:"0", md:"-30px"}}}>
                    <img src={empowerBusinessImage} style={{width:"auto", height:"100%"}} />
                </Box>
            </Box>
            <Box sx={{display:"flex", flexDirection:{xs:"column", md:"row"}, marginTop:"60px", gap:"40px", width:{xs:"100%", md:"65%"}, marginLeft:"5%", padding:{xs:"30px", md:"50px"}, boxSizing:"border-box", borderRadius:"10px", justifyContent:"center", alignItems:"center", backgroundColor:"#e3dcf5"}}>
                <Typography variant='h6' fontWeight={400}>Get our dedicated data scientist service for anything from data to ML models. <br />10 hours per month.</Typography>
                <Chip sx={{backgroundColor:'#2e1866', height:"50px", width:"200px", color:"white", fontSize:"18pt"}} label={"+ $ 495/mo"} />
            </Box>
        </Container>
        <Container>

        </Container>
        <FAQ />
        <Footer />
    </Box>
  );
};

export default Pricing;
