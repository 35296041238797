import styled from '@emotion/styled';
import { Analytics, Api, ArrowForwardIos, ArrowRight, Assignment, CropFree, Dataset, Folder, Image, Insights, Label, Lightbulb, Polyline } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { BarChart, ChartsLegend, LineChart, PieChart } from '@mui/x-charts';
import React, { useEffect, useState } from 'react'
import { api, assignment, dataset, imageDataset, license, model, team, user } from '../apis/urls';
import { getRequest } from '../apis/requests';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    marginTop: 30,
}));

const getGreenToRed = (percent) => {
    if (percent) {
        let r = percent<70 ? 255 : Math.floor(255-(percent*2-100)*255/140);
        let g = percent>70 ? 200 : Math.floor((percent*2)*200/140);
        return 'rgb('+Math.max(r, 0)+','+Math.max(g, 0)+',0)';
    }
    return "#eeeeee";
}

const QuickItem = ({item}) => {
    
    return (
        <Paper elevation={3} sx={{display:"flex", flexDirection:"column", flex:1, paddingY:1, paddingX:2, paddingRight:3}}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                <Box sx={{display:"flex", flexDirection:"row", gap:1, marginTop:0, justifyContent:"flex-start", alignItems:"center"}}>
                    {item.icon}
                    <Typography fontSize={"36pt"} fontWeight={700} color="#474545">{item.count}</Typography>
                    <Typography fontSize={"14pt"} marginTop={2} color="#474545">{item.text}</Typography>
                </Box>
                <ArrowForwardIos sx={{color:item.color, cursor:"pointer"}} onClick={item.onClick} />
            </Box>
            <Typography variant='subtitle2' sx={{fontSize:"12pt", fontWeight:300, color:"#474545", marginTop:0, marginLeft:1}}>{item.subtext}</Typography>
            <Button variant='outlined' fullWidth sx={{marginTop:1, marginX:1, color:item.color, borderColor:item.color, ":hover":{backgroundColor:item.color, borderColor:item.color, color:"white"}}} onClick={item.onClick}>{item.buttonText}</Button>
        </Paper>
    )
}

const Dashboard = () => {

    const authToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("userId");
    const theme = useTheme();
    const navigate = useNavigate();
    if (!authToken) {
        navigate("/platform/login");
    }

    const [quickItems, setQuickItems] = useState([]);
    const [sortedQuickItems, setSortedQuickItems] = useState([]);

    const [userData, setUserData] = useState(null);
    const [teams, setTeams] = useState([]);
    const [assignments, setAssignments] = useState(null);
    const [modelStatuses, setModelStatuses] = useState(null);

    const [userDataLoading, setUserDataLoading] = useState(false);
    const [teamsLoading, setTeamsLoading] = useState(false);
    const [tabularDatasetsLoading, setTabularDatasetsLoading] = useState(false);
    const [imageDatasetsLoading, setImageDatasetsLoading] = useState(false);
    const [modelsLoading, setModelsLoading] = useState(false);
    const [apisLoading, setApisLoading] = useState(false);
    const [assignmentsLoading, setAssignmentsLoading] = useState(false);

    const fetchUserData = () => {
        setUserDataLoading(true);
        const url = user.getUser();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                if(data && data["user_license"]["license_key"] != data["user_license"]["license_origin"]){
                    const url = license.getUserLicenseByLicenseKey(data["user_license"]["license_origin"])
                    const headers = {
                        "Authorization": authToken
                    }
                    getRequest(url, headers)
                        .then(data => {
                            setUserData(data);
                        })
                    
                }
                else{
                    setUserData(data);
                }
                setUserDataLoading(false);
            });
    }

    const fetchTeams = () => {
        setTeamsLoading(true);
        const url = team.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setTeams(data);             
                setTeamsLoading(false);
            })
    }

    const fetchTabularDatasets = () => {
        setTabularDatasetsLoading(true);
        const url = dataset.getByUserId();
        const headers = {
            "Content-Type": "application/json",
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                if (data){
                    setQuickItems(items => [...items, 
                        {
                            order: 1,
                            icon: <Dataset sx={{width:"50px", height:"50px", color:"#5555dd"}} />,
                            count: data.length,
                            text: "tabular datasets",
                            subtext: "Upload your data and transform",
                            buttonText: "Upload",
                            color: "#5555dd",
                            onClick: () => {navigate("/platform/datasets")}
                        }])
                    setTabularDatasetsLoading(false);
                }
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchImageDatasets = () => {
        setImageDatasetsLoading(true);
        const url = imageDataset.getByUserId();
        const headers = {
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                if(data) {
                    setQuickItems(items => [...items, 
                        {
                            order: 2,
                            icon: <Image sx={{width:"50px", height:"50px", color:"#ff3374"}} />,
                            count: data.length,
                            text: "image datasets",
                            subtext: "Upload your images and annotate",
                            buttonText: "Upload",
                            color: "#ff3374",
                            onClick: () => {navigate("/platform/image_datasets")}
                        }])
                    setImageDatasetsLoading(false);
                }
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchModels = () => {
        setModelsLoading(true);
        const url = model.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                if(data) {
                    setModelStatuses(data.map(item => item["status"]).reduce(function (value, value2) {
                        return (
                            value[value2] ? ++value[value2] :(value[value2] = 1),
                            value
                        );
                    }, {}));
                    setQuickItems(items => [...items, 
                        {
                            order: 3,
                            icon: <Analytics sx={{width:"50px", height:"50px", color:"#33ff77"}} />,
                            count: data.length,
                            text: "models",
                            subtext: "Create your ML models",
                            buttonText: "Create",
                            color: "#33ff77",
                            onClick: () => {navigate("/platform/models")}
                        }])
                    setModelsLoading(false);
                }
            })
    }

    const fetchApis = () => {
        setApisLoading(true);
        const url = api.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                if(data) {
                    setQuickItems(items => [...items, 
                        {
                            order: 4,
                            icon: <Api sx={{width:"50px", height:"50px", color:"#33d6ff"}} />,
                            count: data.length,
                            text: "APIs",
                            subtext: "Deploy APIs for real time predictions",
                            buttonText: "Deploy",
                            color: "#33d6ff",
                            onClick: () => {navigate("/platform/apis")}
                        }])
                    setApisLoading(false);
                }
            })
    }

    const fetchAssignments = () => {
        setAssignmentsLoading(true);
        const url = assignment.getByUserId();
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                if (data) {
                    let toDoItemCount = 0;
                    let inProgressItemCount = 0;
                    let completedItemCount = 0;

                    data.forEach(item => {
                        if(item["stage"] == "to_do"){
                            toDoItemCount += 1;
                        }
                        else if(item["stage"] == "in_progress"){
                            inProgressItemCount += 1;
                        }
                        else if(item["stage"] == "completed"){
                            completedItemCount += 1;
                        }
                    });

                    setAssignments({
                        "to_do": toDoItemCount,
                        "in_progress": inProgressItemCount,
                        "completed": completedItemCount,
                        "max": Math.max(toDoItemCount, inProgressItemCount, completedItemCount)
                    })
                }
                else {
                    setAssignments(null)
                }
                setAssignmentsLoading(false);
            })
    }

    useEffect(() => {
        setQuickItems([]);
        fetchUserData();
        fetchTabularDatasets();
        fetchImageDatasets();
        fetchModels();
        fetchApis();
        fetchTeams();
        fetchAssignments();
    }, []);

    const sortByOrder = (array) => {
        return array.sort((a, b) => a.order - b.order);
    };

    useEffect(() => {
        setSortedQuickItems(sortByOrder(quickItems));
    }, [quickItems])

  return (
    <Box sx={{ width: "95%" }}>
        <Typography variant='h5'>Utimind AI - Dashboard</Typography>
        <PageContainer>
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                {tabularDatasetsLoading || imageDatasetsLoading || modelsLoading || apisLoading ? (
                    [
                        {
                            order: 1,
                            icon: <Dataset sx={{width:"50px", height:"50px", color:"#5555dd"}} />,
                            count: <CircularProgress />,
                            text: "tabular datasets",
                            subtext: "Upload your data and transform",
                            buttonText: "Upload",
                            color: "#5555dd",
                            onClick: null
                        },
                        {
                            order: 2,
                            icon: <Image sx={{width:"50px", height:"50px", color:"#ff3374"}} />,
                            count: <CircularProgress />,
                            text: "image datasets",
                            subtext: "Upload your images and annotate",
                            buttonText: "Upload",
                            color: "#ff3374",
                            onClick: null
                        },
                        {
                            order: 3,
                            icon: <Analytics sx={{width:"50px", height:"50px", color:"#33ff77"}} />,
                            count: <CircularProgress />,
                            text: "models",
                            subtext: "Create your ML models",
                            buttonText: "Create",
                            color: "#33ff77",
                            onClick: null
                        },
                        {
                            order: 4,
                            icon: <Api sx={{width:"50px", height:"50px", color:"#33d6ff"}} />,
                            count: <CircularProgress />,
                            text: "APIs",
                            subtext: "Deploy APIs for real time predictions",
                            buttonText: "Deploy",
                            onClick: null
                        }
                    ].map(item => {
                        return (<QuickItem item={item} />)
                    })
                ) : (
                    quickItems && quickItems.map(item => {
                        return (<QuickItem item={item} />)
                    })
                )}
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">Your teams</Typography>
                        <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/team_management")}>{`Manage your teams >`}</Button>
                    </Box>
                    <TableContainer sx={{borderRadius:"5px", maxHeight:"165px"}}>
                        {teamsLoading ? (
                            <CircularProgress />
                        ) : (
                            teams ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Team Name</TableCell>
                                            <TableCell>Number of Members</TableCell>
                                            <TableCell>Your Role</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {teams.map(team => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{team.name}</TableCell>
                                                    <TableCell>{team.members.length}</TableCell>
                                                    <TableCell>{team.owner == userId ? ("Owner") : ("Member")}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography>Cannot fetch the teams at the moment.</Typography>
                            )
                        )}
                    </TableContainer>
                </Paper>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, minHeight:"240px", boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">Your tasks</Typography>
                        <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/project_management")}>{`Manage your tasks >`}</Button>
                    </Box>
                    {assignmentsLoading ? (
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        (assignments == null) ? (
                            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                                <Typography>Cannot fetch the assignments at the moment</Typography>
                            </Box>
                        ) : (
                            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"flex-start", gap:"10px", height:"100%", marginTop:"20px", borderLeft:"1px solid #dddddd", borderBottom:"1px solid #dddddd", paddingLeft:"2px", paddingBottom:"2px"}}>
                                {["to_do", "in_progress", "completed"].map(key => {
                                    return (
                                        <Box sx={{display:"flex", width:"100%", height:"100%"}}>
                                            {assignments["max"] != 0 ? (
                                                <>
                                                    <Box sx={{display:"flex", width:`${Math.max(10, assignments[key]*90/assignments["max"])}%`, height:"100%", borderRadius:"3px", alignItems:"center", backgroundColor: key == "to_do" ? "#ffeee6" : key == "in_progress" ? "#ebf6f9": "#e6ffe6"}}>
                                                        <Typography>{key == "to_do" ? "To Do" : key == "in_progress" ? "In Progress" : "Completed"}</Typography>
                                                    </Box>
                                                    <Typography display={"flex"} alignItems={"center"} marginLeft={"2%"}>{assignments[key]}</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Box sx={{display:"flex", width:`${Math.max(10, 0)}%`, height:"100%", borderRadius:"3px", alignItems:"center", backgroundColor: key == "to_do" ? "#ffeee6" : key == "in_progress" ? "#ebf6f9": "#e6ffe6"}}>
                                                        <Typography>{key == "to_do" ? "To Do" : key == "in_progress" ? "In Progress" : "Completed"}</Typography>
                                                    </Box>
                                                    <Typography display={"flex"} alignItems={"center"} marginLeft={"2%"}>{assignments[key]}</Typography>
                                                </>
                                            )}
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    )}
                </Paper>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, minHeight:"240px", boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">Your models</Typography>
                        <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/models")}>{`Manage your models >`}</Button>
                    </Box>
                    {modelsLoading ? (
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                            <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#0288d1", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                <Typography fontSize={"40pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}><CircularProgress /></Typography>
                                <Typography fontSize={"14pt"} fontWeight={400} color="white">IN PROGRESS</Typography>
                            </Box>
                            <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#2e7d32", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                <Typography fontSize={"40pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}><CircularProgress /></Typography>
                                <Typography fontSize={"14pt"} fontWeight={400} color="white">COMPLETED</Typography>
                            </Box>
                            <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#d32f2f", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                <Typography fontSize={"40pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}><CircularProgress /></Typography>
                                <Typography fontSize={"14pt"} fontWeight={400} color="white">FAILED</Typography>
                            </Box>
                        </Box>
                    ) : (
                        (modelStatuses == null) ? (
                            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                                <Typography>Cannot fetch the model statuses at the moment</Typography>
                            </Box>
                        ) : (
                            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                                <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#0288d1", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                    <Typography fontSize={"28pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}>{modelStatuses["IN_PROGRESS"] ? modelStatuses["IN_PROGRESS"] : 0}</Typography>
                                    <Typography fontSize={"12pt"} fontWeight={400} textAlign={"center"} color="white">models <br />in progress</Typography>
                                </Box>
                                <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#2e7d32", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                    <Typography fontSize={"28pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}>{modelStatuses["COMPLETED"] ? modelStatuses["COMPLETED"] : 0}</Typography>
                                    <Typography fontSize={"12pt"} fontWeight={400} textAlign={"center"} color="white">models <br />completed</Typography>
                                </Box>
                                <Box sx={{flex:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", backgroundColor:"#d32f2f", height:"100%", borderRadius:"10px", gap:"10px"}}>
                                    <Typography fontSize={"28pt"} fontWeight={700} color="#474545" sx={{backgroundColor:"white", paddingX:"10px", borderRadius:"5px"}}>{modelStatuses["FAILED"] ? modelStatuses["FAILED"] : 0}</Typography>
                                    <Typography fontSize={"12pt"} fontWeight={400} textAlign={"center"} color="white">models <br />failed</Typography>
                                </Box>
                            </Box>
                        )
                    )}
                </Paper>
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">License Usages</Typography>
                        <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/team_management")}>{`Get extra usages >`}</Button>
                    </Box>
                    {!userDataLoading ? (
                        (userData && userData["user_license"]) ? (
                            <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                                {["remaining_members", "remaining_rows", "remaining_tabular_model_trainings", "remaining_image_model_trainings", "remaining_image_size", "remaining_predictions", "remaining_ai_suggestions"].map(key => {
                                    let totalAmount = {
                                        "remaining_members" : userData["user_license"]["license_type"] == "TRIAL" ? 1 : userData["user_license"]["license_type"] == "STARTER" ? 1 : 5,
                                        "remaining_rows" : userData["user_license"]["license_type"] == "TRIAL" ? 10000 : userData["user_license"]["license_type"] == "STARTER" ? 1000000 : 10000000,
                                        "remaining_tabular_model_trainings" : userData["user_license"]["license_type"] == "TRIAL" ? 10 : userData["user_license"]["license_type"] == "STARTER" ? "Unlimited" : "Unlimited",
                                        "remaining_image_model_trainings" : userData["user_license"]["license_type"] == "TRIAL" ? 3 : userData["user_license"]["license_type"] == "STARTER" ? 15 : 150,
                                        "remaining_image_size" : userData["user_license"]["license_type"] == "TRIAL" ? 20*1024 : userData["user_license"]["license_type"] == "STARTER" ? 100*1024 : 1024*1024,
                                        "remaining_predictions" : userData["user_license"]["license_type"] == "TRIAL" ? 120 : userData["user_license"]["license_type"] == "STARTER" ? 1500 : 150000,
                                        "remaining_ai_suggestions" : userData["user_license"]["license_type"] == "TRIAL" ? 3 : userData["user_license"]["license_type"] == "STARTER" ? 60 : 600,
                                    }
                                    let units = {
                                        "remaining_members" : "members",
                                        "remaining_rows" : "rows",
                                        "remaining_tabular_model_trainings" : "trainings",
                                        "remaining_image_model_trainings" : "trainings",
                                        "remaining_image_size" : "MB",
                                        "remaining_predictions" : "predictions",
                                        "remaining_ai_suggestions": "generations"
                                    }
                                    return (
                                        <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-between", padding:1}}>
                                            <Typography sx={{textTransform:"capitalize", width:"90%", textAlign:"center"}}>{key != "remaining_ai_suggestions" ? key.split("_").slice(1).join(" ") : "AI Suggestions"}</Typography>
                                            <Box>
                                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                                    {/* Background Circular Progress (gray) */}
                                                    <CircularProgress
                                                        size={"5.5rem"}
                                                        variant="determinate"
                                                        value={100}
                                                        thickness={4}
                                                        sx={{
                                                            color: '#eeeeee', // Gray color for the background
                                                            position: 'absolute',
                                                            left: 0,
                                                        }}
                                                    />
                                                    {/* Foreground Circular Progress (color based on value) */}
                                                    <CircularProgress
                                                        size={"5.5rem"}
                                                        variant="determinate"
                                                        value={totalAmount[key] != "Unlimited" ? userData["user_license"][key] * 100 / totalAmount[key] : 100}
                                                        thickness={4}
                                                        sx={{
                                                            color: getGreenToRed(totalAmount[key] != "Unlimited" ? userData["user_license"][key] * 100 / totalAmount[key] : 100),
                                                        }}
                                                    />
                                                    <Box sx={{position:"absolute", top:"25%", left:"18%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                                        <Typography sx={{fontSize:"18pt", lineHeight:"18pt", color: getGreenToRed(totalAmount[key] != "Unlimited" ? userData["user_license"][key] * 100 / totalAmount[key] : 100)}}>{(totalAmount[key] != "Unlimited" ? userData["user_license"][key] * 100 / totalAmount[key] : 100).toFixed(0)}%</Typography>
                                                        <Typography color={"#777777"} sx={{lineHeight:"14pt", fontSize:"11pt"}}>remaining</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Typography textAlign={"center"}>{key != "remaining_image_size" ? (totalAmount[key] == "Unlimited" ? "Unlimited" : userData["user_license"][key]) : (userData["user_license"][key] / 1024).toFixed(0)} / {key != "remaining_image_size" ? totalAmount[key] : totalAmount[key] / 1024}<br />{units[key]} remaining</Typography>
                                        </Paper>
                                    )
                                })}
                            </Box>
                        ) : (
                            <Typography>Cannot fetch the license usage data at the moment</Typography>
                        )
                    ) : (
                        <CircularProgress />
                    )}
                </Paper>
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">What's new?</Typography>
                    </Box>
                    <List sx={{borderRadius:"5px", maxHeight:"165px", overflowY:"scroll"}}>
                        <ListItem>
                            <ListItemIcon>
                                <Insights />
                            </ListItemIcon>
                            <ListItemText
                                primary="AI-generated actionable suggestions to improve your business"
                            />
                            <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/models")}>
                                {`Try >`}
                            </Button>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <Polyline />
                            </ListItemIcon>
                            <ListItemText
                                primary="Image segmentation annotation tools and algorithms"
                            />
                            <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/image_datasets")}>
                                {`Try >`}
                            </Button>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <Assignment />
                            </ListItemIcon>
                            <ListItemText
                                primary="Maximize your team work efficiency with in-app Kanban board. "
                            />
                            <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/project_management")}>
                                {`Try >`}
                            </Button>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemIcon>
                                <CropFree />
                            </ListItemIcon>
                            <ListItemText
                                primary="Handle object detection tasks with newly added YOLOv10 model"
                            />
                            <Button variant='text' sx={{textTransform:"none"}} onClick={() => navigate("/platform/models")}>
                                {`Try >`}
                            </Button>
                        </ListItem>
                        <Divider />
                    </List>
                </Paper>
                <Paper elevation={3} sx={{flex:1, display:"flex", flexDirection:"column", padding:2, boxSizing:"border-box"}}>
                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <Typography variant='h6' color="#474545">Resources</Typography>
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"100%", gap:"10px", marginTop:"20px"}}>
                        <Box sx={{flex:3, position:"relative", display:"flex", flexDirection:"row", width:"100%"}}>
                            <Box sx={{width:"100px", height:"100px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#686dba", borderRadius:"50%", zIndex:1}}>
                                <Lightbulb sx={{height:"80%", width:"80%", padding:"10%", borderRadius:"50%", boxSizing:"border-box", color:"#eeeeee", backgroundColor:"#383a6e"}} />
                            </Box>
                            <Box sx={{position:"absolute", height:"120%", width:"87%", left:"7%", top:"-10%", backgroundColor:"#e3dcf5", borderRadius:"10px", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                <Typography sx={{marginLeft:"15%", fontSize:"14pt", fontWeight:900, color:"#383a6e"}}>New to Utimind?</Typography>
                                <Typography sx={{marginLeft:"15%", fontSize:"14pt", color:"#383a6e"}}>Get Started with Our Easy-to-Follow Beginner’s Guide!</Typography>
                            </Box>
                        </Box>
                        <Box sx={{flex:2, display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-evenly", marginTop:"15px"}}>
                            <Button variant='text' sx={{textTransform:"none", fontSize:"12pt", color:"#383a6e"}}>{`Documentation >`}</Button>
                            <Button variant='text' sx={{textTransform:"none", fontSize:"12pt", color:"#383a6e"}} onClick={() => navigate("/use_case", {state: {caseField: "manufacturing"}})}>{`Use Cases >`}</Button>
                            <Button variant='text' sx={{textTransform:"none", fontSize:"12pt", color:"#383a6e"}}>{`Blog >`}</Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </PageContainer>
    </Box>
  )
}

export default Dashboard