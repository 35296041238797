import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Check for existing auth state in localStorage when initializing state
        const isAuth = localStorage.getItem('isAuthenticated');
        return isAuth === 'true'; // Convert string to boolean
    });

    const login = (data) => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true'); // Persist auth state
        localStorage.setItem('userId', data["id"]); // Persist auth state
        localStorage.setItem("authToken", `Token ${data["token"]}`)
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.setItem('isAuthenticated', 'false'); // Clear auth state
        localStorage.setItem("userId", null);
        localStorage.setItem("authToken", null)
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};