// store/index.js
import { configureStore } from '@reduxjs/toolkit';
import imageDataReducer from './imageDataSlice';
import labelOptionsReducer from "./labelOptionsSlice";

const store = configureStore({
  reducer: {
    imageData: imageDataReducer,
    labelOptions: labelOptionsReducer,
  },
});

export default store;
