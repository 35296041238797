import { Alert, Box, Button, Modal, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { postRequest } from '../../apis/requests';
import { team } from '../../apis/urls';

const JoinTeamModal = ({open, handleClose}) => {

    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [teamCode, setTeamCode] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleCloseLocally = () => {
        handleClose();
    }

    const handleJoin = () => {
        if(teamCode && teamCode != ""){
            const url = team.joinTeam(teamCode);
            const headers = {
                "Content-Type": "application/json",
                "Authorization": authToken
            };
            const body = JSON.stringify({
                "team_code": teamCode
            });
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok){
                        handleSnackbarOpen("success", "Team join request has been sent to team admin successfully.")
                        handleCloseLocally();
                        return;
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if (data) {
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        else{
            handleSnackbarOpen("error", "Please provide a team code");
        }
    }

  return (
    <Box>
        <Modal
            open={open}
            onClose={handleCloseLocally}>
            <Box sx={{position: 'absolute',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"space-between",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "500px",
                    height: "250px",
                    bgcolor: theme.palette.text.light,
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,}}>
                <Box>
                    <Typography variant='h5'>Join a Team</Typography>
                    <TextField onChange={(e) => setTeamCode(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleJoin();
                            }}
                        label="Team Code" type='text' variant="outlined" sx={{
                            mt: 6,
                            width: "100%"
                    }} />
                </Box>

                <Button onClick={handleJoin} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: "30%",
                    alignSelf: "flex-end"
                }}>
                    Join
                </Button>
            </Box>
        </Modal>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default JoinTeamModal