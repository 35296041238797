import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, List, ListItem, ListItemText, IconButton, ListItemAvatar, Avatar, LinearProgress, Snackbar, Alert, Select, MenuItem, InputLabel, FormControl, useTheme, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { imageDataset, user } from '../../apis/urls';
import { getRequest } from '../../apis/requests';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600, // Increase the width of the modal
    height: 670, // Increase the height of the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ImageUploadModal = ({ isOpen, onRequestClose, fetchImages }) => {

    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [datasetName, setDatasetName] = useState('');
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedTaskType, setSelectedTaskType] = useState(null);
    const [quitDialogOpen, setQuitDialogOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");
    
    const [userData, setUserData] = useState(null);

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        const url = user.getUser();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
              setUserData(data);
            });
      }, [])

    useEffect(() => {
        if (uploading) {
            const handleBeforeUnload = (event) => {
                event.preventDefault();
                event.returnValue = 'Upload is in progress. Are you sure you want to leave?';
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }
    }, [uploading]);

    const handleImageChange = (e) => {
        setImages([...e.target.files]);
    };

    const handleDatasetNameChange = (e) => {
        setDatasetName(e.target.value);
    };

    const handleUpload = async () => {
        if(datasetName == null || datasetName == ""){
            handleSnackbarOpen("error", "Please enter a dataset name.");
            return;
        }
        if (!selectedTaskType) {
            handleSnackbarOpen("error", "Please select a task type");
            return;
        }
        if (images.length == 0) {
            handleSnackbarOpen("error", "Please select at least an image");
            return;
        }

        setUploading(true);
        const formData = new FormData();
        formData.append('name', datasetName);
        formData.append('task_type', selectedTaskType);
        for (let i = 0; i < images.length; i++) {
            formData.append('images', images[i]);
        }

        const xhr = new XMLHttpRequest();
        const url = imageDataset.create();
        xhr.open('POST', url, true);
        xhr.setRequestHeader("Authorization", authToken);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded / event.total) * 100);
                setProgress(percentCompleted);
            }
        };

        xhr.onload = () => {
            if (xhr.status === 200) {
                handleSnackbarOpen("success", "Images have been uploaded successfully.");
                fetchImages();
                handleQuitDialogClose(true);
            }
            else{
                handleSnackbarOpen("success", "Error uploading images.");
            }
            setUploading(false);
        };

        xhr.onerror = () => {
            setUploading(false);
        };

        xhr.send(formData);
    };

    const handleRemoveImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const formatSize = (size) => {
        const units = ['Bytes', 'KB', 'MB', 'GB'];
        let unitIndex = 0;
        let sizeValue = size;
        while (sizeValue >= 1024 && unitIndex < units.length - 1) {
            sizeValue /= 1024;
            unitIndex++;
        }
        return `${sizeValue.toFixed(2)} ${units[unitIndex]}`;
    };

    const getImagePreview = (image) => {
        return URL.createObjectURL(image);
    };

    const handleCloseLocally = () => {
        setQuitDialogOpen(true);
      }

    const resetFields = () => {
        setDatasetName("");
        setImages([]);
        setUploading(false);
        setProgress(0);
        setSelectedTaskType(null);

        onRequestClose();
    }


    const handleQuitDialogClose = (quitApproved) => {
        
        if(quitApproved){
            resetFields();
            onRequestClose();
        }
        setQuitDialogOpen(false);
    }

    return (
        <Box>
            <Modal
                open={isOpen}
                onClose={uploading ? null : handleCloseLocally}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Upload Images
                    </Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Dataset Name"
                        variant="outlined"
                        value={datasetName}
                        onChange={handleDatasetNameChange}
                        disabled={uploading}
                    />
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        disabled={uploading}
                    >
                        Select Images
                        <input
                            type="file"
                            multiple
                            hidden
                            onChange={handleImageChange}
                            disabled={uploading}
                        />
                    </Button>
                    <Box sx={{ height: 300, overflowY: 'auto', border: '1px solid #ccc', padding: 1 }}>
                        <List>
                            {Array.from(images).map((image, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveImage(index)} disabled={uploading}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar src={getImagePreview(image)} variant="square" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={image.name}
                                        secondary={formatSize(image.size)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    {uploading && (
                        <Box sx={{ width: '100%', marginTop: 2 }}>
                            <LinearProgress variant="determinate" value={progress} />
                            <Typography variant="body2" color="textSecondary">{`Uploading ${progress}%`}</Typography>
                        </Box>
                    )}
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Select task type</InputLabel>
                        <Select
                            value={selectedTaskType}
                            label="Select task type"
                            onChange={(e) => {setSelectedTaskType(e.target.value)}}
                            sx={{
                                width: "100%",
                            }}
                        >
                            <MenuItem value={"image_classification"}>Image Classification</MenuItem>
                            <MenuItem value={"object_detection"}>Object Detection</MenuItem>
                            {userData && ["PRO", "ENTERPRISE"].includes(userData["user_license"]["license_type"]) ? (
                                <MenuItem value={"image_segmentation"}>Image Segmentation</MenuItem>
                            ) : (
                                <></>
                            )}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onClick={handleUpload}
                        disabled={uploading}
                    >
                        Start Uploading
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        onClick={handleCloseLocally}
                        disabled={uploading}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            
            <Dialog
                open={quitDialogOpen}
                onClose={() => handleQuitDialogClose(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{fontSize:"12pt", fontWeight:600, backgroundColor:theme.palette.background.main, pt:5}} id="alert-dialog-title">
                    {"Are you sure want to quit? All steps will be discarded."}
                </DialogTitle>
                <DialogContent 
                sx={{backgroundColor:theme.palette.background.main}}>
                    <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                        When you quit creating an image dataset, you will have to start all steps from the beginning. Be sure before perform this action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{backgroundColor:theme.palette.background.main, p:3}}>
                    <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleQuitDialogClose(false)}>Cancel</Button>
                    <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleQuitDialogClose(true)}>Quit</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ImageUploadModal;
