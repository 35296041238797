import React, { useEffect, useState } from 'react'
import { json, useLocation } from 'react-router-dom';
import { dataset } from '../apis/urls';
import { getRequest, postRequest } from '../apis/requests';
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import DataTransformation from '../components/dataset/DataTransformation';
import MergeDatasetModal from '../components/dataset/MergeDatasetModal';
import DataDistribution from '../components/dataset/DataDistributions';

const PageContainer = styled(Box)(( { theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: 30,
}));


const DatasetOverview = () => {

  const authToken = localStorage.getItem('authToken');
  const location = useLocation();
  const dataset_id = location.state["dataset_id"];
  const dataset_name = location.state["dataset_name"];

  const [gridData, setGridData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [rows, setRows] = useState(null);

  const [columnTypes, setColumnTypes] = useState(null);
  const [taskTypesByColumn, setTaskTypesByColumn] = useState(null);
  const [columnsIdealForPrediction, setColumnsIdealForPrediction] = useState(null);

  const [mergeDatesetModalOpen, setMergeDatesetModalOpen] = useState(false);
  const handleOpenMergeDatesetModal = () => setMergeDatesetModalOpen(true);
  const handleCloseMergeDatasetModal = () => setMergeDatesetModalOpen(false);

  const [transformationModalOpen, setTransformationModalOpen] = useState(false);
  const handleTransformationModalOpen = () => setTransformationModalOpen(true);
  const handleCloseTransformationModal = () => setTransformationModalOpen(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("Message");

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
  }

  useEffect(() => {
    fetchDatasetOverview();
    fetchInsightsForModeling();
  }, [dataset_id]);

  const fetchDatasetOverview = () => {
      const url = dataset.getByDatasetId(dataset_id);
      getRequest(url, {"Authorization": authToken})
          .then(data => {
              setGridData(JSON.parse(data));
      })
  };

  const fetchInsightsForModeling = () => {
    const url = dataset.getInsightsForModeling(dataset_id)
    
    const headers = {
        "Content-Type": "application/json",
        "Authorization": authToken
    };

    getRequest(url, headers)
        .then(data => {
          setColumnTypes(data["column_types"]);
          setTaskTypesByColumn(data["task_types_by_column"]);
          setColumnsIdealForPrediction(data["columns_ideal_for_prediction"]);
        })
        .catch((error) => console.error("Error:", error));
}

  useEffect(() => {
    if(gridData){
      setColumns(Object.keys(gridData).map(key => {
        return ({
          field: key,
          headerName: key,
          width: 150
        })
      }))

      const rowsData = [];
      const numRows = Object.keys(Object.values(gridData)[0]).length;

      for (let i = 0; i < numRows; i++) {
        const row = { id: i };
        for (const key of Object.keys(gridData)) {
          row[key] = gridData[key][i];
        }
        rowsData.push(row);
      }
      setRows(rowsData);
    }
  }, [gridData]);

  const applyTransformations = (transformationRequests, saveSeparately) => {
    const url = dataset.applyTransformations(dataset_id);
    const headers = {
      "Authorization": authToken,
      "Content-Type": "application/json"
    }
    const body = JSON.stringify({
      "transformations" : transformationRequests,
      "saveSeparately": saveSeparately
    });
    postRequest(url, headers, body)
      .then(response => response.json())
      .then(data => {
        if(data["error"]){
          handleSnackbarOpen("error", data["error"]);
        }
        else if(data["success"]){
          handleSnackbarOpen("success", data["success"]);
        }
        else{
          handleSnackbarOpen("error", "An error occurred.");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const applyMergeDatasets = (params) => {
    params["leftDataset"] = dataset_id;
    
    const url = dataset.mergeDatasets();
    const headers = {
      "Authorization": authToken,
      "Content-Type": "application/json"
    }
    const body = JSON.stringify(params);

    postRequest(url, headers, body)
      .then(response => response.json())
      .then(data => {
        if(data["success"]) {
          handleSnackbarOpen("success", data["success"]);
          if (!params["saveSeparately"]){
            fetchDatasetOverview();
          }
        }
        else if(data["error"]) {
          handleSnackbarOpen("error", data["error"]);
        }
        else {
          handleSnackbarOpen("error", "An error occurred.");
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>{dataset_name} Dataset</Typography>
        <PageContainer>
          {rows && columns ? (
            <>
              <Box sx={{display:"flex", flexDirection:"row", gap:"10px"}}>
                <Button variant="contained" color="primary" onClick={handleTransformationModalOpen}>Transform Data</Button>
                <Button variant="contained" color="primary" onClick={handleOpenMergeDatesetModal}>Merge Datasets</Button>
              </Box>
              <Box sx={{ height: "500px", marginTop: "30px" }}>
                  <DataGrid
                      rows={rows}
                      columns={columns}
                      checkboxSelection={false}
                      disableRowSelectionOnClick
                      isRowSelectable={() => false}
                      style={{fontWeight: 400, fontSize:"11pt", paddingLeft:"20px"}}
                  />
              </Box>
              <Box sx={{ marginTop:"50px"}}>
                <Typography variant='h5'>{dataset_name} Distributions</Typography>
                {columnTypes && taskTypesByColumn && columnsIdealForPrediction ? (
                  <DataDistribution data={gridData} columnTypes={columnTypes} taskTypesByColumn={taskTypesByColumn} columnsIdealForPrediction={columnsIdealForPrediction} />
                ) : (
                  <CircularProgress />
                )}
              </Box>
              <DataTransformation open={transformationModalOpen} onClose={handleCloseTransformationModal} columns={columns} onApply={applyTransformations} />
              <MergeDatasetModal open={mergeDatesetModalOpen} onClose={handleCloseMergeDatasetModal} onMerge={applyMergeDatasets} leftColumnOptions={columns} />
            </>
          ) : (
              <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress />
              </Box>
          )}
        </PageContainer>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default DatasetOverview