import { CheckCircleOutline } from '@mui/icons-material';
import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material'
import React from 'react'

const UpgradeLicense = () => {
    const theme = useTheme();

    const handleUpgrade = (upgradeTo) => {
        alert("Upgraded");
    }

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"95%", padding:"0px 50px"}}>
        <Typography variant='h5'>Upgrade License</Typography>
        <Box sx={{display:"flex", flexDirection:"row", marginTop:"50px", gap:"40px"}}>
            <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", paddingRight:"20px"}}>
                    <Typography variant='h5'>Pro License</Typography>
                    <Chip label={"Upgrade"} color='success' sx={{cursor:"pointer"}} onClick={() => {handleUpgrade("pro");}} />
                </Box>
                <Typography variant='h7' sx={{fontWeight:300, fontSize:"11pt", marginTop:"10px", marginBottom:"10px"}}>Extended access for individuals and small teams</Typography>
                <hr />
                <Chip sx={{height:"40px", marginTop:"20px", fontSize:"12pt"}} label={"$300/month"} color="primary" />
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>1 User</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>10K Rows of Data</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>1000 Predictions</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CSV Only</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Classification Models</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Regression Models</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>REST APIs</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Email Support</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", paddingRight:"20px"}}>
                    <Typography variant='h5'>Enterprise License</Typography>
                    <Chip label={"Upgrade"} color='success' sx={{cursor:"pointer"}} onClick={() => {handleUpgrade("enterprise");}} />
                </Box>
                <Typography variant='h7' sx={{fontWeight:300, fontSize:"11pt", marginTop:"10px", marginBottom:"10px"}}>Full access with exclusive support for organizations</Typography>
                <hr />
                <Chip sx={{height:"40px", marginTop:"20px", fontSize:"12pt"}} label={"$1.000/month"} color="primary" />
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>1 User</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>10K Rows of Data</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>1000 Predictions</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>CSV Only</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Classification Models</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Regression Models</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>REST APIs</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Email Support</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", paddingRight:"20px"}}>
                    <Typography variant='h5'>Special Needs?</Typography>
                    <Chip label={"Let's talk"} color='success' sx={{cursor:"pointer"}} onClick={() => {handleUpgrade("special");}} />
                </Box>
                <Typography variant='h7' sx={{fontWeight:300, fontSize:"11pt", marginTop:"10px", marginBottom:"10px"}}>Tailored features with a dedicated team of experts</Typography>
                <hr />
            </Box>
        </Box>
    </Box>
  )
}

export default UpgradeLicense