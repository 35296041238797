import { Box, Typography } from '@mui/material'
import React from 'react'

import allInOneImage from "../../assets/images/utimind_all_in_one.png";

const AllInOneSection = () => {
  return (
    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", paddingY:"100px"}}>
        <Typography variant='h3' fontWeight={400} fontSize={{xs:"7.5vw", sm: "7.5vw", md:"3vw"}} textAlign={"center"}>An entire data science team in your pocket</Typography>
        <Box sx={{width:{xs:"100%", md:"55%"}}}>
          <img src={allInOneImage} alt="Utimind" style={{width:"100%", objectFit:"cover"}} />
        </Box>
    </Box>
  )
}

export default AllInOneSection