// DataTransformation.jsx
import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const requestTypesDict = {
  "dropColumns": "Drop Column(s)",
  "typeCasting": "Type Casting",
  "removeDuplicates": "Remove Duplicates",
  "handleMissingValues": "Handle Missing Values",
  "sorting": "Sorting",
  "normalize": "Normalize",
  "scale": "Scale",
  "aggregate": "Aggregate"
}

const DataTransformation = ({ open, onClose, columns, onApply }) => {
  const [transformationType, setTransformationType] = useState("");
  const [transformationParams, setTransformationParams] = useState({});
  const [transformationRequests, setTransformationRequests] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [saveSeparately, setSaveSeparately] = useState(false);

  const handleTransformationTypeChange = (event) => {
    setTransformationType(event.target.value);
    setTransformationParams({});
  };

  const handleTransformationParamChange = (paramName) => (event) => {
    setTransformationParams({
      ...transformationParams,
      [paramName]: event.target.value,
    });
  };

  const handleAddTransformationRequest = () => {
    setTransformationRequests([...transformationRequests, {
      type: transformationType,
      params: transformationParams,
      columns: selectedColumns.filter((e) => (e !== 'apply_to_all_menu_item'))
    }]);
    setTransformationType("");
    setTransformationParams({});
    setSelectedColumns([]);
  };

  const handleRemoveTransformationRequest = (index) => {
    const updatedRequests = [...transformationRequests];
    updatedRequests.splice(index, 1);
    setTransformationRequests(updatedRequests);
  };

  const handleApplyTransformations = () => {
    onApply(transformationRequests, saveSeparately);
    setTransformationType("");
    setTransformationParams({});
    setTransformationRequests([]);
    setSelectedColumns([]);
    setSaveSeparately(false);
    onClose();
  };

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>Transform Data</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} marginRight={2} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
              <Box>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Transformation Type</InputLabel>
                  <Select
                    label="Transformation Type"
                    value={transformationType}
                    onChange={handleTransformationTypeChange}
                  >
                    <MenuItem value="dropColumns">Drop Column(s)</MenuItem>
                    <MenuItem value="typeCasting">Type Casting</MenuItem>
                    <MenuItem value="removeDuplicates">Remove Duplicates</MenuItem>
                    <MenuItem value="handleMissingValues">Handle Missing Values</MenuItem>
                    <MenuItem value="sorting">Sorting</MenuItem>
                    <MenuItem value="normalize">Normalize</MenuItem>
                    <MenuItem value="scale">Scale</MenuItem>
                    <MenuItem value="aggregate">Aggregate</MenuItem>
                    {/* <MenuItem value="customFunction">Custom Function</MenuItem> */}
                  </Select>
                </FormControl>

                {transformationType !== "sorting" && transformationType !== "aggregate" ? (
                  <FormControl sx={{ m: 0, mt:1, width: 570 }}>
                    <InputLabel>Columns</InputLabel>
                    <Select
                      label="Columns"
                      multiple
                      value={selectedColumns}
                      onChange={(e) => {
                        let selectedCols = e.target.value;
                        if (selectedCols.includes("apply_to_all_menu_item") && selectedCols.length <= columns.length){
                          const index = selectedCols.indexOf("apply_to_all_menu_item");
                          if (index > -1) { // only splice array when item is found
                            selectedCols.splice(index, 1); // 2nd parameter means remove one item only
                          }
                        }
                        else if (!selectedCols.includes("apply_to_all_menu_item") && selectedCols.length == columns.length) {
                          selectedCols.push("apply_to_all_menu_item")
                        }
                        setSelectedColumns(selectedCols);
                      }}
                      renderValue={(selectedColumns) => {
                        if (selectedColumns.includes("apply_to_all_menu_item")){
                          return selectedColumns.slice(1).join(', ');
                        }
                        else {
                          return selectedColumns.join(', ');
                        }
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key="apply_to_all_menu_item" value="apply_to_all_menu_item">
                          <Checkbox
                            checked={
                              selectedColumns.findIndex((item) => item === "apply_to_all_menu_item") >= 0
                            }
                            onChange={() => {
                              if (selectedColumns.includes("apply_to_all_menu_item")){
                                setSelectedColumns([]);
                              }
                              else{
                                let selectedCols = ["apply_to_all_menu_item"]
                                columns.map(col => {
                                  selectedCols.push(col.field);
                                })
                                setSelectedColumns(selectedCols);
                              }
                            }}
                          />
                          <ListItemText primary={"Apply to all"} />
                        </MenuItem>
                      {columns.map((col) => (
                        <MenuItem key={col.field} value={col.field}>
                          <Checkbox
                            checked={
                              selectedColumns.findIndex((item) => item === col.field) >= 0
                            }
                          />
                          <ListItemText primary={col.headerName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                

                {transformationType === "typeCasting" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>To Type</InputLabel>
                    <Select
                      label="To Type"
                      value={transformationParams.type || ""}
                      onChange={handleTransformationParamChange("type")}
                    >
                      <MenuItem value="toText">Category</MenuItem>
                      <MenuItem value="toNumber">Number</MenuItem>
                      <MenuItem value="toDatetime">DateTime</MenuItem>
                      <MenuItem value="toBool">Boolean</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {transformationType === "handleMissingValues" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Strategy</InputLabel>
                    <Select
                      label="Strategy"
                      value={transformationParams.method || ""}
                      onChange={handleTransformationParamChange("method")}
                    >
                      <MenuItem value="drop">Drop</MenuItem>
                      <MenuItem value="fillMean">Fill with Mean</MenuItem>
                      <MenuItem value="fillMedian">Fill with Median</MenuItem>
                      <MenuItem value="fillCustom">Fill with Custom Value</MenuItem>
                    </Select>
                    {transformationParams.method === "fillCustom" && (
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Custom Value"
                        value={transformationParams.customValue || ""}
                        onChange={handleTransformationParamChange("customValue")}
                      />
                    )}
                  </FormControl>
                )}

                {transformationType === "sorting" && (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Sort By</InputLabel>
                      <Select
                        label="Sort By"
                        value={transformationParams.by || ""}
                        onChange={handleTransformationParamChange("by")}
                      >
                        {columns.map((col) => (
                          <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Order</InputLabel>
                      <Select
                        label="Order"
                        value={transformationParams.order || ""}
                        onChange={handleTransformationParamChange("order")}
                      >
                        <MenuItem value="asc">Ascending</MenuItem>
                        <MenuItem value="desc">Descending</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                {transformationType === "normalize" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Normalization Method</InputLabel>
                    <Select
                      label="Normalization Method"
                      value={transformationParams.method || ""}
                      onChange={handleTransformationParamChange("method")}
                    >
                      <MenuItem value="minMax">Min-Max</MenuItem>
                      <MenuItem value="zScore">Z-Score</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {transformationType === "scale" && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Scaling Method</InputLabel>
                    <Select
                      label="Scaling Method"
                      value={transformationParams.method || ""}
                      onChange={handleTransformationParamChange("method")}
                    >
                      <MenuItem value="standard">Standard</MenuItem>
                      <MenuItem value="robust">Robust</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {transformationType === "aggregate" && (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Aggregate By</InputLabel>
                      <Select
                        label="Aggregate By"
                        value={transformationParams.by || ""}
                        onChange={handleTransformationParamChange("by")}
                      >
                        {columns.map((col) => (
                          <MenuItem key={col.field} value={col.field}>{col.headerName}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Function</InputLabel>
                      <Select
                        label="Function"
                        value={transformationParams.function || ""}
                        onChange={handleTransformationParamChange("function")}
                      >
                        <MenuItem value="sum">Sum</MenuItem>
                        <MenuItem value="mean">Mean</MenuItem>
                        <MenuItem value="median">Median</MenuItem>
                        <MenuItem value="count">Count</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}

                {transformationType === "customFunction" && (
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Custom Function"
                    placeholder="e.g., row => row.age * 2"
                    value={transformationParams.customFunction || ""}
                    onChange={handleTransformationParamChange("customFunction")}
                  />
                )}
              </Box>

              <Button variant="outlined" onClick={handleAddTransformationRequest} sx={{ marginTop: 2 }}>Add Transformation</Button>
            </Box>

            <Box flex={1} borderLeft="1px solid #ccc" paddingLeft={2} sx={{height:"500px", overflowY:"scroll"}}>
              <Typography variant="h6">Transformation Summary</Typography>
              {transformationRequests.length > 0 ? (
                transformationRequests.map((request, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Typography sx={{ flexGrow: 1 }}>{requestTypesDict[request.type]} ({Object.values(request.params).join(", ")}): {request.columns.includes("apply_to_all_menu_item") ? ("All columns") : request.columns.join(", ")}</Typography>
                    <IconButton onClick={() => handleRemoveTransformationRequest(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography>No transformations added</Typography>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <FormControlLabel control={<Checkbox checked={saveSeparately} onChange={(e) => setSaveSeparately(e.target.checked)} />} label="Save as a separate data frame" />
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleApplyTransformations} color="primary">Apply</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DataTransformation;
