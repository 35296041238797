import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert, Badge, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { api, team } from '../apis/urls';
import { deleteRequest, getRequest } from '../apis/requests';
import { Add, Delete, ManageAccounts, ManageSearch, Notifications } from '@mui/icons-material';
import { formatDate } from '../utils';
import CreateTeamModal from '../components/team/CreateTeamModal';
import JoinTeamModal from '../components/team/JoinTeamModal';
import JoinRequestsModal from '../components/team/JoinRequestsModal';
import ManageTeamModal from '../components/team/ManageTeamModal';
import CreateApiModal from '../components/api/CreateApiModal';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const Apis = () => {

    const authToken = localStorage.getItem('authToken');

    const navigate = useNavigate();

    const [gridData, setGridData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const [createApiModalOpen, setCreateApiModalOpen] = useState(false);
    const handleOpenCreateApiModal = () => setCreateApiModalOpen(true);
    const handleCloseCreateApiModal = () => {
        setCreateApiModalOpen(false);
        fetchApis();
    }

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleOpenDeleteDialog = (itemId) => {
        setSelectedItem(itemId);
        setDialogOpen(true);
    }

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to delete this API?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you delete an API you will not be able to work with it anymore. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Delete</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = api.delete(selectedItem);
            deleteRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "API has been deleted successfully");
                        fetchApis();
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if(data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        setDialogOpen(false);
    };

    useEffect(() => {
        fetchApis();
    }, []);    

    const fetchApis = () => {
        const url = api.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const columns = [
        { field: 'name', headerName: 'Name', width: "200" },
        { field: 'model', headerName: 'Associated Model', width: "200", renderCell: (params) => (params.row["model"]["name"]) },
        { field: 'status', headerName: 'Status', width: "200", renderCell: (params) => {
            if (params.row["model"]["status"] == "COMPLETED" ){
                return(<Chip label="HEALTHY" color='success' />)
            }
            else {
                return(<Chip label="CHECK MODEL" color='error' />)
            }
        }},
        { field: 'created_by', headerName: 'Created By', width: "200" },
        { field: 'created_at', headerName: 'Created At', width: "200", renderCell: (params) => (formatDate(params.row["created_at"])) },
        { field: "actions", headerName: "Actions", width: "350", renderCell: (params) => (
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px", alignItems:"center", marginTop:"7px"}}>
                <Button
                    variant="outlined"
                    endIcon={<ManageSearch />}
                    onClick={() => navigate("/platform/apis/overview", { state: { api_id: params.row["id"] } })}>Overview</Button>
                <Button
                    variant="outlined"
                    color='error'
                    endIcon={<Delete />}
                    onClick={() => handleOpenDeleteDialog(params.row["id"])}>Delete</Button>
            </Box>
        )}
        
    ];

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>APIs</Typography>
        <PageContainer>
            <Box sx={{display:"flex", flexDirection:"row", gap:"20px", margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenCreateApiModal}
                >Create an API</Button>
            </Box>
            {gridData ? (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        isRowSelectable={() => false}
                        style={{fontWeight: 400, fontSize:"11pt"}}
                    />
                </Box>
            ) : (
                <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
        </PageContainer>
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <CreateApiModal open={createApiModalOpen} handleClose={handleCloseCreateApiModal} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Apis