import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { dataset } from '../../apis/urls';
import { getRequest } from '../../apis/requests';

const MergeDatasetModal = ({ open, onClose, onMerge, leftColumnOptions }) => {

  const authToken = localStorage.getItem("authToken");

  const [availableDatasets, setAvailableDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [rightColumnOptions, setRightColumnOptions] = useState([]);
  const [selectedLeftColumn, setSelectedLeftColumn] = useState(null);
  const [selectedRightColumn, setSelectedRightColumn] = useState(null);
  const [joinType, setJoinType] = useState('inner');
  const [saveSeparately, setSaveSeparately] = useState(false);

  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("Message");

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (open) {
      fetchAvailableDatasets();
    }
  }, [open]);

  const fetchAvailableDatasets = () => {
    setLoading(true);
    const url = dataset.getByUserId() // Adjust endpoint as needed
    getRequest(url, { "Authorization": authToken })
      .then(data => {
        setAvailableDatasets(data); // Assuming API response returns dataset list
        setLoading(false);
      })
      .catch((error) => {
        handleSnackbarOpen("error", "Failed to load datasets.");
        setLoading(false);
      });
  };

  const handleDatasetChange = (event) => {
    const datasetId = event.target.value;
    setSelectedDataset(datasetId);
    fetchDatasetColumns(datasetId);
  };

  const fetchDatasetColumns = (datasetId) => {
    setLoading(true);
    const url = dataset.getColumnsByDatasetId(datasetId); // Adjust endpoint as needed
    getRequest(url, { "Authorization": authToken })
      .then(data => {
        setRightColumnOptions(data); // Assuming API response returns column list
        setLoading(false);
      })
      .catch((error) => {
        handleSnackbarOpen("error", "Failed to load columns.");
        setLoading(false);
      });
  };

  const handleMerge = () => {
    onMerge({
      leftColumn: selectedLeftColumn,
      rightDataset: selectedDataset,
      rightColumn: selectedRightColumn,
      joinType: joinType,
      saveSeparately: saveSeparately,
    });
    setAvailableDatasets([]);
    setSelectedDataset("");
    setRightColumnOptions([]);
    setSelectedLeftColumn(null);
    setSelectedRightColumn(null);
    setJoinType("inner");
    setSaveSeparately(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Merge Dataset</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Left Column</InputLabel>
              <Select
                value={selectedLeftColumn}
                onChange={(e) => setSelectedLeftColumn(e.target.value)}
              >
                <MenuItem key={"__index_column__"} value={"__index_column__"}>{"Index Column"}</MenuItem>
                {leftColumnOptions.map((col) => (
                  <MenuItem key={col["field"]} value={col["field"]}>{col["headerName"]}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Select Dataset</InputLabel>
              <Select
                value={selectedDataset}
                onChange={handleDatasetChange}
              >
                {availableDatasets.map((dataset) => (
                  <MenuItem key={dataset.id} value={dataset.id}>{dataset.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedDataset && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Select Right Column</InputLabel>
                  <Select
                    value={selectedRightColumn}
                    onChange={(e) => setSelectedRightColumn(e.target.value)}
                  >
                    <MenuItem key={"__index_column__"} value={"__index_column__"}>{"Index Column"}</MenuItem>
                    {rightColumnOptions.map((column) => (
                      <MenuItem key={column} value={column}>{column}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel>Join Type</InputLabel>
                  <Select
                    value={joinType}
                    onChange={(e) => setJoinType(e.target.value)}
                  >
                    <MenuItem value="inner">Inner Join</MenuItem>
                    <MenuItem value="left">Left Join</MenuItem>
                    <MenuItem value="right">Right Join</MenuItem>
                    <MenuItem value="outer">Outer Join</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <FormControlLabel control={<Checkbox checked={saveSeparately} onChange={(e) => setSaveSeparately(e.target.checked)} />} label="Save as a separate data frame" />
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleMerge} color="primary" disabled={!selectedDataset || !selectedRightColumn || !selectedLeftColumn}>
          Merge
        </Button>
      </DialogActions>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={5000}
        onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant='filled'
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default MergeDatasetModal;