import { Box, Chip, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDate } from '../../utils'
import { getRequest } from '../../apis/requests';
import { license } from '../../apis/urls';

const LicenseDetails = ({userData, clickToUpgrade, shareYourLicense}) => {

    const authToken = localStorage.getItem("authToken");

    const [licenseKey, setLicenseKey] = useState(userData["user_license"]["license_key"]);
    const [licenseOrigin, setLicenseOrigin] = useState(userData["user_license"]["license_origin"]);
    const [originalLicenseData, setOriginalLicenseData] = useState(userData);

    useEffect(() => {
        if(licenseKey != licenseOrigin){
            const url = license.getUserLicenseByLicenseKey(licenseOrigin)
            const headers = {
                "Authorization": authToken
            }
            getRequest(url, headers)
                .then(data => {
                    setOriginalLicenseData(data);
                })
            
        }
        else{
            setOriginalLicenseData(userData);
        }
    }, []);

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"60%", padding:"0px 50px"}}>
        <Typography variant='h5'>License Details</Typography>
        <Box sx={{display:"flex", flexDirection:"column", marginTop:"50px", gap:"10px"}}>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Status:</TableCell>
                            <TableCell>
                                {originalLicenseData["user_license"]["is_active"] ? (
                                    <Chip label={"Active"} color='success' />
                                ) : (
                                    <>
                                        <Chip label={"Expired - Contact to your account manager"} color='error' />
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>License Type:</TableCell>
                            <TableCell sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"20px"}}>
                                {originalLicenseData["user_license"]["license_type"]}
                                {(licenseOrigin != licenseKey) ? (
                                    <p style={{textDecoration:"underline"}}>{` by ${originalLicenseData["first_name"]} ${originalLicenseData["last_name"]}`}</p>
                                ) : (
                                    <></>
                                )}
                                {/* {((userData["user_license"]["license_type"] == "TRIAL" || userData["user_license"]["license_type"] == "PRO")) && (licenseOrigin == licenseKey) ? (
                                    <Chip label={"Click to Upgrade"} color="primary" sx={{cursor:"pointer"}} onClick={() => {clickToUpgrade();}} />
                                ) : (
                                    <></>
                                )} */}
                                {(licenseOrigin == licenseKey && originalLicenseData["user_license"]["is_active"] && (userData["user_license"]["license_type"] == "PRO" || userData["user_license"]["license_type"] == "ENTERPRISE")) ? (
                                    <Chip label={"Share Your License"} color="warning" sx={{cursor:"pointer"}} onClick={() => {shareYourLicense();}} />
                                ) : (
                                    <></>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Start Date:</TableCell>
                            <TableCell>{formatDate(originalLicenseData["user_license"]["start_date"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>End Date:</TableCell>
                            <TableCell>{formatDate(originalLicenseData["user_license"]["end_date"])}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Members:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_members"]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Rows:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_rows"]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Image Size:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_image_size"] ? (originalLicenseData["user_license"]["remaining_image_size"] / 1024).toFixed(2) : "-"} MB</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Tabular Model Trainings:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_tabular_model_trainings"]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Image Model Trainings:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_image_model_trainings"]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Predictions:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_predictions"]}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Remaining Business Suggestions:</TableCell>
                            <TableCell>{originalLicenseData["user_license"]["remaining_ai_suggestions"]}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </Box>
  )
}

export default LicenseDetails