//export const BASE_URL = "http://localhost:8000/api";
export const BASE_URL = "https://utience.com/api";

export const user = {
    login: () => `${BASE_URL}/user/login/`,
    signup: () => `${BASE_URL}/user/register/`,
    activate: () => `${BASE_URL}/user/activate/`,
    resendActivationMail: () => `${BASE_URL}/user/resend_activation_mail/`,
    send_password_reset_token: () => `${BASE_URL}/user/send_password_reset_token/`,
    reset_password_confirmation: () => `${BASE_URL}/user/reset_password_confirm/`,
    getUser: () => `${BASE_URL}/user/get_user`,
    updateProfilePicture: () => `${BASE_URL}/user/update_profile_picture/`,
    updateUserProfile: () => `${BASE_URL}/user/update_user_profile/`,
    updateUserPassword: () => `${BASE_URL}/user/update_user_password/`,
}

export const license = {
    getLicenseSharings: (licenseKey) => `${BASE_URL}/license_sharing/get_license_sharings/?license_key=${licenseKey}`,
    getUserLicenseByLicenseKey: (licenseKey) => `${BASE_URL}/user/get_user_license_by_license_key/?license_key=${licenseKey}`,
    invite: () => `${BASE_URL}/license_sharing/invite/`,
    getLicenseSharingInvitations: () => `${BASE_URL}/license_sharing/get_license_sharing_invitations/`,
    quitLicense: () => `${BASE_URL}/license_sharing/quit_license_sharing/`,
    removeMember: (userId) => `${BASE_URL}/license_sharing/remove_member/?user_id=${userId}`,
    acceptInvitation: (invitationId) => `${BASE_URL}/license_sharing/accept_license_invitation/?invitation_id=${invitationId}`,
    rejectInvitation: (invitationId) => `${BASE_URL}/license_sharing/reject_license_invitation/?invitation_id=${invitationId}`,
}

export const team = {
    create: () => `${BASE_URL}/team/create_new_team/`,
    getByUserId: () => `${BASE_URL}/team/get_teams`,
    getByTeamId: (selectedTeamId) => `${BASE_URL}/team/get_team/?team_id=${selectedTeamId}`,
    joinTeam: (teamCode) => `${BASE_URL}/team/join_team/?team_code=${teamCode}`,
    removeTeam: (selectedTeamId, userId) => `${BASE_URL}/team/remove_team/?team_id=${selectedTeamId}&member_id=${userId}`,
    leaveTeam: (selectedTeamId) => `${BASE_URL}/team/leave_team/?team_id=${selectedTeamId}`,
    deleteTeam: (selectedTeamId) => `${BASE_URL}/team/delete_team/?team_id=${selectedTeamId}`,
    getTeamJoinRequests: () => `${BASE_URL}/team/get_team_join_requests`,
    acceptTeamJoinRequest: () => `${BASE_URL}/team/accept_team_join_request/`,
    rejectTeamJoinRequest: () => `${BASE_URL}/team/reject_team_join_request/`,
}

export const notification = {
    create: () => `${BASE_URL}/notification/`,
    getByUserId: () => `${BASE_URL}/notification/check_notifications`,
    markAsRead: (notification_id) => `${BASE_URL}/notification/mark_as_read/?notification_id=${notification_id}`,
    markAllAsRead: () => `${BASE_URL}/notification/mark_all_as_read/`,
}

export const dataset = {
    create: () => `${BASE_URL}/dataset/`,
    getByUserId: () => `${BASE_URL}/dataset/get_datasets`,
    getByDatasetId: (selectedDatasetId) => `${BASE_URL}/dataset/get_data/?dataset_id=${selectedDatasetId}`,
    getColumnsByDatasetId: (selectedDatasetId) => `${BASE_URL}/dataset/get_columns/?dataset_id=${selectedDatasetId}`,
    getByTeamId: (team_id) => `${BASE_URL}/dataset/get_datasets_by_team/?team_id=${team_id}`,
    mergeDatasets: () => `${BASE_URL}/dataset/merge_datasets/`,
    getInsightsForModeling: (selectedDatasetId) => `${BASE_URL}/dataset/get_insights_for_modeling/?dataset_id=${selectedDatasetId}`,
    delete: (item_to_delete) => `${BASE_URL}/dataset/${item_to_delete}/`,
    share: (selectedDatasetId) => `${BASE_URL}/dataset/share_dataset/?dataset_id=${selectedDatasetId}`,
    setDataTypes: () => `${BASE_URL}/dataset/set_data_types/`,
    applyTransformations: (selectedDatasetId) => `${BASE_URL}/dataset/apply_transformations/?dataset_id=${selectedDatasetId}`,
}

export const imageDataset = {
    create: () => `${BASE_URL}/image_dataset/create_new_image_dataset/`,
    getByUserId: () => `${BASE_URL}/image_dataset/get_image_datasets`,
    getByTeamId: (team_id) => `${BASE_URL}/image_dataset/get_image_datasets_by_team/?team_id=${team_id}`,
    getImagesByDatasetId: (selectedDatasetId) => `${BASE_URL}/image_dataset/get_images/?image_dataset_id=${selectedDatasetId}`,
    getImageById: (selectedImageId) => `${BASE_URL}/image_dataset/get_image_by_id/?image_id=${selectedImageId}`,
    getLabelOptions: (selectedDatasetId) => `${BASE_URL}/image_dataset/get_label_options/?image_dataset_id=${selectedDatasetId}`,
    setLabelOptions: (selectedDatasetId) => `${BASE_URL}/image_dataset/set_label_options/?image_dataset_id=${selectedDatasetId}`,
    setLabels: (selectedImageId) => `${BASE_URL}/image_dataset/set_labels/?image_id=${selectedImageId}`,
    markAnnotated: (selectedImageId) => `${BASE_URL}/image_dataset/mark_as_annotated/?image_id=${selectedImageId}`,
    markNotAnnotated: (selectedImageId) => `${BASE_URL}/image_dataset/mark_as_not_annotated/?image_id=${selectedImageId}`,
    getThumbnailsByDatasetId: (selectedDatasetId) => `${BASE_URL}/image_dataset/get_thumbnails/?image_dataset_id=${selectedDatasetId}`,
    delete: (item_to_delete) => `${BASE_URL}/image_dataset/${item_to_delete}/`,
    share: (selectedDatasetId) => `${BASE_URL}/image_dataset/share_image_dataset/?image_dataset_id=${selectedDatasetId}`,
}

export const model = {
    create: () => `${BASE_URL}/model/`,
    getByUserId: () => `${BASE_URL}/model/get_models`,
    getByTeamId: (team_id) => `${BASE_URL}/model/get_models_by_team/?team_id=${team_id}`,
    delete: (item_to_delete) => `${BASE_URL}/model/${item_to_delete}/`,
    startTraining: (model_id) => `${BASE_URL}/model/start_training/?model_id=${model_id}`,
    getModelOverview: (model_id) => `${BASE_URL}/model/overview/?model_id=${model_id}`,
    getBusinessSuggestions: (model_id) => `${BASE_URL}/model/get_business_suggestions/?model_id=${model_id}`,
    predict: (model_id) => `${BASE_URL}/model/predict/?model_id=${model_id}`,
    predict_image: (model_id) => `${BASE_URL}/model/predict_image/?model_id=${model_id}`,
    share: (model_id) => `${BASE_URL}/model/share_model/?model_id=${model_id}`,
}

export const integration = {
    queryDatabase:() => `${BASE_URL}/integration/query_database/`,
}

export const api = {
    create: () => `${BASE_URL}/api/create_new_api/`,
    getByUserId: () => `${BASE_URL}/api/get_apis`,
    getByTeamId: (team_id) => `${BASE_URL}/api/get_apis_by_team/?team_id=${team_id}`,
    getByApiId: (selectedApiId) => `${BASE_URL}/api/get_apis/?api_id=${selectedApiId}`,
    delete: (selectedApiId) => `${BASE_URL}/api/${selectedApiId}/`,
}

export const apiLog = {
    getByApiId: (api_id) => `${BASE_URL}/api_log/get_api_logs?api_id=${api_id}`,
}

export const assignment = {
    create: () => `${BASE_URL}/assignment/create_new_assignment/`,
    getByUserId: () => `${BASE_URL}/assignment/get_assignments`,
    getByAssignmentId: (assignment_id) => `${BASE_URL}/assignment/get_assignments/?assignment_id=${assignment_id}`,
}

export const ticket = {
    create: () => `${BASE_URL}/ticket/create_new_ticket/`,
    getByUserId: () => `${BASE_URL}/ticket/get_tickets`,
    getByTicketId: (ticket_id) => `${BASE_URL}/ticket/get_tickets/?ticket_id=${ticket_id}`,
}

export const demoRequest = {
    create: () => `${BASE_URL}/demo_request/`,
}