// store/imageDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  labelOptions: [],
};

const labelOptionsSlice = createSlice({
  name: 'labelOptions',
  initialState,
  reducers: {
    setLabelOptions: (state, action) => {
      state.labelOptions = action.payload;
    },
  },
});

export const { setLabelOptions } = labelOptionsSlice.actions;
export default labelOptionsSlice.reducer;
