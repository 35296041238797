import { Box, Button, Chip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UseCaseHeader = ({ caseIndustry, caseTitle, caseImage }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'auto', md: '450px' }, // Responsive height adjustment
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, row on desktop
        boxSizing: 'border-box',
        paddingX: { xs: '5%', md: '15%' },
        paddingY: '20px',
        background: 'linear-gradient(to bottom, #2e1866, #000000)',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          color: 'white',
          paddingTop: { xs: '10px', md: '0px' },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Chip
          label={`Industry: ${caseIndustry}`}
          sx={{
            backgroundColor: 'lavender',
            fontSize: '12pt',
            marginBottom: { xs: '10px', md: '20px' },
          }}
        />
        <Typography variant="h3" marginTop={{ xs: '10px', md: '20px' }}>
          Use Case: {caseTitle}
        </Typography>
        <Typography
          variant="h5"
          fontWeight={300}
          marginTop={{ xs: '10px', md: '20px' }}
          width={{ xs: '100%', md: '70%' }}
        >
          How Utimind transforms {caseIndustry} industry <br />
          with No-Code Machine Learning
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '10px', md: '20px' },
            marginTop: { xs: '20px', md: '50px' },
            width: '100%',
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              width: { xs: '100%', sm: '200px' },
              fontSize: '12pt',
              backgroundColor: '#686dba',
              ':hover': { backgroundColor: '#383A6E' },
            }}
            onClick={() => navigate('/pricing')}
          >
            Try it for Free
          </Button>
          <Button
            variant="outlined"
            sx={{
              textTransform: 'none',
              width: { xs: '100%', sm: '200px' },
              fontSize: '12pt',
              color: theme.palette.text.light,
              borderColor: '#686dba',
              ':hover': {
                backgroundColor: '#686dba',
                borderColor: '#686dba',
                color: 'white', // Ensures text is visible on hover
              },
            }}
            onClick={() => navigate('/request_demo')}
          >
            See it in Action
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 4,
          width: '100%',
          height: { xs: '250px', md: '100%' }, // Adjusted height for responsiveness
          marginTop: { xs: '20px', md: '0' },
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={caseImage}
          alt={caseTitle}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '20px',
            maxWidth: '500px', // Limiting max width for better appearance on large screens
          }}
        />
      </Box>
    </Box>
  );
};

export default UseCaseHeader;