import React, { useState, useEffect } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  MenuItem, Select, TextField, Typography
} from '@mui/material';

const colors = [
  { name: 'Red', value: '#f44336' },
  { name: 'Pink', value: '#e91e63' },
  { name: 'Purple', value: '#9c27b0' },
  { name: 'Deep Purple', value: '#673ab7' },
  { name: 'Indigo', value: '#3f51b5' },
  { name: 'Blue', value: '#2196f3' },
  { name: 'Light Blue', value: '#03a9f4' },
  { name: 'Cyan', value: '#00bcd4' },
  { name: 'Teal', value: '#009688' },
  { name: 'Green', value: '#4caf50' },
  { name: 'Light Green', value: '#8bc34a' },
  { name: 'Lime', value: '#cddc39' },
  { name: 'Yellow', value: '#ffeb3b' },
  { name: 'Amber', value: '#ffc107' },
  { name: 'Orange', value: '#ff9800' },
  { name: 'Deep Orange', value: '#ff5722' },
  { name: 'Brown', value: '#795548' },
  { name: 'Grey', value: '#9e9e9e' },
  { name: 'Blue Grey', value: '#607d8b' },
  { name: 'Black', value: '#000000' },
  { name: 'White', value: '#ffffff' },
  { name: 'Turquoise', value: '#40e0d0' },
  { name: 'Salmon', value: '#fa8072' },
  { name: 'Gold', value: '#ffd700' },
  { name: 'Orchid', value: '#da70d6' }
];

const EditLabelModal = ({ open, onClose, onSave, labels }) => {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [labelName, setLabelName] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  useEffect(() => {
    if (selectedLabel) {
      const label = labels.find(label => label.name === selectedLabel);
      if (label) {
        setLabelName(label.name);
        setSelectedColor(label.color);
      }
    }
  }, [selectedLabel, labels]);

  const handleSave = () => {
    onSave({ oldName: selectedLabel, newName: labelName, color: selectedColor });
    setSelectedLabel('');
    setLabelName('');
    setSelectedColor('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Label</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Select
            value={selectedLabel}
            onChange={(e) => setSelectedLabel(e.target.value)}
            displayEmpty
            renderValue={(value) =>
              value ? (
                <Typography>{labels.find(label => label.name === value)?.name}</Typography>
              ) : (
                <Typography>Select Label to Edit</Typography>
              )
            }
          >
            {labels.map((label) => (
              <MenuItem key={label.name} value={label.name}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: label.color,
                    marginRight: 1
                  }}
                />
                {label.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Label Name"
            value={labelName}
            onChange={(e) => setLabelName(e.target.value)}
            fullWidth
          />
          <Select
            value={selectedColor}
            onChange={(e) => setSelectedColor(e.target.value)}
            displayEmpty
            renderValue={(value) =>
              value ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      backgroundColor: value,
                      marginRight: 1
                    }}
                  />
                  <Typography>{colors.find(c => c.value === value)?.name}</Typography>
                </Box>
              ) : (
                <Typography>Select Color</Typography>
              )
            }
          >
            {colors.map((color) => (
              <MenuItem key={color.value} value={color.value}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: color.value,
                    marginRight: 1
                  }}
                />
                {color.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!labelName || !selectedColor}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLabelModal;
