import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

export const getRequest = (url, headers) => {

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
    })
    .then(response => {
        if (response.ok){
            return response.json();
        }
        else{
            
            if (response.status === 401) {
                localStorage.setItem('isAuthenticated', 'false'); // Clear auth state
                localStorage.setItem("userId", null);
                localStorage.setItem("authToken", null)
                useNavigate()("platform/login");
            }
        }
    })
    .then(data => {
        return data;
    })
    .catch((error) => console.error("Error:", error));
}

export const postRequest = (url, headers = {}, body = null) => {

    const requestOptions = {
        method: 'POST',
        headers: {
            ...headers,
        }
    }

    if (body !== null) {
        requestOptions.body = body
    }

    return fetch(url, requestOptions)
    .then(response => {
        return response;
    })
    .catch((error) => console.error("Error:", error));
}

export const putRequest = (url, headers = {}, body = null) => {

    const requestOptions = {
        method: 'PUT',
        headers: {
            ...headers,
        }
    }

    if (body !== null) {
        requestOptions.body = body
    }

    return fetch(url, requestOptions)
        .then(response => {
            return response;
        })
        .catch((error) => console.error("Error:", error));
}

export const deleteRequest = (url, headers = {}) => {
    const requestOptions = {
        method: "DELETE",
        headers: {
            ...headers,
        }
    }

    return fetch(url, requestOptions)
    .then(response => {
        return response
    })
    .catch((error) => console.error("Error:", error));
}