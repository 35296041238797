import { Delete } from '@mui/icons-material'
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setImageData } from "../../store/imageDataSlice";


const LabelPanel = () => {

    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.imageData);
    const labelOptions = useSelector((state) => state.labelOptions);

    const handleRemoveLabel = (index) => {
        const updatedLabels = imageData.imageData.labels.filter((_, idx) => idx !== index);
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
    }

    const handleChangeLabel = (index, newLabel) => {
        const updatedLabels = imageData.imageData.labels.map((label, idx) => {
            if (idx === index) {
                return { ...label, label: newLabel };
            }
            return label;
        });
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
    }

  return (
    <Box sx={{ height: 738, width: 250, overflowY: 'scroll', border: '1px solid #ccc', margin:"20px 30px", padding:"10px", }}>
        <Typography>Annotations:</Typography>
        <List>
            {imageData.imageData.labels ? (
                imageData.imageData.labels.map((label, index) => (
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveLabel(index)} >
                                <Delete />
                            </IconButton>
                        }
                    >
                        <Select value={label["label"]}
                                onChange={(event) => {handleChangeLabel(index, event.target.value)}}
                                sx={{width:"200px"}}
                            >
                                {labelOptions.labelOptions ? (
                                    labelOptions.labelOptions.map((labelObj) => {
                                        return (<MenuItem value={labelObj["name"]}>
                                                    <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
                                                        <Typography>{labelObj["name"]}</Typography>
                                                        <Box sx={{height:"30px", width:"30px", backgroundColor:labelObj["color"]}}></Box>
                                                    </Box>
                                                </MenuItem>)
                                    })
                                ) : (
                                    <></>
                                )}
                            </Select>
                    </ListItem>
                ))
            ) : (
                <></>
            )}
        </List>
    </Box>
  )
}

export default LabelPanel