import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // You can choose other styles

const CodeBox = ({ codeString, language }) => {
  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap" rel="stylesheet" />
      <SyntaxHighlighter className={"react-syntax-highlighter"} language={language} showLineNumbers={true} style={a11yDark} customStyle={{fontSize:"11pt", fontFamily:"'JetBrains Mono', monospace"}}>
        {codeString}
      </SyntaxHighlighter>
    </>
  );
};

export default CodeBox;