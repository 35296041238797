import { Box, Typography } from '@mui/material'
import React from 'react'

const Preferences = () => {
  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"60%", padding:"50px 50px"}}>
        <Typography variant='h5'>Preferences</Typography>
        <Box sx={{display:"flex", flexDirection:"column", marginTop:"50px", gap:"30px"}}>
            
        </Box>
    </Box>
  )
}

export default Preferences