import styled from '@emotion/styled';
import { Add, Delete, ManageSearch, Share } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, Tooltip, Typography, useTheme } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils';
import { deleteRequest, getRequest, putRequest } from '../apis/requests';
import { imageDataset, team } from '../apis/urls';
import ImageUploadModal from '../components/imageDataset/ImageUploadModal';
import { useSelector, useDispatch } from 'react-redux';
import { setLabelOptions } from "../store/labelOptionsSlice";

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const taskTypesDict = {
    "image_classification": "IMAGE CLASSIFICATION",
    "object_detection": "OBJECT DETECTION",
    "image_segmentation": "IMAGE SEGMENTATION",
}

const ImageDatasets = () => {

    const authToken = localStorage.getItem("authToken");
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const labelOptions = useSelector((state) => state.labelOptions);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const [shareImageDatasetDialogOpen, setShareImageDatasetDialogOpen] = useState(false);
    
    const [selectedItem, setSelectedItem] = useState(null);
    const [gridData, setGridData] = useState(null);
    
    const columns = [
        { field: 'name', headerName: 'Name', width: "250" },
        { field: 'task_type', headerName: 'Task Type', width: "250", renderCell: (params) => (taskTypesDict[params.row["task_type"]]) },
        { field: 'counts', headerName: 'Counts', width: "150" },
        { field: 'size', headerName: 'Size', width: "150", renderCell: (params) => (`${(params.row["size"] / 1024).toFixed(2)} MB`) },
        { field: 'uploaded_by', headerName: 'Uploaded By', width: "200" },
        { field: 'uploaded_at', headerName: 'Uploaded At', width: "200", renderCell: (params) => (formatDate(params.row["uploaded_at"])) },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: "245",
            renderCell: (params) => {return (
                <Box>
                    <Tooltip title="Overview">
                        <IconButton aria-label='Overview' onClick={() => handleClickAction(params.row, "overview")}>
                            <ManageSearch sx={{color:"#ffbf00", width:"35px", height:"35px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Share">
                        <IconButton color='primary' aria-label='Share' onClick={() => handleClickAction(params.row, "share")}>
                            <Share sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color='error' aria-label='Delete' onClick={() => handleClickAction(params.row, "delete")}>
                            <Delete sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        }
    ]

    const handleClickAction = (props, action_type) => {
        setSelectedItem(props.id);

        if (action_type === "delete") {
          setDialogOpen(true);
        }
        else if (action_type === "overview"){
            navigate("/platform/image_datasets/overview", 
            { state: { image_dataset_id: props.id, 
                    image_dataset_name: props.name,
                    image_dataset_task_type: props.task_type
                    } 
            })
        }
        else if (action_type === "share"){
          setShareImageDatasetDialogOpen(true);
        }
    }

    const [addImageDatesetModalOpen, setAddImageDatesetModalOpen] = useState(false);
    const handleOpenAddImageDatesetModal = () => setAddImageDatesetModalOpen(true);
    const handleCloseAddImageDatesetModal = () => {
        setAddImageDatesetModalOpen(false);
        fetchImageDatasets();
    }

    useEffect(() => {
        fetchImageDatasets();
    }, []);    

    const fetchImageDatasets = () => {
        const url = imageDataset.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const [dialogOpen, setDialogOpen] = useState(false);

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to delete this image dataset?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you delete an image dataset you will not be able to work with it anymore. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Delete</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = imageDataset.delete(selectedItem);
            deleteRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "Image dataset has been deleted successfully");
                        fetchImageDatasets();
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if(data && data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        setDialogOpen(false);
    };

    const ShareImageDatasetDialog = () => {

        const theme = useTheme();
        const [teamData, setTeamData] = useState([]);
        const [selectedTeam, setSelectedTeam] = useState(null);

        useEffect(() => {
            const url = team.getByUserId();
            getRequest(url, {"Authorization": authToken})
                .then(data => {
                    setTeamData(data);
                })
        }, [])
        
    
        return (
          <Dialog
            open={shareImageDatasetDialogOpen}
            onClose={() => handleShareImageDatasetDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Choose a team that you want to share the image dataset with"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <Box>
                    {(teamData && teamData.length > 0) ? (
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Select team</InputLabel>
                            <Select
                                label={"Select team"}
                                value={selectedTeam}
                                sx={{width:"400px", padding:0 }}
                                onChange={(event) => {setSelectedTeam(event.target.value)}}
                                >
                                {teamData.map(team => {
                                    return (
                                        <MenuItem value={team}>{team.name}</MenuItem>
                                    )}
                                )}
                            </Select>
                        </FormControl>
                    ) : 
                    (
                        <Typography>You do not have a team</Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleShareImageDatasetDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleShareImageDatasetDialogClose(true, selectedTeam)}>Share</Button>
            </DialogActions>
          </Dialog>
        )
    };

    const handleShareImageDatasetDialogClose = (shareApproved, selectedTeam) => {
        if(shareApproved){
            if (selectedTeam) {
                const url = imageDataset.share(selectedItem);
                const headers = {
                    "Content-Type": "application/json",
                    "Authorization": authToken
                }
                const body = JSON.stringify({
                    "team_id": selectedTeam.id
                })

                putRequest(url, headers, body)
                    .then(response => {
                        if(response.ok){
                            handleSnackbarOpen("success", "Image dataset has been shared with the team.");
                        }
                        return response.json();
                    })
                    .then(data => {
                        if(data["error"]){
                            handleSnackbarOpen("error", data["error"]);
                        }
                    });
            }         
            else {
                handleSnackbarOpen("error", "You must select a team for sharing.")
            }
        }
        setShareImageDatasetDialogOpen(false);
    };

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>Image Datasets</Typography>
        <PageContainer>
            <Box sx={{ margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenAddImageDatesetModal}
                >Create New</Button>
            </Box>
            {gridData ? (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        isRowSelectable={() => false}
                        style={{fontWeight: 400, fontSize:"11pt", paddingLeft:"20px"}}
                    />
                </Box>
            ) : (
                <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
        </PageContainer>
        <ImageUploadModal isOpen={addImageDatesetModalOpen} onRequestClose={handleCloseAddImageDatesetModal} fetchImages={fetchImageDatasets} />
        <ShareImageDatasetDialog open={shareImageDatasetDialogOpen} handleClose={handleShareImageDatasetDialogClose} />
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ImageDatasets