import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import allInOneImage from "../../assets/images/utimind_all_in_one.png";

const AllInOneUtimind = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' }, // Stack on mobile, row on desktop
        boxSizing: 'border-box',
        paddingX: { xs: '5%', md: '10%' }, // Responsive padding
        paddingY: { xs: '50px', md: '100px' }, // Responsive padding
        gap: '20px',
        alignItems: 'center', // Center items vertically
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: { xs: '30px', md: '0' }, // Space between image and text on mobile
        }}
      >
        <img
          src={allInOneImage}
          alt='Utimind Features'
          style={{ width: '100%', height: 'auto', maxWidth: '700px' }} // Limit max width for larger screens
        />
        <Typography variant='h5' sx={{ marginTop: '-40px' }}>+</Typography>
        <Typography variant='h6' sx={{ marginTop: '10px', textAlign: 'center' }}>
          Dedicated Data Scientists
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: { xs: 'center', md: 'left' }, // Center text on mobile
        }}
      >
        <Typography variant='h3' marginLeft={{ xs: '0', md: '30px' }} marginBottom="20px">
          Data science team in your pocket
        </Typography>
        <Typography
          variant='h5'
          marginLeft={{ xs: '0', md: '30px' }}
          marginTop="20px"
          fontWeight={300}
          color="#555555"
        >
          Utimind consolidates all necessary components for data science and AI workflows into a single, unified platform. This eliminates the need for multiple disparate tools, reduces the time and cost associated with traditional AI development, and democratizes access to advanced analytics by making it accessible to non-technical users. Utimind’s no-code, user-friendly approach ensures that businesses can quickly turn data into actionable insights, driving efficiency, reducing operational barriers, and accelerating the overall AI adoption process.
        </Typography>
      </Box>
    </Box>
  );
};

export default AllInOneUtimind;
