// store/imageDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  imageData: {
    url: '',
    datasetLabel: null,
    labels: [],
    actualHeight: null,
    actualWidth: null,
    aspectRatio: null,
    annotated: false
  },
};

const imageDataSlice = createSlice({
  name: 'imageData',
  initialState,
  reducers: {
    setImageData: (state, action) => {
      state.imageData = action.payload;
    },
    setDatasetLabel: (state, action) => {
      state.imageData.datasetLabel = action.payload;
    },
    addLabel: (state, action) => {
      state.imageData.labels.push(action.payload);
    },
    removeLabel: (state, action) => {
      state.imageData.labels.splice(action.payload, 1);
    },
  },
});

export const { setImageData, setDatasetLabel, addLabel, removeLabel } = imageDataSlice.actions;
export default imageDataSlice.reducer;
