import React, { useEffect, useState } from 'react'
import { api, assignment, dataset, imageDataset, model, team } from '../apis/urls'
import { getRequest, postRequest } from '../apis/requests';
import { Alert, Autocomplete, Box, Button, Checkbox, Chip, FormControl, Input, InputLabel, ListItemText, Menu, MenuItem, Modal, Paper, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { Add, DensityMedium, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import styled from '@emotion/styled';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600, // Increase the width of the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categoriesDict = {
    "tabular_dataset": "Tabular Dataset",
    "image_dataset": "Image Dataset",
    "model": "Model",
    "api": "API"
}

const ProjectManagement = () => {

    const authToken = localStorage.getItem("authToken");

    const [toDoItems, setToDoItems] = useState([]);
    const [inProgressItems, setInProgressItems] = useState([]);
    const [completedItems, setCompletedItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);

    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [selectedDueDate, setSelectedDueDate] = useState(null);
    
    const [teams, setTeams] = useState([]);
    const [imageDatasets, setImageDatasets] = useState([]);
    const [tabularDatasets, setTabularDatasets] = useState([]);
    const [models, setModels] = useState([]);
    const [apis, setApis] = useState([]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        fetchTeams();
        fetchAssignments();
    }, [])

    const fetchTeams = () => {
        const url = team.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setTeams(data);
            })
    }

    const fetchTeam = (teamId) => {
        const url = team.getByTeamId(teamId);
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                setSelectedTeam(data);
                setSelectedTeamId(data["id"]);
            })
    }

    const fetchImageDatasets = (teamId) => {
        const url = imageDataset.getByTeamId(teamId);
        const headers = {
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                setImageDatasets(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchTabularDatasets = (teamId) => {
        const url = dataset.getByTeamId(teamId);
        const headers = {
            "Content-Type": "application/json",
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                setTabularDatasets(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchModels = (teamId) => {
        const url = model.getByTeamId(teamId);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setModels(data);
            })
    }

    const fetchApis = (teamId) => {
        const url = api.getByTeamId(teamId);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setApis(data);
            })
    }

    const fetchAssignments = () => {
        const url = assignment.getByUserId();
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                let toDoItemList = [];
                let inProgressItemList = [];
                let completedItemList = [];

                data.forEach(item => {
                    if(item["stage"] == "to_do"){
                        toDoItemList.push(item);
                    }
                    else if(item["stage"] == "in_progress"){
                        inProgressItemList.push(item);
                    }
                    else if(item["stage"] == "completed"){
                        completedItemList.push(item);
                    }
                });

                setToDoItems(toDoItemList);
                setInProgressItems(inProgressItemList);
                setCompletedItems(completedItemList);
            })
    }

    const handleDisplayAssignment = (item) => {
        setSelectedItem(item);
        fetchTeam(item["team"]["id"])
        setSelectedCategory(item["category"]);
        switch(item["category"]) {
            case "general":
                ;
            case "tabular_dataset":
                fetchTabularDatasets(item["team"]["id"]);
            case "image_dataset":
                fetchImageDatasets(item["team"]["id"]);
            case "model":
                fetchModels(item["team"]["id"]);
            case "api":
                fetchApis(item["team"]["id"]);
        }
        setSelectedObject(item["object_id"]);
        setSelectedTeamMembers(item["assignees"]);
        setSelectedTitle(item["title"]);
        setSelectedDescription(item["description"]);
        setSelectedPriority(item["priority"]);
        setSelectedStage(item["stage"]);
        setSelectedDueDate(dayjs(item["due_date"]));

        setIsAssignmentModalOpen(true);
    }

    const handleAssignmentModalClose = () => {
        setSelectedItem(null);
        setSelectedTeam(null);
        setSelectedTeamId(null);
        setSelectedCategory(null);
        setSelectedObject(null);
        setSelectedTeamMembers([]);
        setSelectedTitle(null);
        setSelectedDescription(null);
        setSelectedPriority(null);
        setSelectedStage(null);
        setSelectedDueDate(null);

        setIsAssignmentModalOpen(false);
    }

    const handleSelectedTeamChanged = (e) => {
        const team = e.target.value;
        fetchTeam(team);
        setSelectedTeamMembers([]);
    }

    const handleSelectedCategoryChanged = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        
        switch(category) {
            case "general":
                ;
            case "tabular_dataset":
                fetchTabularDatasets(selectedTeamId);
            case "image_dataset":
                fetchImageDatasets(selectedTeamId);
            case "model":
                fetchModels(selectedTeamId);
            case "api":
                fetchApis(selectedTeamId);
        }
    }

    const handleSelectedObjectChanged = (e) => {
        const object = e.target.value;
        setSelectedObject(object);
    }

    const handleSave = () => {
        if (document.getElementById("selected-title-text-field").value 
            && selectedTeam 
            && selectedTeamMembers 
            && selectedCategory 
            && (selectedObject == "general" || selectedCategory) 
            && selectedPriority 
            && selectedStage) {

            const assignmentData = {
                "id": selectedItem ? selectedItem["id"] : null,
                "title": document.getElementById("selected-title-text-field").value,
                "description": document.getElementById("selected-description-text-field").value,
                "team": selectedTeam,
                "assignees": selectedTeamMembers,
                "category": selectedCategory,
                "object_id": selectedObject,
                "object_name": selectedCategory == "general" ? (null) : (
                    selectedCategory == "tabular_dataset" ? (tabularDatasets.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (selectedCategory == "image_dataset" ? (imageDatasets.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (selectedCategory == "model" ? (models.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (apis.filter(item => item.id == selectedObject)[0]["name"])))
                ),
                "priority": selectedPriority,
                "due_date": selectedDueDate ? dayjs(selectedDueDate).toDate() : null,
                "stage": selectedStage,                
            }
    
            const url = assignment.create();
            const headers = {
                "Authorization": authToken,
                "Content-Type": "application/json"
            }
            const body = JSON.stringify(assignmentData);
    
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok) {
                        if(selectedItem) {
                            handleSnackbarOpen("success", "Task has been updated successfully.");
                        }
                        else {
                            handleSnackbarOpen("success", "New task has been assigned successfully.");
                        }
                        handleAssignmentModalClose();
                        fetchAssignments();
                    }
                    else {
                        handleSnackbarOpen("error", "An error occurred.");
                    }
                })
        }
        else {
            handleSnackbarOpen("error", "Please fill all fields properly");
        }
    }

    const AssignmentModal = () => {
        return (
            <Modal
                open={isAssignmentModalOpen}
                onClose={handleAssignmentModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Assignment
                    </Typography>

                    <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
                        <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                            
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Select team</InputLabel>
                                <Select
                                    value={selectedTeamId}
                                    label="Select team"
                                    onChange={handleSelectedTeamChanged}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    {teams.map(team => {
                                        return (
                                            <MenuItem value={team.id}>{team.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            {selectedTeamId ? (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select category</InputLabel>
                                    <Select
                                        value={selectedCategory}
                                        label="Select category"
                                        onChange={handleSelectedCategoryChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        <MenuItem value={"general"}>General</MenuItem>
                                        <MenuItem value={"tabular_dataset"}>Tabular Dataset</MenuItem>
                                        <MenuItem value={"image_dataset"}>Image Dataset</MenuItem>
                                        <MenuItem value={"model"}>Model</MenuItem>
                                        <MenuItem value={"api"}>API</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select category</InputLabel>
                                    <Select
                                        value={selectedCategory}
                                        label="Select category"
                                        onChange={handleSelectedCategoryChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                        disabled
                                    >
                                    </Select>
                                </FormControl>
                            )}

                            {selectedCategory && selectedCategory != "general" ? (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select {categoriesDict[selectedCategory]}</InputLabel>
                                    <Select
                                        value={selectedObject}
                                        label={`Select ${categoriesDict[selectedCategory]}`}
                                        onChange={handleSelectedObjectChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        {selectedCategory == "tabular_dataset" ? (
                                            tabularDatasets.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (selectedCategory == "image_dataset") ? (
                                            imageDatasets.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (selectedCategory == "model" ? (
                                            models.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (
                                            apis.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select object</InputLabel>
                                    <Select
                                        value={selectedObject}
                                        label={`Select ${categoriesDict[selectedCategory]}`}
                                        onChange={handleSelectedObjectChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                        disabled
                                    >
                                        
                                    </Select>
                                </FormControl>
                            )}
                        </Box>

                        <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                            <FormControl fullWidth margin='normal'>
                                <InputLabel>Select priority</InputLabel>
                                <Select
                                    value={selectedPriority}
                                    label="Select priority"
                                    onChange={(e) => setSelectedPriority(e.target.value)}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <MenuItem value={"high"}>High</MenuItem>
                                    <MenuItem value={"medium"}>Medium</MenuItem>
                                    <MenuItem value={"low"}>Low</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin='normal'>
                                <InputLabel>Select stage</InputLabel>
                                <Select
                                    value={selectedStage}
                                    label="Select stage"
                                    onChange={(e) => setSelectedStage(e.target.value)}
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    <MenuItem value={"to_do"}>To Do</MenuItem>
                                    <MenuItem value={"in_progress"}>In Progress</MenuItem>
                                    <MenuItem value={"completed"}>Completed</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin='normal'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Select due date"
                                        value={selectedDueDate}
                                        onChange={(newValue) => setSelectedDueDate(newValue)}
                                        />
                                </LocalizationProvider>
                            </FormControl>
                        </Box>
                    </Box>

                    {selectedTeam ? (
                        <FormControl fullWidth margin='normal'>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={selectedTeam["members"]}
                                getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                                value={selectedTeamMembers}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, newValue) => setSelectedTeamMembers(newValue)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Assigned to"
                                    placeholder="Assignees"
                                />
                                )}
                            />
                        </FormControl>
                    ) : (
                        <FormControl fullWidth margin='normal'>
                            <Autocomplete
                                disabled
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Assigned to"
                                    placeholder="Assignees"
                                />
                                )}
                            />
                        </FormControl>
                    )}

                    <FormControl fullWidth margin='normal'>
                        <TextField id='selected-title-text-field' label="Title" defaultValue={selectedTitle} />
                    </FormControl>

                    <FormControl fullWidth margin='normal'>
                        <TextField id='selected-description-text-field' label="Description" defaultValue={selectedDescription} multiline rows={3} />
                    </FormControl>

                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", gap:"20px", marginTop:"10px"}}>
                        <Button variant="contained" color='error' onClick={handleAssignmentModalClose}>Cancel</Button>
                        <Button variant="contained" color='primary' onClick={handleSave}>Save</Button>
                    </Box>                    
                </Box>
            </Modal>
        )
    }

    const handleNewAssignment = () => {
        setIsAssignmentModalOpen(true);
    }

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>Project Management Board</Typography>
        <PageContainer>
            <Box sx={{ margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleNewAssignment}
                >Create New Task</Button>
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", width:"95%", height:"75vh", gap:"10px", padding:"0px"}}>
                <Box sx={{flex:1, display:"flex", flexDirection:"column", height:"95%", gap:"10px", padding:"10px", backgroundColor:"#E0E0E0", borderRadius:"10px", overflowY:"scroll"}}>
                    <Box sx={{display:"flex", gap:"10px"}}>
                        <Typography fontWeight={900}>TO DO</Typography>
                        <Typography sx={{color:"gray"}}>[ {toDoItems.length} ]</Typography>
                    </Box>
                    {toDoItems.length > 0 ? (
                        toDoItems.map(item => {
                            return (
                                <Paper elevation={3} sx={{display:"flex", flexDirection:"column", backgroundColor:"white", padding:"15px 15px 10px 15px", cursor:"pointer"}} onClick={() => handleDisplayAssignment(item)}>
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        {item.category == "general" ? (
                                                <Chip label={"GENERAL"} size='small' />
                                            ) : (
                                            item.category == "tabular_dataset" ? (
                                                <Chip label={"TABULAR DATASET"} size='small' sx={{backgroundColor:"lavender"}} />
                                            ) : (item.category == "image_dataset" ? (
                                                <Chip label={"IMAGE DATASET"} size='small' sx={{backgroundColor:"#ffe6ee"}} />
                                            ) : (item.category == "model" ? (
                                                <Chip label={"MODEL"} size='small' sx={{backgroundColor:"#ccffdd"}} />
                                            ) : (
                                                <Chip label={"API"} size='small' sx={{backgroundColor:"#ccf5ff"}} />
                                            )))
                                        )}
                                        {item.priority == "low" ? (
                                            <KeyboardDoubleArrowDown sx={{color:"#3399ff", stroke: "#3399ff", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (item.priority == "medium" ? (
                                            <DensityMedium sx={{color:"#ff6600", stroke: "#ff6600", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (
                                            <KeyboardDoubleArrowUp sx={{color:"crimson", stroke: "crimson", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ))}
                                    </Box>
                                    <Box sx={{height:"50px"}}>
                                        <Typography sx={{marginTop:"5px"}}>{item.title}</Typography>
                                    </Box>
                                        {item.category == "general" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"gray", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "tabular_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#5555dd", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "image_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#ff3374", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "model" ? (
                                            <Tooltip title={"Model name"}>
                                                <Typography sx={{backgroundColor:"#33ff77", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={"API name"}>
                                                <Typography sx={{backgroundColor:"#33d6ff", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        )))
                                        )}
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"10px"}}>
                                        <Tooltip title={"Assigned team"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.team.name}</Typography>
                                        </Tooltip>
                                        <Tooltip title={"Assigned by"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.assigned_by.first_name} {item.assigned_by.last_name}</Typography>
                                        </Tooltip>
                                    </Box>
                                </Paper>
                            )
                        })
                    ) : (
                        <Typography variant='subtitle2' sx={{fontSize:"12pt", fontStyle:"italic", fontWeight:400, color:"#333333"}}>There are no items here</Typography>
                    )}
                </Box>
                <Box sx={{flex:1, display:"flex", flexDirection:"column", height:"95%", gap:"10px", padding:"10px", backgroundColor:"#E0E0E0", borderRadius:"10px", overflowY:"scroll"}}>
                    <Box sx={{display:"flex", gap:"10px"}}>
                        <Typography fontWeight={900}>IN PROGRESS</Typography>
                        <Typography sx={{color:"gray"}}>[ {inProgressItems.length} ]</Typography>
                    </Box>
                    {inProgressItems.length > 0 ? (
                        inProgressItems.map(item => {
                            return (
                                <Paper elevation={3} sx={{display:"flex", flexDirection:"column", backgroundColor:"white", padding:"15px 15px 10px 15px", cursor:"pointer"}} onClick={() => handleDisplayAssignment(item)}>
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        {item.category == "general" ? (
                                                <Chip label={"GENERAL"} size='small' />
                                            ) : (
                                            item.category == "tabular_dataset" ? (
                                                <Chip label={"TABULAR DATASET"} size='small' sx={{backgroundColor:"lavender"}} />
                                            ) : (item.category == "image_dataset" ? (
                                                <Chip label={"IMAGE DATASET"} size='small' sx={{backgroundColor:"#ffe6ee"}} />
                                            ) : (item.category == "model" ? (
                                                <Chip label={"MODEL"} size='small' sx={{backgroundColor:"#ccffdd"}} />
                                            ) : (
                                                <Chip label={"API"} size='small' sx={{backgroundColor:"#ccf5ff"}} />
                                            )))
                                        )}
                                        {item.priority == "low" ? (
                                            <KeyboardDoubleArrowDown sx={{color:"#3399ff", stroke: "#3399ff", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (item.priority == "medium" ? (
                                            <DensityMedium sx={{color:"#ff6600", stroke: "#ff6600", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (
                                            <KeyboardDoubleArrowUp sx={{color:"crimson", stroke: "crimson", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ))}
                                    </Box>
                                    <Box sx={{height:"50px"}}>
                                        <Typography sx={{marginTop:"5px"}}>{item.title}</Typography>
                                    </Box>
                                        {item.category == "general" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"gray", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "tabular_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#5555dd", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "image_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#ff3374", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "model" ? (
                                            <Tooltip title={"Model name"}>
                                                <Typography sx={{backgroundColor:"#33ff77", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={"API name"}>
                                                <Typography sx={{backgroundColor:"#33d6ff", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        )))
                                        )}
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"10px"}}>
                                        <Tooltip title={"Assigned team"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.team.name}</Typography>
                                        </Tooltip>
                                        <Tooltip title={"Assigned by"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.assigned_by.first_name} {item.assigned_by.last_name}</Typography>
                                        </Tooltip>
                                    </Box>
                                </Paper>
                            )
                        })
                    ) : (
                        <Typography variant='subtitle2' sx={{fontSize:"12pt", fontStyle:"italic", fontWeight:400, color:"#333333"}}>There are no items here</Typography>
                    )}
                </Box>
                <Box sx={{flex:1, display:"flex", flexDirection:"column", height:"95%", gap:"10px", padding:"10px", backgroundColor:"#E0E0E0", borderRadius:"10px", overflowY:"scroll"}}>
                    <Box sx={{display:"flex", gap:"10px"}}>
                        <Typography fontWeight={900}>COMPLETED</Typography>
                        <Typography sx={{color:"gray"}}>[ {completedItems.length} ]</Typography>
                    </Box>
                    {completedItems.length > 0 ? (
                        completedItems.map(item => {
                            return (
                                <Paper elevation={3} sx={{display:"flex", flexDirection:"column", backgroundColor:"white", padding:"15px 15px 10px 15px", cursor:"pointer"}} onClick={() => handleDisplayAssignment(item)}>
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                        {item.category == "general" ? (
                                                <Chip label={"GENERAL"} size='small' />
                                            ) : (
                                            item.category == "tabular_dataset" ? (
                                                <Chip label={"TABULAR DATASET"} size='small' sx={{backgroundColor:"lavender"}} />
                                            ) : (item.category == "image_dataset" ? (
                                                <Chip label={"IMAGE DATASET"} size='small' sx={{backgroundColor:"#ffe6ee"}} />
                                            ) : (item.category == "model" ? (
                                                <Chip label={"MODEL"} size='small' sx={{backgroundColor:"#ccffdd"}} />
                                            ) : (
                                                <Chip label={"API"} size='small' sx={{backgroundColor:"#ccf5ff"}} />
                                            )))
                                        )}
                                        {item.priority == "low" ? (
                                            <KeyboardDoubleArrowDown sx={{color:"#3399ff", stroke: "#3399ff", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (item.priority == "medium" ? (
                                            <DensityMedium sx={{color:"#ff6600", stroke: "#ff6600", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ) : (
                                            <KeyboardDoubleArrowUp sx={{color:"crimson", stroke: "crimson", strokeWidth: 1, backgroundColor:"#E0E0E0", borderRadius:"50%", padding:"2px"}} />
                                        ))}
                                    </Box>
                                    <Box sx={{height:"50px"}}>
                                        <Typography sx={{marginTop:"5px"}}>{item.title}</Typography>
                                    </Box>
                                        {item.category == "general" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"gray", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "tabular_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#5555dd", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "image_dataset" ? (
                                            <Tooltip title={"Dataset name"}>
                                                <Typography sx={{backgroundColor:"#ff3374", alignSelf:"flex-start", color:"#f2f2f2", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (item.category == "model" ? (
                                            <Tooltip title={"Model name"}>
                                                <Typography sx={{backgroundColor:"#33ff77", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title={"API name"}>
                                                <Typography sx={{backgroundColor:"#33d6ff", alignSelf:"flex-start", color:"#333333", padding:"0px 5px", marginTop:"10px", borderRadius:"3px"}}>{item.object_name}</Typography>
                                            </Tooltip>
                                        )))
                                        )}
                                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"10px"}}>
                                        <Tooltip title={"Assigned team"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.team.name}</Typography>
                                        </Tooltip>
                                        <Tooltip title={"Assigned by"}>
                                            <Typography variant='subtitle2' sx={{fontSize:"11pt", fontWeight:400, color:"#777777"}}>{item.assigned_by.first_name} {item.assigned_by.last_name}</Typography>
                                        </Tooltip>
                                    </Box>
                                </Paper>
                            )
                        })
                    ) : (
                        <Typography variant='subtitle2' sx={{fontSize:"12pt", fontStyle:"italic", fontWeight:400, color:"#333333"}}>There are no items here</Typography>
                    )}
                </Box>
            </Box>
        </PageContainer>
        <AssignmentModal />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ProjectManagement