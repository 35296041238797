import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

const SolutionVideoDemo = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        paddingX: { xs: '5%', md: '15%' }, // Responsive padding
        paddingY: { xs: '100px', md: '100px' }, // Responsive padding
        boxSizing: 'border-box',
      }}
    >
      <Typography variant="h3" fontSize={{xs:"24pt", md:"36pt"}}>See Utimind No-Code AI Platform</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Responsive layout
          marginTop: '30px',
          gap: { xs: '20px', md: '80px' }, // Responsive gap
          alignItems: 'center', // Center content vertically
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: { xs: 'auto', md: '320px' }, // Responsive height
            width: '100%',
            maxWidth: '700px',
            backgroundColor: 'blue',
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/Oskk-xipi7Q"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px', // Adds rounded corners
            }}
          ></iframe>
        </Box>

        <Box
          sx={{
            flex: 1,
            marginTop: { xs: '20px', md: '0px' }, // Adjust margin for mobile
            textAlign: { xs: 'center', md: 'left' }, // Center text on mobile
          }}
        >
          <Typography variant="h5" fontWeight={300} color="#555555" fontSize={{xs:"16pt", md:"18pt"}}>
            Unlock the full potential of your data with Utimind’s all-in-one AI platform. From data management and image annotation to model training, evaluation, and deployment, Utimind simplifies the entire process—no coding required.
            <br />
            <br />
            Watch our demo to see how easy it is to build and deploy powerful AI models, driving faster insights and smarter decisions across your business.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SolutionVideoDemo;
