import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React from 'react'

const Personas = ({modelData}) => {

    const theme = useTheme();

  return (
    <Box sx={{display:"flex", flexDirection:"row", gap:"20px", marginTop:"20px", overflowX:"scroll"}}>
        {Object.keys(modelData["max_probabilities"]).map((className, index) => {
            const probability = modelData["max_probabilities"][className] ? modelData["max_probabilities"][className]["probability"] : null;
            const sample = modelData["max_probabilities"][className] ? modelData["max_probabilities"][className]["sample"] : null;
            const tableData = sample ? Object.keys(sample).map(key => ({
                feature: key,
                value: sample[key]
              })) : null;
            return (
                <Box sx={{display:"flex", 
                          flexDirection:"column",
                          padding:"10px",
                          backgroundColor:theme.palette.background.lighter,
                          border:"1px solid gray",
                          borderRadius:"5px",
                          minWidth:"300px"}}>
                    <Typography>Persona #{index+1}</Typography>
                    <Typography variant='subtitle2' fontWeight={300} fontSize={"11pt"}>This persona has <b>{(probability * 100).toFixed(2)}%</b> probability for <b>{modelData["target_feature"]}</b> is <b>{className}</b></Typography>
                    <TableContainer sx={{marginTop:"10px", borderRadius:"5px", backgroundColor:theme.palette.background.default, maxHeight:"600px"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Feature</TableCell>
                                    <TableCell>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData && tableData.map(row => {
                                    return (
                                        <TableRow>
                                            <TableCell>{row.feature}</TableCell>
                                            <TableCell>{row.value}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )
        })}
    </Box>
  )
}

export default Personas