import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Box, Typography, useMediaQuery } from '@mui/material';
import HomeNavbar from '../components/home/HomeNavbar';
import Navbar from '../components/global/Navbar';

export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const isLargeScreen = useMediaQuery('(min-width:720px)');

    if (!isAuthenticated) {
        return <Navigate to="/platform/login" replace />;
    }

    if (!isLargeScreen) {
        return <Box>
            <Navbar />
            <Typography variant="h6" textAlign="center" sx={{margin:"20px"}}>
                The platform is not available on smaller screens. Please use a larger device or increase your browser window size.
            </Typography>
        </Box>;
    }

    return children;
};