import styled from '@emotion/styled'
import { AccountBalance, AccountCircle, AirplaneTicket, Circle, Help, Logout, Mail, ManageAccounts, ManageAccountsOutlined, NotificationAdd, Notifications, Person, Person3Outlined, PersonOutline, Support } from '@mui/icons-material'
import { AppBar, Avatar, Badge, Box, InputBase, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, notification, user } from '../../apis/urls';
import { getRequest, postRequest, putRequest } from '../../apis/requests';
import utienceLogo from "../../assets/images/utience_logo_secondary.svg";


const Icons = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
}));

const Navbar = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const notificationMenuOpen = Boolean(notificationAnchorEl);
  const [badgeCount, setBadgeCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const auth = useAuth();
  const authToken = localStorage.getItem("authToken")
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);


  useEffect(() => {
    const url = user.getUser();
    getRequest(url, {"Authorization": authToken})
        .then(data => {
          setUserData(data);
        });
  }, [])

  useEffect(() => {
    getNotifications();
    const intervalId = setInterval(() => {
      getNotifications();
    }, 10000);
  
    return () => clearInterval(intervalId);
  }, []);

  const getNotifications = () => {
    const url = notification.getByUserId();
    const headers = {"Authorization": authToken}
    getRequest(url, headers)
      .then(data => {
        if (data){
          const notificationCount = data.reduce((count, notification) => {
            if (!notification.is_seen) {
              return count + 1;
            }
            else {
              return count;
            }
          }, 0);
          setBadgeCount(notificationCount)
          setNotifications(data);
        }
      });
  }

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleMarkRead = (notification_id, notification_resource_type) => {
    const url = notification.markAsRead(notification_id);
    const headers = {"Authorization": authToken}
    putRequest(url, headers)
      .then(response => response.json())
      .then(data => {
        const notificationCount = data.reduce((count, notification) => {
          if (!notification.is_seen) {
            return count + 1;
          }
          else {
            return count;
          }
        }, 0);
        setBadgeCount(notificationCount)
        setNotifications(data);
      })

    if(notification_resource_type == "team_join_request"){
      navigate("/platform/team_management");
    }
    else if (notification_resource_type == "model"){
      navigate("/platform/models")
    }
    else if (notification_resource_type == "dataset"){
      navigate("/platform/datasets")
    }
    else if (notification_resource_type == "image_dataset"){
      navigate("/platform/image_datasets")
    }
    else if (notification_resource_type == "license_sharing"){
      navigate("/platform/account")
    }
    else if (notification_resource_type == "assignment"){
      navigate("/platform/project_management")
    }
  }

  const handleMarkAllRead = () => {
    const url = notification.markAllAsRead();
    const headers = {"Authorization": authToken}
    putRequest(url, headers)
      .then(response => response.json())
      .then(data => {
        const notificationCount = data.reduce((count, notification) => {
          if (!notification.is_seen) {
            return count + 1;
          }
          else {
            return count;
          }
        }, 0);
        setBadgeCount(notificationCount)
        setNotifications(data);
      })
  }


  return (
    <AppBar position='sticky'>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between"}}>
        <img src={utienceLogo} alt='logo' style={{cursor:"pointer", height:"70px", width:"auto"}} onClick={() => {navigate("/")}} />
        {userData ? (
          <Icons>
            <Tooltip title="Support Tickets">
              <Badge onClick={() => navigate("/platform/help")} sx={{cursor:"pointer"}}>
                <Help />
              </Badge>
            </Tooltip>
            <Tooltip title="Notifications">
              <Badge badgeContent={badgeCount} color='error' onClick={handleNotificationClick} sx={{cursor:"pointer"}}>
                <Notifications />
              </Badge>
            </Tooltip>
            <Menu
              anchorEl={notificationAnchorEl}
              open={notificationMenuOpen}
              onClose={handleNotificationClose}
              sx={{marginTop:"10px", maxHeight:"400px", maxWidth:"600px"}}
            >
              {notifications && notifications.length > 0 ? (
                <Box sx={{padding:"20px"}}>
                  <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <Typography variant='subtitle1'>Notifications</Typography>
                    <Typography variant="subtitle1" sx={{color:"blue", cursor:"pointer", textDecoration:"underline"}} onClick={handleMarkAllRead}>Mark all as seen</Typography>
                  </Box>
                  <hr />
                  {notifications.map(notification => {
                    return (
                      <Tooltip title={`${notification.title} ${notification.content}`}>
                        <MenuItem onClick={() => handleMarkRead(notification.id, notification.resource_type)}><Typography variant='h7' fontWeight={notification.is_seen ? 100 : 900}>{notification.title} {notification.content}</Typography></MenuItem>
                      </Tooltip>
                    )
                  })}
                </Box>
              ) : (
                <Box sx={{width:"300px", padding:"5px 20px"}}>
                   <Typography variant='subtitle1'>No unread notifications.</Typography>
                </Box>
              )}
            </Menu>

            <Typography variant='span' sx={{fontSize:"11pt"}}>{userData["first_name"]} {userData["last_name"]}, {userData["user_license"]["license_type"]} License</Typography>
            {userData["user_info"]["profile_picture"] ? (
                <Avatar 
                  sx={{ width:40, height:40, cursor:"pointer" }} 
                  src={`${BASE_URL}${userData["user_info"]["profile_picture"]}`}
                  onClick={handleMenuClick}
                />
            ) : (
                <Avatar 
                  sx={{ width:40, height:40, cursor:"pointer" }} 
                  src={require("../../assets/images/blank_profile.png")}
                  onClick={handleMenuClick}
                />
            )}
          </Icons>
        ) : (
          <></>
        )}

      </Toolbar>
      <Menu
        open={menuOpen}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{marginTop:"45px"}}
      >
        <MenuItem onClick={() => navigate("/platform/profile")} sx={{width:"200px", marginY:"5px"}}>
          <ListItemIcon>
            <PersonOutline />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={() => navigate("/platform/account")} sx={{width:"200px", marginY:"5px"}}>
          <ListItemIcon>
            <ManageAccountsOutlined />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem onClick={() => auth.logout()} sx={{width:"200px", marginY:"5px"}}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  )
}

export default Navbar