import { Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getRequest, putRequest } from '../apis/requests'
import { user, BASE_URL } from "../apis/urls"
import { Edit } from '@mui/icons-material'
import PersonalInfo from '../components/user/PersonalInfo'
import ChangePassword from '../components/user/ChangePassword'
import Preferences from '../components/user/Preferences'

const Profile = () => {

    const authToken = localStorage.getItem('authToken');
    const theme = useTheme();
    const [userData, setUserData] = useState(null);
    const profilePictureInputRef = useRef(null);

    useEffect(() => {
        const url = user.getUser();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setUserData(data);
            });
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("profile_picture", file);

            const url = user.updateProfilePicture();
            const headers = {
                                "Authorization": authToken
                            };
            
            putRequest(url, headers, formData)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(data => {
                    setUserData(data);
                    window.location.reload();
                })
        }
    }

    const [tabIndex, setTabIndex] = React.useState(0);
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }


  return (
    <Box sx={{display:"flex", flexDirection:"row", width:"100%", height:"80vh"}}>
        {userData ? (
            <>
                <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                    <Box sx={{display:"flex", flexDirection:"column", height:"300px", alignItems:"center", justifyContent:"center"}}>
                        <input
                            type="file"
                            ref={profilePictureInputRef}
                            onChange={handleFileChange}
                            style={{ display: 'none' }} // Hide the file input
                        />
                        <Box sx={{height:"200px",
                                width:"200px",
                                position: 'relative',
                                cursor: "pointer",
                                '&:hover .hover-icon': {
                                    visibility: 'visible',
                                },
                                '&:hover': {
                                    filter: "brightness(50%)",
                                }}}
                                onClick={() => profilePictureInputRef.current.click()}>
                            {userData["user_info"]["profile_picture"] ? (
                                <img src={`${BASE_URL}${userData["user_info"]["profile_picture"]}`} style={{width:"100%", height:"100%", objectFit:"cover", borderRadius:"50%"}}/>
                            ) : (
                                <img src={require("../assets/images/blank_profile.png")} alt='profile_picture' style={{width:"100%", height:"100%", objectFit:"cover", borderRadius:"50%"}} />
                            )}
                            <Edit className="hover-icon" sx={{color:"white",
                                                              position: 'absolute',
                                                              height:"30px",
                                                              width:"30px",
                                                              top: '50%',
                                                              left: '50%',
                                                              transform: 'translate(-50%, -50%)',
                                                              visibility: 'hidden'}} />
                        </Box>
                        <Typography variant='h6' color={theme.palette.text.dark} sx={{marginTop:"20px"}}>{userData["first_name"]} {userData["last_name"]}</Typography>
                        {userData.user_info.company ? (
                            <Typography variant='h7' color={theme.palette.text.dark} sx={{fontWeight:100}}>{userData.user_info.company}</Typography>
                        ) : (
                            <></>
                        )}
                        {userData.user_info.title ? (
                            <Typography variant='subtitle2' color={theme.palette.text.dark} sx={{fontWeight:100}}>{userData.user_info.title}</Typography>
                        ) : (
                            <></>
                        )}
                    </Box>
                    <Box>
                        <List>
                            <ListItem disablePadding onClick={() => setTabIndex(0)}>
                                <ListItemButton selected={tabIndex === 0}>
                                    <ListItemText primary="Personal Info" />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => setTabIndex(1)}>
                                <ListItemButton selected={tabIndex === 1}>
                                    <ListItemText primary="Change Password" />
                                </ListItemButton>
                            </ListItem>
                            {/* <ListItem disablePadding onClick={() => setTabIndex(2)}>
                                <ListItemButton selected={tabIndex === 2}>
                                    <ListItemText primary="Preferences" />
                                </ListItemButton>
                            </ListItem> */}
                        </List>
                    </Box>
                </Box>
                <hr />
                <Box sx={{flex:4, display:"flex", flexDirection:"column"}}>
                    <TabPanel value={tabIndex} index={0}>
                        <PersonalInfo userData={userData} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <ChangePassword />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <Preferences />
                    </TabPanel>
                </Box>
            </>
        ) : (
            <CircularProgress />
        )}
        
    </Box>
  )
}

export default Profile