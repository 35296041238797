import { Box, Grid, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'

import timeImage from "../../assets/images/sand-clock.png";
import costImage from "../../assets/images/down-align.png";
import techImage from "../../assets/images/coding.png";
import hrImage from "../../assets/images/group.png";

const WhyUtimind = () => {

    const theme = useTheme();

    const data = [
      {
        title: 'Time',
        icon: timeImage,
        traditional: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>Months</b> to develop and deploy AI solutions</Typography>,
        utimind: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>Hours</b> with no-code automation</Typography>,
        color: 'lavender',
      },
      {
        title: 'Cost',
        icon: costImage,
        traditional: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>$240,000/year</b> for staff, tools and infra</Typography>,
        utimind: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>$12,000/year</b> for an affordable annual plan</Typography>,
        color: '#ccf5ff',
      },
      {
        title: 'Tech Level',
        icon: techImage,
        traditional: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>Highly specialized</b> knowledge in AI and software</Typography>,
        utimind: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>For any tech level</b> with intuitive no-code interface</Typography>,
        color: '#ccffdd',
      },
      {
        title: 'Human Resource',
        icon: hrImage,
        traditional: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>Recruit and retain</b> a skilled team of data scientists</Typography>,
        utimind: <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}} textAlign={{xs:"center", md:"left"}}><b>Empowers existing staff</b> to build and deploy AI</Typography>,
        color: '#ffe6ee',
      },
    ];
  

  return (
    <Box sx={{ width: "100%", display:"flex", flexDirection:"column", alignItems:"center", paddingTop:"100px", paddingBottom:{xs:"0px", md:"100px"}, backgroundColor:theme.palette.background.lighter}}>
        <Typography variant='h3' fontWeight={400} fontSize={{xs:"7.5vw", sm: "7.5vw", md:"3vw"}} textAlign={"center"}>Save time and reduce costs <br />building complex AI models</Typography>
        <Box sx={{ display: 'flex', flexDirection:"column", alignContent:"center", width:{xs:"95%", md:"70%"}, marginTop:"70px"}}>
          <Box sx={{display:"flex", flexDirection:"row", width:"100%", alignItems:"center", justifyContent:"center", gap:{xs:"7vw", sm:"7vw", md:"9vw"}}}>
            <Typography textAlign={"center"} fontSize={{xs:"4vw", sm: "4vw", md:"2vw"}}>
              TRADITIONAL <br />AI DEVELOPMENT
            </Typography>
            <Typography fontSize={{xs:"4vw", sm: "4vw", md:"2vw"}}>
              vs
            </Typography>
            <Typography textAlign={"center"} fontSize={{xs:"4vw", sm: "4vw", md:"2vw"}}>
              UTIMIND NO-CODE <br/>AI PLATFORM
            </Typography>
          </Box>
          <Box>
            {data.map(item => {
              return (
                <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", marginTop:"20px"}}>
                  <Paper elevation={3} sx={{display:"flex", flexDirection:"column", width:"80%", paddingX:{xs:"10px", md:"0px"}, alignItems:"center", justifyContent:{xs:"flex-start", md:"center"}, backgroundColor:item.color, borderRadius:{xs:"5px", md:"10px"}, height:{xs:"120px", md:"90px"}, paddingTop: {xs:"5px", md:"0"}, marginBottom:"25px"}}>
                    <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}}>{item.title}:</Typography>
                    {item.traditional}
                  </Paper>
                  <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width:{xs:"20vw", md:"9vw"}, height:{xs:"9vw", md:"5vw"}, backgroundColor:item.color, border:"5px solid #dddddd", borderRadius:"50%", marginTop:{xs:"40px", md:"0"}, marginX:"10px"}}>
                    <img src={item.icon} alt={item.title} width={"60%"} height={"auto"} />
                  </Box>
                  <Paper elevation={3} sx={{display:"flex", flexDirection:"column", width:"80%", paddingX:{xs:"10px", md:"0px"}, alignItems:"center", justifyContent:{xs:"flex-start", md:"center"}, backgroundColor:item.color, borderRadius:{xs:"5px", md:"10px"}, height:{xs:"120px", md:"90px"}, paddingTop: {xs:"5px", md:"0"}, marginBottom:"25px"}}>
                    <Typography fontWeight={300} fontSize={{xs:"4vw", md:"16pt"}}>{item.title}:</Typography>
                    {item.utimind}
                  </Paper>
                </Box>
              )
            })}
          </Box>
        </Box>
    </Box>
  )
}

export default WhyUtimind