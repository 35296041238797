// LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { Alert, Box, Button, CircularProgress, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { user } from '../apis/urls';
import { postRequest } from '../apis/requests';

import utienceLogo from "../assets/images/utience_logo_primary.svg";
import loginBackground from "../assets/images/login_background.jpg";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [didntReceiveEmail, setDidntReceiveEmail] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);

    const auth = useAuth();
    const theme = useTheme();
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
            }
        
            setSnackbarOpen(false);
    }

    useEffect(() => {
        if(auth.isAuthenticated) {
            navigate("/platform");
        }
    }, [])

    const handleLogin = () => {
        setIsInProgress(true);
        
        const headers = {
            'Content-Type': 'application/json'
        }

        const body = JSON.stringify({
            username: email,
            password: password
        });

        const url = user.login();
        postRequest(url, headers, body)
            .then(response => {
                if(response){
                    return response.json();
                }
                else{
                    return {"error": "An error occurred."}
                }
            })
            .then(data => {
                if (data["token"]) {
                    auth.login(data);
                    navigate('/platform/');
                }
                else if (data["error"]) {
                    handleSnackbarOpen("error", data["error"]);
                    if (data["error"] == "Please activate your account.") {
                        setDidntReceiveEmail(true);
                    }
                }
                setIsInProgress(false);
            })
    };

    const handleForgotPassword = () => {
        navigate("/platform/forgot-password");
    }

    const genericDomains = [
        "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", 
        "live.com", "aol.com", "icloud.com", "mail.com"
    ];
  
    // Function to validate the email
    const validateEmail = (email) => {
        // Basic email format regex
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  
        if (!emailRegex.test(email)) {
          handleSnackbarOpen("error", "Please enter a valid email address");
          return false;
        }
  
        // Extract the domain from the email
        const domain = email.split('@')[1];
  
        // Check if the domain is in the list of generic domains
        if (genericDomains.includes(domain)) {
          handleSnackbarOpen("error", "Please enter a business email address");
          return false;
        }
  
        return true; // Valid organizational email
    };

    const handleResendActivationMail = () => {
        if (validateEmail(email)) {
            const headers = {
                'Content-Type': 'application/json'
            }
    
            const body = JSON.stringify({
                username: email
            });
    
            const url = user.resendActivationMail();
            postRequest(url, headers, body)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data["message"]) {
                        handleSnackbarOpen("success", data["message"]);
                    }
                    else if (data["error"]) {
                        handleSnackbarOpen("error", data["error"]);
                        setDidntReceiveEmail(true);
                    }
                })    
        }
    }

    return (
        <Box sx={{ width:"100%", height:"100%", backgroundImage:`url(${loginBackground})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
            <Box sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                top: "50%", 
                left: "50%", 
                transform: "translate(-50%, -50%)",
                width: {xs:"75vw", md:"350px"},
                height: {xs:"160vw", md:"550px"},
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: 24,
                p: 4,
                gap: "50px"
            }}>
                <Box sx={{width:{xs:"100%", md:"100%"}, height:"100px", display:"flex", justifyContent:"center"}}>
                    <img src={utienceLogo} style={{height:"100px", width:"auto"}} onClick={() => navigate("/")} />
                </Box>
                <Box sx={{display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: {xs:"100%", md:"90%"},
                        borderRadius: "10px"}}>
                    <Typography variant="h6" sx={{color: theme.palette.text.dark}}>Log In</Typography>
                    <TextField onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleLogin();
                            }}
                        label="Email" type='email' variant="outlined" sx={{
                            mt: 4,
                            width:{xs:"100%", md:"100%"},
                        }} />
                    <TextField onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleLogin();
                            }}
                        label="Password" type='password' variant="outlined" sx={{
                            mt: 2,
                            width:{xs:"100%", md:"100%"},
                        }} />
                    <Button onClick={handleLogin} disabled={isInProgress} variant='contained' sx={{
                        mt: 2,
                        height: "55px",
                        width: {xs:"100%", md:"100%"},
                    }}>
                        Login
                    </Button>
                    {isInProgress && (
                        <CircularProgress />
                    )}
                    <Typography sx={{mt:4, cursor: "pointer", color:"blue"}} onClick={handleForgotPassword} fontWeight={100}>Forgot your password?</Typography>
                    <Typography sx={{mt:1}} fontWeight={100}><a href='signup' style={{textDecoration:"none", color:"blue"}}>Don't have account?</a></Typography>
                    {didntReceiveEmail && (
                        <Typography sx={{mt:1, cursor: "pointer", color:"crimson"}} onClick={handleResendActivationMail} fontWeight={900}>Didn't receive activation email?</Typography>
                    )}
                </Box>
            </Box>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Login