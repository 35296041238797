import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getRequest } from "../apis/requests";
import { user } from "../apis/urls";
import { Box, Typography, useTheme } from '@mui/material';
import { ErrorOutline, TaskAlt } from '@mui/icons-material';

const Activate = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [infoText, setInfoText] = useState("");
    const [infoCode, setInfoCode] = useState("");

    useEffect(() => {
        const activation_url = user.activate() + window.location.href.split("activate/")[1]

        getRequest(activation_url, {})
            .then(data => {
                setInfoText(data["message"]);
                setInfoCode(data["code"]);
            })
    }, [])

  return (
    <Box sx={{ 
        width:"100%", 
        height:"100%", 
        backgroundColor: theme.palette.background.lighterblue,
        display: "flex",
        flexDirection: "column",
        paddingTop: "15%",
        alignItems: "center"}}>
        {(infoCode == "user_already_active" || infoCode == "activation_success") ? (
            <TaskAlt style={{ height:"75px", width: "75px", fill: "green"}} />
        ) : (
            <ErrorOutline style={{ height:"75px", width: "75px", fill: "red"}} />
        )
        }
        <Typography 
            variant='h5'
            width={"50%"}
            textAlign={"center"}
            mt={2}
        >{infoText}</Typography>
        <a href="/platform/login">Back to Login</a>
    </Box>
  )
}

export default Activate