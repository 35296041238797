import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import numericImage from "../../assets/images/number-blocks.png";
import categoricImage from "../../assets/images/shapes.png";
import booleanImage from "../../assets/images/technology.png";
import dateTimeImage from "../../assets/images/schedule.png";
import classificationImage from "../../assets/images/classification.png";
import regressionImage from "../../assets/images/regression.png";
import unknownImage from "../../assets/images/null.png";
import { Check, CheckBoxOutlineBlankRounded, CheckCircleOutlineOutlined, Close, CloseRounded, ClosedCaptionOffRounded } from '@mui/icons-material';


const DTYPE_CONVERSIONS = {
    'object': 'Category',
    'category': 'Category',
    'datetime64': 'DateTime',
    'timedelta64': 'DateTime',
    'str': 'Category',
    'U': 'Category',
    'int64': 'Number',
    'int32': 'Number',
    'int16': 'Number',
    'int8': 'Number',
    'uint64': 'Number',
    'uint32': 'Number',
    'uint16': 'Number',
    'uint8': 'Number',
    'float64': 'Number',
    'float32': 'Number',
    'float16': 'Number',
    'bool': 'Boolean',
    'bool_': 'Boolean'
}

// Function to bin numeric data into ranges
const binNumericData = (values, maxBins = 100) => {
  const numericValues = values.map(Number).filter(v => !isNaN(v));
  const min = Math.min(...numericValues);
  const max = Math.max(...numericValues);
  let binSize;

  // Adjust bin size for values between 0 and 1
  if (min >= 0 && max <= 1) {
    binSize = 0.1; // Smaller bin size for detailed distribution in 0-1 range
  } else {
    binSize = Math.ceil((max - min) / maxBins);
  }

  const bins = {};

  numericValues.forEach(value => {
    const bin = Math.floor((value - min) / binSize) * binSize + min;
    bins[bin.toFixed(2)] = (bins[bin.toFixed(2)] || 0) + 1;
  });

  return Object.entries(bins).map(([bin, count]) => ({
    name: `${bin} - ${(parseFloat(bin) + binSize).toFixed(2)}`,
    count
  }));
};
  
  // Function to process data into format suitable for charts
  const processData = (columnData, maxCategories = 100) => {
    const counts = {};
    const values = Object.values(columnData);
  
    // Check if data is numeric or categorical
    if (values.every(v => !isNaN(v))) {
      // Bin numeric data if too many unique values
      return binNumericData(values);
    } else {
      // Count occurrences for categorical data
      values.forEach(value => {
        counts[value] = (counts[value] || 0) + 1;
      });
  
      // Limit to the top categories by count
      const sortedEntries = Object.entries(counts).sort((a, b) => b[1] - a[1]);
      const limitedEntries = sortedEntries.slice(0, maxCategories);
      return limitedEntries.map(([key, count]) => ({ name: key, count }));
    }
  };

const DataDistribution = ({ data, columnTypes, taskTypesByColumn, columnsIdealForPrediction }) => {
  const [selectedTab, setSelectedTab] = useState(Object.keys(data)[0]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', marginTop:"10px", marginBottom:"50px" }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="columns tabs"
      >
        {Object.keys(data).map((column, index) => (
          <Tab key={index} label={column} value={column} />
        ))}
      </Tabs>

      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" marginBottom="10px">Distribution of Column {selectedTab}</Typography>
        <Typography sx={{display:"flex", gap:"10px", alignItems:"center", marginBottom:"10px"}}>Data Type: <b>{DTYPE_CONVERSIONS[columnTypes[selectedTab]]}</b> 
            <img src={DTYPE_CONVERSIONS[columnTypes[selectedTab]] == "Number" ? numericImage : DTYPE_CONVERSIONS[columnTypes[selectedTab]] == "Category" ? categoricImage : DTYPE_CONVERSIONS[columnTypes[selectedTab]] == "DateTime" ? dateTimeImage : booleanImage
                } alt={DTYPE_CONVERSIONS[columnTypes[selectedTab]]} style={{height:"30px", width:"auto"}} /></Typography>
        <Typography sx={{display:"flex", gap:"10px", alignItems:"center", marginBottom:"10px"}}>Suggested Task Type: <b>{taskTypesByColumn[selectedTab]}</b>
            <img src={taskTypesByColumn[selectedTab] == "classification" ? classificationImage : taskTypesByColumn[selectedTab] == "regression" ? regressionImage : unknownImage} alt={taskTypesByColumn[selectedTab]} style={{height:"30px", width:"auto"}} /></Typography>
        <Typography sx={{display:"flex", gap:"10px", alignItems:"center", marginBottom:"10px"}}>Ideal For Prediction: <b>{columnsIdealForPrediction[selectedTab] ? "Yes": "No"}</b>
            {columnsIdealForPrediction[selectedTab] ? <CheckCircleOutlineOutlined color='success' sx={{height:"30px", width:"auto"}} /> : <CloseRounded color='error' sx={{height:"30px", width:"auto"}} /> }</Typography>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={processData(data[selectedTab])}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DataDistribution;
