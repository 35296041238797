import { Alert, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getRequest, postRequest } from "../apis/requests";
import { model } from "../apis/urls";
import { Analytics, BatchPrediction, Business, Insights, ReadMore } from '@mui/icons-material';
import Summary from '../components/model/Summary';
import Personas from '../components/model/Personas';
import Effects from '../components/model/Effects';
import ModelBuildModal from '../components/model/ModelBuildModal';

const Overview = ({modelData, handleTryDifferentAlgorithms}) => {

    const theme = useTheme();

    return (
        <Box sx={{display:"flex", flexDirection:"column", padding:"20px", gap:"20px"}}>
            <Box sx={{display:"flex", 
                      flexDirection:"column", 
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>{modelData["name"]} Summary</Typography>
                <Typography variant='subtitle1' fontWeight={300}>This machine learning model is trained to predict <b>{modelData["target_feature"]}</b> with <b>{["accuracy", "f1", "roc_auc"].includes(modelData["optimization_metric"]) ? `${(100 * modelData["last_test_performance"]).toFixed(2)}%` : modelData["last_test_performance"].toFixed(2)} {modelData["optimization_metric"]}</b> as test performance.</Typography>
                <Summary modelData={modelData} />
            </Box>
            <Box sx={{display:"flex", 
                      flexDirection:"row",
                      alignItems: "center",
                      gap: "20px",
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>Do you want to see how other algorithms perform on this task?</Typography>
                <Button variant='contained' sx={{fontSize:"12pt"}} onClick={handleTryDifferentAlgorithms}>Try Different Algorithms</Button>
            </Box>
            {modelData["effects"] && 
                <Box sx={{display:"flex", 
                            flexDirection:"column", 
                            padding:"20px",
                            border: "1px solid gray",
                            borderRadius: "5px"}}>
                    <Typography variant='h6'>Key Predictors</Typography>
                    <Typography variant='subtitle1' fontWeight={300}>How each feature affects the likelihood of <b>{modelData["target_feature"]}</b> independently.</Typography>
                    <Effects modelData={modelData} />
                </Box>
            }
            {modelData["target_type"] == "classification" ? (
                <Box sx={{display:"flex", 
                            flexDirection:"column", 
                            padding:"20px",
                            border: "1px solid gray",
                            borderRadius: "5px"}}>
                    <Typography variant='h6'>Personas</Typography>
                    <Typography variant='subtitle1' fontWeight={300}>Features that yields the most probable outcomes for each class.</Typography>
                    <Personas modelData={modelData} />
                </Box>
            ) : (
                <></>
            )}
        </Box>
    )
}

const ActionableSuggestions = ({modelData}) => {
    const theme = useTheme();
    const authToken = localStorage.getItem("authToken");

    const [classes, setClasses] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState("maximize");
    const [suggestionsLoading, setSuggestionsLoading] = useState(false);
    const [generatedSuggestions, setGeneratedSuggestions] = useState(null);

    useEffect(() => {
        if (modelData && modelData["effects"]) {
            setClasses((Object.keys(Object.values(Object.values(modelData["effects"])[0])[0])));
            setSelectedClass((Object.keys(Object.values(Object.values(modelData["effects"])[0])[0]))[0])
        }
        if (modelData && modelData["business_suggestions"] && Object.keys(modelData["business_suggestions"]).length > 0) {
            setGeneratedSuggestions(modelData["business_suggestions"]);
        }
    }, [])

    const handleGetSuggestions = () => {
        setSuggestionsLoading(true);

        const content = `Goal is to ${selectedObjective} "${selectedClass}". ${modelData["target_type"] == "classification" ? "Fold Changes" : "Changes in unit"}: ${JSON.stringify(modelData["effects"])}`;
        
        const url = model.getBusinessSuggestions(modelData["id"]);
        const headers = {
            "Content-Type": "application/json",
            "Authorization": authToken
        }
        const data = JSON.stringify({
            "content": content,
        })
        postRequest(url, headers, data)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                if (data["data"]) {
                    setGeneratedSuggestions(data["data"]);
                    handleSnackbarOpen("success", "Business suggestions generated successfully.")
                }
                else if (data["error"]) {
                    handleSnackbarOpen("error", data["error"]);
                }
                else {
                    handleSnackbarOpen("error", "Unknown error occured. If issue persists, please open a support ticket.")
                }
                setSuggestionsLoading(false);
            })
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    return (
        <Box sx={{display:"flex", flexDirection:"column", padding:"20px", gap:"20px"}}>
            <Box sx={{display:"flex", 
                      flexDirection:"column", 
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>{modelData["name"]} Actionable Suggestions</Typography>
                <Typography variant='subtitle1' fontWeight={300}>AI-generated actionable suggestions to improve <b>{modelData["target_feature"]}</b>.</Typography>
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", marginTop:"20px", gap:"10px"}}>
                    <Typography>The business objective is to </Typography>
                    <Select
                        sx={{height:"24pt"}}
                        value={selectedObjective}
                        onChange={(e) => setSelectedObjective(e.target.value)}
                        >
                        {["maximize", "minimize"].map((cls, index) => (
                            <MenuItem key={index} value={cls}>
                                {cls}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        sx={{height:"24pt"}}
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        >
                        {classes && classes.map((cls, index) => (
                            <MenuItem key={index} value={cls}>
                                {cls}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button variant='contained' disabled={suggestionsLoading} sx={{fontSize:"12pt", height:"24pt", textTransform:"none", marginLeft:"50px"}} onClick={handleGetSuggestions}>Get New Suggestions</Button>
                    {suggestionsLoading && (
                        <CircularProgress sx={{marginLeft:"20px"}} />
                    )}
                </Box>
            </Box>
            {modelData["effects"] && 
                <Box sx={{display:"flex", 
                            flexDirection:"column", 
                            padding:"20px",
                            border: "1px solid gray",
                            borderRadius: "5px"}}>
                    <Typography variant='h6'>Generated Suggestions</Typography>
                    <Typography variant='subtitle1' fontWeight={300}>Please use our AI-generated suggestions as helpful guidance, and consult with experts as needed to best suit your specific needs.</Typography>
                    <Box sx={{height:"400px", overflow:"scroll"}}>
                        {generatedSuggestions && Object.keys(generatedSuggestions).length > 0 ? (
                            <Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={"10%"} sx={{fontWeight:700, fontSize:"12pt"}}>Feature</TableCell>
                                                <TableCell sx={{fontWeight:700, fontSize:"12pt"}}>AI-Generated Business Suggestions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(generatedSuggestions).map(key => {
                                                return (
                                                    <TableRow>
                                                        <TableCell sx={{fontWeight:700, fontSize:"12pt"}}>{key}</TableCell>
                                                        <TableCell sx={{fontWeight:400, fontSize:"12pt"}}>{generatedSuggestions[key]}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : (
                            <Box sx={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Typography fontStyle={"italic"}>Get suggestions to display here.</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            }
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

const UseModel = ({modelData}) => {
    const theme = useTheme();
    const authToken = localStorage.getItem("authToken");

    const [predictionResult, setPredictionResult] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const columnOptions = modelData["prediction_column_options"];
    const optionsToDict = {}
    Object.keys(columnOptions).map(category => {
        if (typeof columnOptions[category] == "object"){
            optionsToDict[category] = columnOptions[category][0];
        }
        else {
            optionsToDict[category] = columnOptions[category]
        }
    })

    const [selectedOptions, setSelectedOptions] = useState(optionsToDict);

    const handleChange = (event, category) => {
        const { value } = event.target;
        setSelectedOptions(prev => ({
            ...prev,
            [category]: value
        }));
    };

    const handlePredict = () => {
    
        const data = JSON.stringify({
            "X_input_data": [selectedOptions]
        });
    
        const url = model.predict(modelData["id"])
    
        const headers = {"Authorization": authToken, "Content-Type": "application/json"}
    
        postRequest(url, headers, data)
            .then(response => (response.json()))
            .then(data => {
                if(data && data[0] && data[0]["prediction"] != null) {
                    setPredictionResult(data);
                    handleSnackbarOpen("success", `Prediction made successfully`)
                }
                else if (data && data["error"]){
                    handleSnackbarOpen("error", data["error"])
                }
            })
    }

    return (
        <Box sx={{border:"1px solid gray", 
            borderRadius:"5px", 
            margin:"20px", 
            padding:"20px" }}>
            <Typography variant='h6'>Use {modelData["name"]}</Typography>
            <Typography variant='subtitle1' fontWeight={300}>Fill the fields below with the instance you want to make a prediction.</Typography>
            
            <Box sx={{display:"grid", gridTemplateColumns: "repeat(auto-fill, minmax(300px, 3fr))", gap:"20px", marginTop:"20px"}}>
                {Object.entries(columnOptions).map(([category, options], index) => {
                    if (typeof options == "object"){
                        return (
                            <FormControl sx={{width:"300px"}}>
                                <InputLabel>{category}</InputLabel>
                                <Select
                                    value={selectedOptions[category]}
                                    label={category}
                                    onChange={(event) => handleChange(event, category)}
                                    >
                                    {options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )
                    }
                    else if (typeof options == "number") {
                        return (
                            <FormControl sx={{width:"300px"}}>
                                <TextField
                                    required
                                    label={category}
                                    value={selectedOptions[category]}
                                    onChange={(event) => handleChange(event, category)}
                                    />
                            </FormControl>
                        )
                    }
                })}
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%", marginTop:"20px" }}>
                <Box sx={{display:"flex", alignItems:"center"}}>
                    {predictionResult ? (
                        <>
                            <Box sx={{display:"flex", 
                                flexDirection:"column", 
                                padding:"10px", 
                                backgroundColor:theme.palette.background.lighter,
                                border:"1px solid gray",
                                borderRadius:"5px"}}>
                                <Typography>Prediction Result</Typography>
                                <Typography variant='subtitle2' fontWeight={300} fontSize={"9pt"}>Prediction result of the instance you have submitted.</Typography>
                                <Box sx={{display:"flex", 
                                            flexDirection:"column", 
                                            backgroundColor:theme.palette.background.default, 
                                            borderRadius:"5px",
                                            marginTop:"10px",
                                            padding:"10px"}}>
                                    <Typography variant='subtitle1' 
                                            fontWeight={300} 
                                            fontSize={"11pt"} 
                                            sx={{marginTop:"10px", marginBottom:"10px"}}
                                            >Prediction: 
                                            <span style={{fontWeight:400, fontSize:"12pt"}}>
                                                {` ${modelData["target_feature"]} is ${predictionResult[0]["prediction"]}`}
                                            </span>
                                    </Typography>
                                    {predictionResult[0]["probability"] ? (
                                        <>
                                            <hr />
                                            <Typography variant='subtitle1' 
                                                    fontWeight={300} 
                                                    fontSize={"11pt"} 
                                                    sx={{marginTop:"10px", marginBottom:"10px"}}
                                                    >Probability:
                                                    <span style={{fontWeight:400, fontSize:"12pt"}}>
                                                        {` ${(predictionResult[0]["probability"]*100).toFixed(2)}%`}
                                                    </span>
                                            </Typography>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
                <Button onClick={handlePredict} variant='contained' sx={{
                    right: "50px",
                    height: "55px",
                    width: "150px"
                }}>
                    Predict
                </Button>
            </Box>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

const ModelOverview = () => {

    const authToken = localStorage.getItem('authToken');
    const location = useLocation();
    const model_id = location.state["model_id"];

    const [modelData, setModelData] = useState(null);
    const [trainModelModalOpen, setTrainModelModalOpen] = useState(false);

    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    }

    useEffect(() => {
        fetchModelOverview();
    }, [model_id]);

    const fetchModelOverview = () => {
        const url = model.getModelOverview(model_id);
        getRequest(url, {"Authorization": localStorage.getItem("authToken")})
            .then(data => {
                setModelData(data);
        })
    };

    const handleTryDifferentAlgorithms = () => {
        setTrainModelModalOpen(true);
    }

    const handleCloseTrainModelModal = () => {
        setTrainModelModalOpen(false);
    }

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"100%"}}>
        {
            modelData ? (
                <React.Fragment>
                    <Tabs value={currentTabIndex} onChange={handleTabChange} >
                        <Tab label='Overview' className='left' icon={<Analytics />}  iconPosition='start' />
                        <Tab label='Actionable Suggestions' className='left' icon={<Insights />}  iconPosition='start' />
                        <Tab label='Use Model' className='right' icon={<BatchPrediction />} iconPosition='start' />
                    </Tabs>
                
                    {/* Overview Tab */}
                    {currentTabIndex === 0 && (
                        <Overview modelData={modelData} handleTryDifferentAlgorithms={handleTryDifferentAlgorithms} />
                    )}
                    {/* Actionable Suggestions Tab */}
                    {currentTabIndex === 1 && (
                        <ActionableSuggestions modelData={modelData} />
                    )}
                    {/* Use Model Tab */}
                    {currentTabIndex === 2 && (
                        <UseModel modelData={modelData} />
                    )}
                    <ModelBuildModal open={trainModelModalOpen} handleClose={handleCloseTrainModelModal} modelDataParam={modelData} />
                </React.Fragment>
            ) : (
                <>

                </>
            )
        }
    </Box>
  )
}

export default ModelOverview