import { TaskAlt } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const PostSignup = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (location.state && location.state.isInAppNavigation) {
      setShowConfirmation(true);
    } else {
      navigate("/platform/");
    }
  }, [location.state, navigate]);


  if (showConfirmation) {
    return (
      <Box sx={{ 
        width: "100%", 
        height: "100%", 
        backgroundColor: theme.palette.background.lighterblue,
        display: "flex",
        flexDirection: "column",
        paddingTop: "15%",
        alignItems: "center"
      }}>
        <TaskAlt style={{ height: "75px", width: "75px", fill: "green" }} />
        <Typography
          variant='h5'
          width={"50%"}
          textAlign={"center"}
          mt={2}
        >
          Thank you for registering! To complete the activation of your account, please click the link that has been sent to your email address.
        </Typography>
        <a href="/">Back to Login</a>
      </Box>
    );
  }
  return null;
}

export default PostSignup