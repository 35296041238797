import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { putRequest } from '../../apis/requests'
import { user } from '../../apis/urls'

const PersonalInfo = ({userData}) => {
    const theme = useTheme();
    const authToken = localStorage.getItem("authToken");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");
    const [dialogOpen, setDialogOpen] = useState(false);

    const [newUserData, setNewUserData] = useState(userData);
    const [firstName, setFirstName] = useState(userData["first_name"]);
    const [lastName, setLastName] = useState(userData["last_name"]);
    const [email, setEmail] = useState(userData["email"]);
    const [phoneNumber, setPhoneNumber] = useState(userData["user_info"]["phone_number"]);
    const [company, setCompany] = useState(userData["user_info"]["company"]);
    const [title, setTitle] = useState(userData["user_info"]["title"]);

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const getUpdatedUserData = () => {
        const updatedUserData = {
            ...newUserData,
            first_name: firstName,
            last_name: lastName,
            user_info: {
                ...newUserData.user_info,
                phone_number: phoneNumber,
                company: company,
                title: title,
            },
        };
        setNewUserData(updatedUserData);
        return updatedUserData;
    }

    const handleSave = () => {
        const updatedUserData = getUpdatedUserData();

        const url = user.updateUserProfile();
        const headers = {
            "Authorization": authToken,
            "Content-Type": "application/json"
        };
        putRequest(url, headers, JSON.stringify(updatedUserData))
            .then(response => {
                if(response.ok){
                    handleSnackbarOpen("success", "Your profile has been successfully updated.");
                }
                else{
                    handleSnackbarOpen("error", "Error updating your profile.");
                }
            })
    }

    const SaveChangesDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to save?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  Are you sure want to save your personal information?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Yes</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (saveApproved) => {
        if(saveApproved){
            handleSave();
        }
        setDialogOpen(false);
      };

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"60%", padding:"50px 50px"}}>
        <Typography variant='h5'>Personal Info</Typography>
        <Box sx={{display:"flex", flexDirection:"column", marginTop:"50px", gap:"30px"}}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField sx={{width:"300px"}} value={firstName} label="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
                <TextField sx={{width:"300px"}} value={lastName} label="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField disabled sx={{width:"300px"}} value={email} label="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
                <PhoneInput
                    country={"tr"}
                    value={phoneNumber}
                    containerStyle={{ width:"300px"}}
                    inputStyle={{height:"100%"}}
                    onChange={setPhoneNumber}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField sx={{width:"300px"}} value={company} label="Company"
                    onChange={(e) => setCompany(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
                <TextField sx={{width:"300px"}} value={title} label="Title"
                    onChange={(e) => setTitle(e.target.value)} 
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            setDialogOpen(true);
                        }} />
            </Box>

            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"right"}}>
                <Button
                    sx={{backgroundColor:theme.palette.primary.main, marginTop:"20px", height:"50px", width:"150px"}}
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                >Save Changes</Button>
            </Box>
        </Box>
        <SaveChangesDialog open={dialogOpen} handleClose={handleDialogClose} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default PersonalInfo