import { useState } from "react";

import React from 'react'

const ConfigBuilder = () => {
  return (
    <div>ConfigBuilder</div>
  )
}

export default ConfigBuilder

export const buildConfig = (params) => {

    let modelData = null;

    //tabular data
    if(params["dataType"] == "tabular") {
        if(params["mode"] == "manual") {
            if(params["optimizationMetric"] != "log_loss"){
                modelData = {
                    dataset: params["selectedDataset"],
                    name: params["modelName"],
                    data_type: params["dataType"],
                    target_feature: params["columnToPredict"],
                    target_type: params["taskType"],
                    modeling_mode: params["mode"],
                    optimization_metric: params["optimizationMetric"],
                    feature_list: "all",
                    validation_type: params["validationType"],
                    cv_folds: params["foldCount"],
                    validation_size: params["validationSize"],
                    test_size: params["testSize"],
                    learning_algorithm: params["learningAlgorithm"],
                    layers: params["layers"],
                    config: {
                        jobs: [
                            {
                                name: "data_preprocessing",
                                steps: [
                                    {
                                        name: "handle_feature_selection",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "feature_list": "all",
                                        }
                                    },
                                    {
                                        name: "handle_missing_values",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "strategy": "drop"
                                        }
                                    },
                                    {
                                        name: "handle_feature_scaling",
                                        parameters: {
                                            target_column: params["columnToPredict"],
                                            strategy: "minmax"
                                        }
                                    },
                                    {
                                        name: "handle_one_hot_encoding",
                                        parameters: {
                                            target_column: params["columnToPredict"]
                                        }
                                    },
                                    {
                                        name: "handle_train_validation_test_split",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "validation_size": params["validationSize"],
                                            "test_size": params["testSize"]
                                        }
                                    }
                                ]
                            },
                            {
                                name: "model_building",
                                steps: [
                                    {
                                        name: "handle_model_selection",
                                        parameters: {
                                            learning_algorithm: params["learningAlgorithm"],
                                            hyperparameters: {}
                                        }
                                    },
                                    {
                                        name: "handle_scorer_selection",
                                        parameters: {
                                            optimization_metric: params["optimizationMetric"]
                                        }
                                    }
                                ]
                            },
                            {
                                name: "model_evaluation",
                                steps: [
                                    {
                                        name: "handle_training_and_validation",
                                        parameters: {
                                            validation_type: params["validationType"],
                                            param_grid: "null"
                                        }
                                    },
                                    {
                                        name: "handle_testing",
                                        parameters: {
                                            task_type: params["taskType"],
                                            target_column: params["columnToPredict"]
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                };
            }
            else {
                modelData = {
                    dataset: params["selectedDataset"],
                    name: params["modelName"],
                    data_type: params["dataType"],
                    target_feature: params["columnToPredict"],
                    target_type: params["taskType"],
                    modeling_mode: params["mode"],
                    optimization_metric: params["optimizationMetric"],
                    feature_list: "all",
                    validation_type: params["validationType"],
                    cv_folds: params["foldCount"],
                    validation_size: params["validationSize"],
                    test_size: params["testSize"],
                    learning_algorithm: params["learningAlgorithm"],
                    layers: params["layers"],
                    config: {
                        jobs: [
                            {
                                name: "data_preprocessing",
                                steps: [
                                    {
                                        name: "handle_feature_selection",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "feature_list": "all",
                                        }
                                    },
                                    {
                                        name: "handle_missing_values",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "strategy": "drop"
                                        }
                                    },
                                    {
                                        name: "handle_feature_scaling",
                                        parameters: {
                                            target_column: params["columnToPredict"],
                                            strategy: "minmax"
                                        }
                                    },
                                    {
                                        name: "handle_one_hot_encoding",
                                        parameters: {
                                            target_column: params["columnToPredict"]
                                        }
                                    },
                                    {
                                        name: "handle_binary_encoding",
                                        parameters: {
                                            target_column: params["columnToPredict"]
                                        }
                                    },
                                    {
                                        name: "handle_train_validation_test_split",
                                        parameters: {
                                            "target_column": params["columnToPredict"],
                                            "validation_size": params["validationSize"],
                                            "test_size": params["testSize"]
                                        }
                                    }
                                ]
                            },
                            {
                                name: "model_building",
                                steps: [
                                    {
                                        name: "handle_model_selection",
                                        parameters: {
                                            learning_algorithm: params["learningAlgorithm"],
                                            hyperparameters: {}
                                        }
                                    },
                                    {
                                        name: "handle_scorer_selection",
                                        parameters: {
                                            optimization_metric: params["optimizationMetric"]
                                        }
                                    }
                                ]
                            },
                            {
                                name: "model_evaluation",
                                steps: [
                                    {
                                        name: "handle_training_and_validation",
                                        parameters: {
                                            validation_type: params["validationType"],
                                            param_grid: "null"
                                        }
                                    },
                                    {
                                        name: "handle_testing",
                                        parameters: {
                                            task_type: params["taskType"],
                                            target_column: params["columnToPredict"]
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                };
            }
        } //auto mode
        else {
            modelData = {
                dataset: params["selectedDataset"],
                name: params["modelName"],
                data_type: params["dataType"],
                target_feature: params["columnToPredict"],
                target_type: params["taskType"],
                modeling_mode: params["mode"],
                optimization_metric: params["taskType"] == "classification" ? "accuracy": "rmse",
                feature_list: "all",
                validation_type: "cross_validation",
                cv_folds: 5,
                validation_size: params["validationSize"],
                test_size: 20,
                learning_algorithm: params["taskType"] == "classification" ? "random_forest_classifier": "random_forest_regressor",
                layers: params["layers"],
                config: {
                    jobs: [
                        {
                            name: "data_preprocessing",
                            steps: [
                                {
                                    name: "handle_feature_selection",
                                    parameters: {
                                        "target_column": params["columnToPredict"],
                                        "feature_list": "all",
                                    }
                                },
                                {
                                    name: "handle_missing_values",
                                    parameters: {
                                        "target_column": params["columnToPredict"],
                                        "strategy": "drop"
                                    }
                                },
                                {
                                    name: "handle_feature_scaling",
                                    parameters: {
                                        target_column: params["columnToPredict"],
                                        strategy: "minmax"
                                    }
                                },
                                {
                                    name: "handle_one_hot_encoding",
                                    parameters: {
                                        target_column: params["columnToPredict"]
                                    }
                                },
                                {
                                    name: "handle_train_validation_test_split",
                                    parameters: {
                                        "target_column": params["columnToPredict"],
                                        "validation_size": params["validationSize"],
                                        "test_size": 20
                                    }
                                }
                            ]
                        },
                        {
                            name: "model_building",
                            steps: [
                                {
                                    name: "handle_model_selection",
                                    parameters: {
                                        learning_algorithm: params["taskType"] == "classification" ? "random_forest_classifier": "random_forest_regressor",
                                        hyperparameters: {}
                                    }
                                },
                                {
                                    name: "handle_scorer_selection",
                                    parameters: {
                                        optimization_metric: params["taskType"] == "classification" ? "accuracy": "rmse"
                                    }
                                }
                            ]
                        },
                        {
                            name: "model_evaluation",
                            steps: [
                                {
                                    name: "handle_training_and_validation",
                                    parameters: {
                                        validation_type: "cross_validation",
                                        param_grid: "null"
                                    }
                                },
                                {
                                    name: "handle_testing",
                                    parameters: {
                                        task_type: params["taskType"],
                                        target_column: params["columnToPredict"]
                                    }
                                }
                            ]
                        }
                    ]
                }
            };
        }
    }
    else { //image data
        if(params["mode"] == "manual") {
            modelData = {
                image_dataset: params["selectedDataset"],
                name: params["modelName"],
                data_type: params["dataType"],
                target_type: params["taskType"],
                modeling_mode: params["mode"],
                optimization_metric: params["optimizationMetric"],
                feature_list: "all",
                validation_type: params["validationType"],
                cv_folds: params["foldCount"],
                validation_size: params["validationSize"],
                test_size: params["testSize"],
                learning_algorithm: params["learningAlgorithm"],
                config: {
                    jobs: [
                        {
                            name: "image_data_preprocessing",
                            steps: [
                                {
                                    name: "handle_making_directories",
                                    parameters: {}
                                },
                                {
                                    name: "handle_train_test_split",
                                    parameters: {}
                                },
                                {
                                    name: "handle_copying_images",
                                    parameters: {}
                                },
                                {
                                    name: "handle_dataset_config",
                                    parameters: {}
                                }
                            ],
                        },
                        {
                            name: "image_model_building",
                            steps: [
                                {
                                    name: "handle_loading_model",
                                    parameters: {
                                        "learning_algorithm": params["learningAlgorithm"]
                                    }
                                }
                            ]
                        },
                        {
                            name: "image_model_evaluation",
                            steps: [
                                {
                                    name: "handle_training_and_validation",
                                    parameters: {}
                                }
                            ]
                        }
                    ]
                }
            }
        } //auto mode
        else {
            modelData = {
                image_dataset: params["selectedDataset"],
                name: params["modelName"],
                data_type: params["dataType"],
                target_type: params["taskType"],
                modeling_mode: params["mode"],
                optimization_metric: "map",
                feature_list: "all",
                validation_type: "cross_validation",
                cv_folds: 5,
                validation_size: 20,
                test_size: 20,
                learning_algorithm: "yolov8",
                config: {
                    jobs: [
                        {
                            name: "image_data_preprocessing",
                            steps: [
                                {
                                    name: "handle_making_directories",
                                    parameters: {}
                                },
                                {
                                    name: "handle_train_test_split",
                                    parameters: {}
                                },
                                {
                                    name: "handle_copying_images",
                                    parameters: {}
                                },
                                {
                                    name: "handle_dataset_config",
                                    parameters: {}
                                }
                            ],
                        },
                        {
                            name: "image_model_building",
                            steps: [
                                {
                                    name: "handle_loading_model",
                                    parameters: {
                                        "learning_algorithm": "yolov8"
                                    }
                                }
                            ]
                        },
                        {
                            name: "image_model_evaluation",
                            steps: [
                                {
                                    name: "handle_training_and_validation",
                                    parameters: {}
                                }
                            ]
                        }
                    ]
                }
            }
        }
    }

    return modelData;
}