import styled from '@emotion/styled';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Snackbar, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { dataset, team } from "../apis/urls";
import { getRequest, deleteRequest, putRequest } from "../apis/requests";
import { formatDate } from "../utils";

import CircularProgress from '@mui/material/CircularProgress';
import Add from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import AddDatasetModal from '../components/dataset/AddDatasetModal';
import { useNavigate } from 'react-router-dom';
import { Delete, ManageSearch, MoreVert, ReadMore, Share } from '@mui/icons-material';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const Datasets = () => {

    const authToken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");
    
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [shareDatasetDialogOpen, setShareDatasetDialogOpen] = useState(false);

    const [gridData, setGridData] = useState(null);

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to delete this dataset?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you delete a dataset you will not be able to work with it anymore. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Delete</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = dataset.delete(selectedItem);
            deleteRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        fetchDatasets();
                      }
                      else {
                        handleSnackbarOpen("error", "Error deleting dataset.");
                      }
                });
        }
        setDialogOpen(false);
      };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 3 },
        { field: 'file_type', headerName: 'Type', flex: 1, renderCell: (params) => (
            <Chip label={params.row["file_type"]} sx={{backgroundColor: params.row["file_type"] == "csv" ? "limegreen" : null }} />
        ) },
        { field: 'rows', headerName: 'Rows', flex: 1 },
        { field: 'features', headerName: 'Features', flex: 1 },
        { field: 'size', headerName: 'Size', flex: 1, renderCell: (params) => (`${params.row["size"]} kB`) },
        { field: 'uploaded_by', headerName: 'Uploaded By', flex: 2 },
        { field: 'uploaded_at', headerName: 'Uploaded At', flex: 2, renderCell: (params) => (formatDate(params.row["uploaded_at"])) },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: "245",
            renderCell: (params) => {return (
                <Box>
                    <Tooltip title="Overview">
                        <IconButton aria-label='Overview' onClick={() => handleClickAction(params.row, "overview")}>
                            <ManageSearch sx={{color:"#ffbf00", width:"35px", height:"35px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Share">
                        <IconButton color='primary' aria-label='Share' onClick={() => handleClickAction(params.row, "share")}>
                            <Share sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color='error' aria-label='Delete' onClick={() => handleClickAction(params.row, "delete")}>
                            <Delete sx={{width:"30px", height:"30px"}} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        }
    ]

    const handleClickAction = (props, action_type) => {
        setSelectedItem(props.id);

        if (action_type === "delete") {
          setDialogOpen(true);
        }
        else if (action_type === "overview"){
            navigate('/platform/datasets/overview', { state: { dataset_id: props.id, dataset_name: props.name } });
        }
        else if (action_type === "share"){
          setShareDatasetDialogOpen(true);
        }
    }

    const ShareDatasetDialog = () => {

        const theme = useTheme();
        const [teamData, setTeamData] = useState([]);
        const [selectedTeam, setSelectedTeam] = useState(null);

        useEffect(() => {
            const url = team.getByUserId();
            getRequest(url, {"Authorization": authToken})
                .then(data => {
                    setTeamData(data);
                })
        }, [])
        
    
        return (
          <Dialog
            open={shareDatasetDialogOpen}
            onClose={() => handleShareDatasetDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Choose a team that you want to share the dataset with"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <Box>
                    {(teamData && teamData.length > 0) ? (
                        <FormControl fullWidth margin='normal'>
                            <InputLabel>Select team</InputLabel>
                            <Select
                                label={"Select team"}
                                value={selectedTeam}
                                sx={{width:"400px", padding:0 }}
                                onChange={(event) => {setSelectedTeam(event.target.value)}}
                                >
                                {teamData.map(team => {
                                    return (
                                        <MenuItem value={team}>{team.name}</MenuItem>
                                    )}
                                )}
                            </Select>
                        </FormControl>
                    ) : 
                    (
                        <Typography>You do not have a team</Typography>
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleShareDatasetDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleShareDatasetDialogClose(true, selectedTeam)}>Share</Button>
            </DialogActions>
          </Dialog>
        )
    };

    const handleShareDatasetDialogClose = (shareApproved, selectedTeam) => {
        if(shareApproved){
            if (selectedTeam) {
                const url = dataset.share(selectedItem);
                const headers = {
                    "Content-Type": "application/json",
                    "Authorization": authToken
                }
                const body = JSON.stringify({
                    "team_id": selectedTeam.id
                })

                putRequest(url, headers, body)
                    .then(response => {
                        if(response.ok){
                            handleSnackbarOpen("success", "Dataset has been shared with the team.");
                        }
                        return response.json();
                    })
                    .then(data => {
                        if(data["error"]){
                            handleSnackbarOpen("error", data["error"]);
                        }
                    });
            }         
            else {
                handleSnackbarOpen("error", "You must select a team for sharing.")
            }
        }
        setShareDatasetDialogOpen(false);
    };

    const [addDatesetModalOpen, setAddDatesetModalOpen] = useState(false);
    const handleOpenAddDatesetModal = () => setAddDatesetModalOpen(true);
    const handleCloseAddDatesetModal = () => {
      setAddDatesetModalOpen(false);
      fetchDatasets();
    }

    useEffect(() => {
        fetchDatasets();
    }, []);    

    const fetchDatasets = () => {
        const url = dataset.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>Tabular Datasets</Typography>
        <PageContainer>
            <Box sx={{ margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenAddDatesetModal}
                >Add New</Button>
            </Box>
            {gridData ? (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
                        isRowSelectable={() => false}
                        style={{fontWeight: 400, fontSize:"11pt", paddingLeft:"20px"}}
                    />
                </Box>
            ) : (
                <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
        </PageContainer>
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <ShareDatasetDialog open={shareDatasetDialogOpen} handleClose={handleShareDatasetDialogClose} />
        <AddDatasetModal open={addDatesetModalOpen} handleClose={handleCloseAddDatesetModal} onTriggerSnackbar={handleSnackbarOpen} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Datasets