import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert, Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { team } from '../apis/urls';
import { deleteRequest, getRequest, putRequest } from '../apis/requests';
import { Add, Code, CopyAll, Delete, ExitToApp, ManageAccounts, Notifications, TimeToLeave, Token } from '@mui/icons-material';
import { formatDate } from '../utils';
import CreateTeamModal from '../components/team/CreateTeamModal';
import JoinTeamModal from '../components/team/JoinTeamModal';
import JoinRequestsModal from '../components/team/JoinRequestsModal';
import ManageTeamModal from '../components/team/ManageTeamModal';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const Team = () => {

    const authToken = localStorage.getItem('authToken');
    const userId = localStorage.getItem("userId");

    const [gridData, setGridData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [teamJoinRequestData, setTeamJoinRequestData] = useState(null);
    const [teamJoinBadgeCount, setTeamJoinBadgeCount] = useState(0);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
    const handleOpenCreateTeamModal = () => setCreateTeamModalOpen(true);
    const handleCloseCreateTeamModal = () => setCreateTeamModalOpen(false);

    const [joinTeamModalOpen, setJoinTeamModalOpen] = useState(false);
    const handleOpenJoinTeamModal = () => setJoinTeamModalOpen(true);
    const handleCloseJoinTeamModal = () => setJoinTeamModalOpen(false);

    const [joinRequestsModalOpen, setJoinRequestsModalOpen] = useState(false);
    const handleOpenJoinRequestsModal = () => setJoinRequestsModalOpen(true);
    const handleCloseJoinRequestsModal = () => setJoinRequestsModalOpen(false);

    const [manageTeamModalOpen, setManageTeamModalOpen] = useState(false);
    const handleOpenManageTeamModal = (teamId) => {
        setSelectedItem(teamId);
        setManageTeamModalOpen(true);
    }
    const handleCloseManageTeamModal = () => setManageTeamModalOpen(false);

    const [teamCode, setTeamCode] = useState(null);
    const handleInviteCode = (teamCode) => {
        setTeamCode(teamCode);
        setInviteDialogOpen(true);
    }

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    const InviteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={inviteDialogOpen}
            onClose={() => setInviteDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5, display:"flex"}} id="alert-dialog-title">
                {`Team Code: ${teamCode}`} <CopyAll sx={{cursor:"pointer", marginLeft:"20px"}} onClick={() => {
                    navigator.clipboard.writeText(teamCode);
                    handleSnackbarOpen("success", "Team code copied.")
                    }
                } />
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  You can share this team code with your colleagues to make a join request to your team
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => setInviteDialogOpen(false)}>OK</Button>
            </DialogActions>
          </Dialog>
        )
      }  
    
    const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

    const LeaveDialog = () => {
  
          const theme = useTheme();
      
          return (
            <Dialog
              open={leaveDialogOpen}
              onClose={() => setLeaveDialogOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                  {`Leaving Team`}
              </DialogTitle>
              <DialogContent 
                sx={{backgroundColor:theme.palette.background.light}}>
                  <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                    When you leave from a team you will not be able to work with the shared items anymore. Be sure before perform this action.
                  </DialogContentText>
              </DialogContent>
              <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleLeaveDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleLeaveDialogClose(true)}>Delete</Button>
              </DialogActions>
            </Dialog>
          )
        }  
      
    const handleLeave = (teamId) => {
        setSelectedItem(teamId);
        setLeaveDialogOpen(true);
    }

    const handleLeaveDialogClose = (leaveApproved) => {
        if(leaveApproved){
            const url = team.leaveTeam(selectedItem);
            putRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "You have left from the team.")
                        fetchTeams();
                    }
                    else {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                });
        }
        setLeaveDialogOpen(false);
    }

    const handleDelete = (teamId) => {
        setSelectedItem(teamId);
        setDialogOpen(true);
    }

    const [dialogOpen, setDialogOpen] = useState(false);

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to delete this team?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you delete a team you will not be able to work with shared items anymore. All items (datasets, models, APIs etc.) will be used by their creators only. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Delete</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = team.deleteTeam(selectedItem);
            deleteRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "Team has been deleted")
                        fetchTeams();
                    }
                    else {
                        return response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                });
        }
        setDialogOpen(false);
    };

    useEffect(() => {
        fetchTeams();
        fetchTeamJoinRequests();
    }, []);    

    const fetchTeams = () => {
        const url = team.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const fetchTeamJoinRequests = () => {
        const url = team.getTeamJoinRequests();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setTeamJoinRequestData(data);
                setTeamJoinBadgeCount(data.length);
            })
    }

    const columns = [
        { field: 'name', headerName: 'Name', width: "200" },
        { field: 'members', headerName: 'Number of Members', width: "200", renderCell: (params) => params.row["members"].length },
        { field: 'created_at', headerName: 'Created At', width: "150", renderCell: (params) => (formatDate(params.row["created_at"])) },
        { field: "manage_team", headerName: "Actions", width: "450", renderCell: (params) => (
            <Box sx={{display:"flex", flexDirection:"row", gap:"10px", marginTop:"7px"}}>
                <Button
                    variant="outlined"
                    endIcon={<ManageAccounts />}
                    onClick={() => handleOpenManageTeamModal(params.row["id"])}>Manage Team</Button>
                <Button
                    variant="outlined"
                    endIcon={<Token />}
                    onClick={() => handleInviteCode(params.row["code"])}>Invite Code</Button>
                {params.row["owner"] != userId ? (
                    <Button
                        variant="outlined"
                        color='error'
                        endIcon={<ExitToApp />}
                        onClick={() => handleLeave(params.row["id"])}>Leave</Button>
                ) : (
                    <Button
                        variant="outlined"
                        color='error'
                        endIcon={<Delete />}
                        onClick={() => handleDelete(params.row["id"])}>Delete</Button>
                )}
            </Box>
        )}
    ];


  return (
    <Box sx={{ width: "100%" }}>
        <Typography variant='h5'>Team</Typography>
        <PageContainer>
            <Box sx={{display:"flex", flexDirection:"row", gap:"20px", margin: "0px 0px 20px 0px"}}>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenCreateTeamModal}
                >Create a Team</Button>
                <Button
                    variant="contained"
                    endIcon={<Add />}
                    onClick={handleOpenJoinTeamModal}
                >Join a Team</Button>
                
                <Badge badgeContent={teamJoinBadgeCount} color='error'>
                    <Button
                        variant="contained"
                        onClick={handleOpenJoinRequestsModal}
                    >Requests</Button>
                </Badge>
            </Box>
            {gridData ? (
                <Box sx={{ height: "500px" }}>
                    <DataGrid
                        rows={gridData}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        isRowSelectable={() => false}
                        style={{fontWeight: 400, fontSize:"11pt"}}
                    />
                </Box>
            ) : (
                <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            )}
        </PageContainer>
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <InviteDialog open={inviteDialogOpen} />
        <LeaveDialog open={leaveDialogOpen} handleClose={handleLeaveDialogClose} />
        <CreateTeamModal open={createTeamModalOpen} handleClose={handleCloseCreateTeamModal} />
        <JoinTeamModal open={joinTeamModalOpen} handleClose={handleCloseJoinTeamModal} />
        <JoinRequestsModal open={joinRequestsModalOpen} handleClose={handleCloseJoinRequestsModal} teamJoinRequestData={teamJoinRequestData} />
        {manageTeamModalOpen && <ManageTeamModal open={manageTeamModalOpen} handleClose={handleCloseManageTeamModal} teamId={selectedItem} />}
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Team