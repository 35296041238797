import { Add, Remove } from '@mui/icons-material';
import { Alert, Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { license, user } from '../../apis/urls';
import { deleteRequest, getRequest, postRequest } from '../../apis/requests';
import { DataGrid } from '@mui/x-data-grid';

const ShareLicenseModal = ({open, handleClose}) => {
    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [licenseShareUserEmail, setLicenseShareUserEmail] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleCloseLocally = () => {
        handleClose();
    }

    const handleShare = () => {
        if(licenseShareUserEmail && licenseShareUserEmail != ""){
            const url = license.invite();
            const headers = {
                "Content-Type": "application/json",
                "Authorization": authToken
            };
            const body = JSON.stringify({
                "license_share_email": licenseShareUserEmail
            });
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok){
                        handleSnackbarOpen("success", "License share invitation has been sent to the user successfully.")
                        handleCloseLocally();
                        return;
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]) {
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        else{
            handleSnackbarOpen("error", "Please provide an email.");
        }
    }

  return (
    <Box>
        <Modal
            open={open}
            onClose={handleCloseLocally}>
            <Box sx={{position: 'absolute',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"space-between",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "500px",
                    height: "250px",
                    bgcolor: theme.palette.text.light,
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,}}>
                <Box>
                    <Typography variant='h5'>Share License</Typography>
                    <TextField onChange={(e) => setLicenseShareUserEmail(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter")
                                handleShare();
                            }}
                        label="User Email" type='text' variant="outlined" sx={{
                            mt: 6,
                            width: "100%"
                    }} />
                </Box>

                <Button onClick={handleShare} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: "30%",
                    alignSelf: "flex-end"
                }}>
                    Invite
                </Button>
            </Box>
        </Modal>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

const LicenseManagement = ({userData}) => {

    const authToken = localStorage.getItem("authToken");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const [gridData, setGridData] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [licenseInvitationData, setLicenseInvitationData] = useState(null);
    const [licenseInvitationBadgeCount, setLicenseInvitationBadgeCount] = useState(0);

    const [shareLicenseModalOpen, setShareLicenseModalOpen] = useState(false);
    const handleOpenShareLicenseModal = () => setShareLicenseModalOpen(true);
    const handleCloseShareLicenseModal = () => setShareLicenseModalOpen(false);

    const [invitationsModalOpen, setInvitationsModalOpen] = useState(false);
    const handleOpenInvitationsModal = () => {
        if (licenseInvitationData){
            setInvitationsModalOpen(true);
        }
        else{
            handleSnackbarOpen("info", "There are no license invitations.")
        }
    }
    const handleCloseInvitationsModal = (invitationStatus) => {
        if (invitationStatus == "accept") {
            const url = license.acceptInvitation(licenseInvitationData["id"]);
            const headers = {
                "Authorization": authToken
            }
            postRequest(url, headers)
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", `You are now using ${licenseInvitationData["license_type"]}`)
                        fetchLicenseSharingInvitations();
                    }
                    else {
                        response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]) {
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        else if (invitationStatus == "reject") {
            const url = license.rejectInvitation(licenseInvitationData["id"]);
            const headers = {
                "Authorization": authToken
            }
            postRequest(url, headers)
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", `You rejected using ${licenseInvitationData["license_type"]}`)
                        fetchLicenseSharingInvitations();
                    }
                    else {
                        response.json();
                    }
                })
                .then(data => {
                    if (data && data["error"]) {
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }

        setInvitationsModalOpen(false);
    }

    const [removeMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
    const handleOpenRemoveMemberModal = () => setRemoveMemberModalOpen(true);
    const handleCloseRemoveMemberModal = (removeApproved) => {
        if(removeApproved) {
            const url = license.removeMember(selectedItem);
            const headers = {
                "Authorization": authToken
            }
            deleteRequest(url, headers)
                .then(response => {
                    if (response.ok){
                        handleSnackbarOpen("success", "The user has been removed from your license members.");
                        const url = user.getUser();
                        fetchLicenseSharings(userData);
                    }
                    else{
                        return response.json()
                    }
                })
                .then(data => {
                    if (data && data["error"]){
                        handleSnackbarOpen("error", data["error"])
                    }
                })
        }
        setRemoveMemberModalOpen(false);
    }
    const [quitLicenseModalOpen, setQuitLicenseModalOpen] = useState(false);
    const handleOpenQuitLicenseModal = () => setQuitLicenseModalOpen(true);
    const handleCloseQuitLicenseModal = (quitApproved) => {
        if (quitApproved){
            const url = license.quitLicense();
            const headers = {
                "Authorization": authToken
            }
            deleteRequest(url, headers)
                .then(response => {
                    if (response.ok){
                        handleSnackbarOpen("success", "You have been quit using this license.");
                        const url = user.getUser();
                        getRequest(url, {"Authorization": authToken})
                            .then(updatedUserData => {
                                fetchLicenseSharings(updatedUserData);
                            });
                    }
                    else{
                        return response.json()
                    }
                })
                .then(data => {
                    if (data && data["error"]){
                        handleSnackbarOpen("error", data["error"])
                    }
                })
        }
        setQuitLicenseModalOpen(false);  
    }  

    const [licenseKey, setLicenseKey] = useState(userData["user_license"]["license_key"]);
    const [licenseOrigin, setLicenseOrigin] = useState(userData["user_license"]["license_origin"]);
    const [originalLicenseData, setOriginalLicenseData] = useState(userData);

    useEffect(() => {
        fetchLicenseSharings(userData);
        fetchLicenseSharingInvitations();

        if(licenseKey != licenseOrigin){
            const url = license.getUserLicenseByLicenseKey(licenseOrigin)
            const headers = {
                "Authorization": authToken
            }
            getRequest(url, headers)
                .then(data => {
                    setOriginalLicenseData(data);
                })
            
        }
        else{
            setOriginalLicenseData(userData);
        }
    }, []);    

    const fetchLicenseSharings = (data) => {
        const url = license.getLicenseSharings(data["user_license"]["license_key"]);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setGridData(data);
            })
    };

    const fetchLicenseSharingInvitations = () => {
        const url = license.getLicenseSharingInvitations();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                if (data && data["id"]) {
                    setLicenseInvitationData(data);
                    setLicenseInvitationBadgeCount(1);
                }
                else {
                    setLicenseInvitationData(null);
                    setLicenseInvitationBadgeCount(0);
                }
            })
    };

    const InvitationModal = () => {
        const theme = useTheme();

        if (licenseInvitationData){
            return (
                <Dialog
                  open={invitationsModalOpen}
                  onClose={() => handleCloseInvitationsModal(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                      {"New license sharing invitation"}
                  </DialogTitle>
                  <DialogContent 
                    sx={{backgroundColor:theme.palette.background.light}}>
                      <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                        {`${licenseInvitationData["owner"]} has invited you to use their ${licenseInvitationData["license_type"]} license. Do you accept?`}
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                      <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleCloseInvitationsModal("reject")}>Reject</Button>
                      <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleCloseInvitationsModal("accept")}>Accept</Button>
                  </DialogActions>
                </Dialog>
              )
        }
    
        
    }

    const handleRemoveMember = (userId) => {
        setSelectedItem(userId)
        handleOpenRemoveMemberModal();
        
    }

    const RemoveMemberModal = () => {
        const theme = useTheme();
    
        return (
          <Dialog
            open={removeMemberModalOpen}
            onClose={() => handleCloseRemoveMemberModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to remove this member?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you remove a member from using your license, the member will not be able to utilize your license until you re-share. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleCloseRemoveMemberModal(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleCloseRemoveMemberModal(true)}>Remove</Button>
            </DialogActions>
          </Dialog>
        )
    }

    const QuitLicenseModal = () => {
        const theme = useTheme();
    
        return (
          <Dialog
            open={quitLicenseModalOpen}
            onClose={() => handleCloseQuitLicenseModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to quit this license?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you quit a license you will not be able to utilize it until you are re-invited. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleCloseQuitLicenseModal(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleCloseQuitLicenseModal(true)}>Quit</Button>
            </DialogActions>
          </Dialog>
        )
    }

    const columns = [
        { field: 'full_name', headerName: 'Full Name', width: "250" },
        { field: 'email', headerName: 'Email', width: "250" },
        { field: "actions", headerName: "Actions", width: "200", renderCell: (params) => {
            if ((params.row["user_id"] != localStorage.getItem("userId"))){
                return (
                    <Button
                        variant="outlined"
                        color='error'
                        endIcon={<Remove />}
                        onClick={() => handleRemoveMember(params.row["user_id"])}>Remove</Button>
                )
            }
            else{
                return (
                    <Typography sx={{fontStyle:"italic", fontSize:"11pt", lineHeight:"50px"}}>No action for this user</Typography>
                )
            }
        }
    }
    ];

    return (
        <Box sx={{display:"flex", flexDirection:"column", width:"90%", padding:"0px 50px"}}>
            <Typography variant='h5'>License Sharing</Typography>
            <Box sx={{display:"flex", flexDirection:"column", marginTop:"50px", gap:"10px"}}>
                <Box sx={{display:"flex", flexDirection:"row", gap:"20px", margin: "0px 0px 20px 0px"}}>
                    {licenseKey == licenseOrigin && !["TRIAL", "STARTER"].includes(userData["user_license"]["license_type"]) ? (
                        <Button
                            variant="contained"
                            endIcon={<Add />}
                            onClick={handleOpenShareLicenseModal}
                        >Share License</Button>
                    ) : (
                        <></>
                    )}
                    
                    <Badge badgeContent={licenseInvitationBadgeCount} color='error'>
                        <Button
                            variant="contained"
                            onClick={handleOpenInvitationsModal}
                        >Invitations</Button>
                    </Badge>
                </Box>
                {licenseKey == licenseOrigin && !["TRIAL", "STARTER"].includes(userData["user_license"]["license_type"]) ? (
                    gridData ? (
                        <Box sx={{ height: "500px" }}>
                            <DataGrid
                                rows={gridData}
                                columns={columns}
                                checkboxSelection
                                disableRowSelectionOnClick
                                isRowSelectable={() => false}
                                style={{fontWeight: 400, fontSize:"11pt"}}
                            />
                        </Box>
                    ) : (
                        <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>License Type</TableCell>
                                    <TableCell>License Owner</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {licenseKey != licenseOrigin && !["TRIAL", "STARTER"].includes(userData["user_license"]["license_type"]) ? (
                                        <>
                                            <TableCell>{userData["user_license"]["license_type"]}</TableCell>
                                            <TableCell>{originalLicenseData["first_name"]} {originalLicenseData["last_name"]}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleOpenQuitLicenseModal}
                                                >Quit License</Button>
                                            </TableCell>
                                        </>
                                    ) : (
                                        <>
                                            <TableCell>You don't have any shared license.</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </>
                                    )}
                                    
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
            <ShareLicenseModal open={shareLicenseModalOpen} handleClose={handleCloseShareLicenseModal} />
            <InvitationModal open={invitationsModalOpen} handleClose={handleCloseInvitationsModal} />
            <QuitLicenseModal open={quitLicenseModalOpen} handleClose={handleCloseQuitLicenseModal} />
            <RemoveMemberModal open={removeMemberModalOpen} handleClose={handleCloseRemoveMemberModal} />
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default LicenseManagement