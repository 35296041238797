import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { BASE_URL, imageDataset } from '../../apis/urls';
import { getRequest } from '../../apis/requests';
import { useSelector, useDispatch } from 'react-redux';
import { setImageData, addLabel, removeLabel, setDatasetLabel } from "../../store/imageDataSlice";

const ImageCanvas = ({ taskType }) => {
    const authToken = localStorage.getItem("authToken");
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.imageData);
    const labelOptions = useSelector((state) => state.labelOptions);

    const [isDrawing, setIsDrawing] = useState(false);
    const [isMovingStart, setIsMovingStart] = useState(false);
    const [isMovingEnd, setIsMovingEnd] = useState(false);
    const [movingRectangleIndex, setMovingRectangleIndex] = useState(null);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(800);
    const [canvasHeight, setCanvasHeight] = useState(800);
    const [selectedLabelIndex, setSelectedLabelIndex] = useState(0)
  
    const handleMouseDown = (e) => {
      if (labelOptions.labelOptions.length == 0) {
        return;
      }
      const rect = imageRef.current.getBoundingClientRect();

      let pointX = e.clientX - rect.left;
      let pointY = e.clientY - rect.top;
      let moving = false;

      //Clicked on existing point
      imageData.imageData.labels.forEach((rectangle, index) => {
        if (pointX > rectangle.startPoint.x - 3 &&
            pointX < rectangle.startPoint.x + 3 &&
            pointY > rectangle.startPoint.y - 3 &&
            pointY < rectangle.startPoint.y + 3)
        {
            setMovingRectangleIndex(index);
            setIsMovingStart(true);
            setIsDrawing(false);
            moving = true;
            return;
        }
        else if (pointX > rectangle.endPoint.x - 3 &&
            pointX < rectangle.endPoint.x + 3 &&
            pointY > rectangle.endPoint.y - 3 &&
            pointY < rectangle.endPoint.y + 3)
        {
            setMovingRectangleIndex(index);
            setIsMovingEnd(true);
            setIsDrawing(false);
            moving = true;
            return;
        }
      });
      if(!moving) {
        setStartPoint({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        setIsDrawing(true);
      }
    };
  
    const handleMouseUp = (e) => {
      const rect = imageRef.current.getBoundingClientRect();
      const currentPoint = { x: e.clientX - rect.left, y: e.clientY - rect.top };

      if (isDrawing){
        dispatch(addLabel({ startPoint, endPoint: currentPoint, label:labelOptions.labelOptions[selectedLabelIndex]["name"] }));
        drawAllRectangles(imageData.imageData.labels);
        setIsDrawing(false);
      }
      else if (isMovingStart) {
        const updatedLabels = imageData.imageData.labels.map((label, idx) => {
            if (idx === movingRectangleIndex) {
                return { ...label, startPoint: currentPoint };
            }
            return label;
        });
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
        drawAllRectangles(imageData.imageData.labels);
        setIsMovingStart(false);
        setMovingRectangleIndex(null);
      }
      else if (isMovingEnd) {
        const updatedLabels = imageData.imageData.labels.map((label, idx) => {
            if (idx === movingRectangleIndex) {
                return { ...label, endPoint: currentPoint };
            }
            return label;
        });
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
        drawAllRectangles(imageData.imageData.labels);
        setIsMovingStart(false);
        setMovingRectangleIndex(null);
      }
    };
  
    const handleMouseMove = (e) => {
      const rect = imageRef.current.getBoundingClientRect();
      let pointX = e.clientX - rect.left;
      let pointY = e.clientY - rect.top;

      const currentPoint = { x: pointX, y: pointY };

      const isOverCorner = imageData.imageData.labels.some(rectangle => 
        (pointX > rectangle.startPoint.x - 3 &&
            pointX < rectangle.startPoint.x + 3 &&
            pointY > rectangle.startPoint.y - 3 &&
            pointY < rectangle.startPoint.y + 3) || 
            (pointX > rectangle.endPoint.x - 3 &&
            pointX < rectangle.endPoint.x + 3 &&
            pointY > rectangle.endPoint.y - 3 &&
            pointY < rectangle.endPoint.y + 3)
      );
      if (isOverCorner) {
        imageRef.current.style.cursor = 'pointer';
      } else {
        imageRef.current.style.cursor = 'crosshair';
      }
      
      if (isDrawing){
        drawAllRectangles([...imageData.imageData.labels, { startPoint, endPoint: currentPoint }]);
      }
      else if (isMovingStart) {
        const updatedLabels = imageData.imageData.labels.map((label, idx) => {
            if (idx === movingRectangleIndex) {
                return { ...label, startPoint: currentPoint };
            }
            return label;
        });
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
        drawAllRectangles(imageData.imageData.labels);
      }
      else if (isMovingEnd) {
        const updatedLabels = imageData.imageData.labels.map((label, idx) => {
            if (idx === movingRectangleIndex) {
                return { ...label, endPoint: currentPoint };
            }
            return label;
        });
        dispatch(setImageData({...imageData.imageData, labels: updatedLabels}));
        drawAllRectangles(imageData.imageData.labels);
      }
    };
  
    const drawAllRectangles = (rects) => {
      if (!rects) {
        return;
      }
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      rects.forEach(({ startPoint, endPoint, label }) => {
        // Draw rectangle
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.rect(startPoint.x, startPoint.y, endPoint.x - startPoint.x, endPoint.y - startPoint.y);
        if (label) {
          ctx.strokeStyle = labelOptions.labelOptions.filter(labelOption => labelOption["name"] == label)[0]["color"]
        }
        else {
          ctx.strokeStyle = labelOptions.labelOptions[selectedLabelIndex]["color"]
        }
        ctx.stroke();
  
        // Draw top-left corner square
        drawCornerSquare(ctx, startPoint.x, startPoint.y, label);
  
        // Draw bottom-right corner square
        drawCornerSquare(ctx, endPoint.x, endPoint.y, label);
      });
    };
  
    const drawCornerSquare = (ctx, x, y, label) => {
      const size = 6;
      if (label) {
        ctx.fillStyle = labelOptions.labelOptions.filter(labelOption => labelOption["name"] == label)[0]["color"]
      }
      else{
        ctx.fillStyle = labelOptions.labelOptions[selectedLabelIndex]["color"]
      }
      ctx.fillRect(x - size / 2, y - size / 2, size, size);
    };
  
    useEffect(() => {
      if (taskType == "object_detection") {
        drawAllRectangles(imageData.imageData.labels);
      }
    }, [imageData.imageData.labels, imageData.imageData.url]);

    useEffect(() => {
      if (taskType == "object_detection") {
        if(!isDrawing)
        drawAllRectangles(imageData.imageData.labels);
      }
    });

    useEffect(() => {
      const actualAspectRatio = imageData.imageData.actualWidth / imageData.imageData.actualHeight;
      let canvasAspectRatio = 1;
      //image is wider than canvas
      if (actualAspectRatio > 1) {
        canvasAspectRatio = 800 / imageData.imageData.actualWidth;
      }
      else {
        canvasAspectRatio = 800 / imageData.imageData.actualHeight;
      }
      setCanvasWidth(canvasAspectRatio * imageData.imageData.actualWidth);
      setCanvasHeight(canvasAspectRatio * imageData.imageData.actualHeight);
      dispatch(setImageData({...imageData.imageData, aspectRatio: 1 / canvasAspectRatio}));
    }, [imageData.imageData.url]);
  
    const handleDragStart = (e) => {
      e.preventDefault();
    };
  
    return (
      <Box sx={{display:"flex", flexDirection:"row"}}>
        <Box
            sx={{
            position: 'relative',
            display: 'inline-block',
            height:"800px",
            width:"800px",
            alignItems:"center",
            justifyContent:"center"
            }}
        >
          {taskType == "object_detection" ? (
            <Box sx={{position:"relative", display:"flex", alignItems:"center", justifyContent:"center"}}>
              <img
                  src={`${BASE_URL}${imageData.imageData.url}`}
                  ref={imageRef}
                  onMouseDown={handleMouseDown}
                  onMouseUp={handleMouseUp}
                  onMouseMove={handleMouseMove}
                  onDragStart={handleDragStart}
                  style={{ display: 'block', width: canvasWidth, height: canvasHeight, maxHeight:"800px", objectFit:"contain", cursor: "crosshair" }}
                  />
              <canvas
                  ref={canvasRef}
                  width={canvasWidth}
                  height={canvasHeight}
                  style={{ position: 'absolute', pointerEvents: 'none', border:"1px solid black" }}
                  />
            </Box>
          ) : (
            <img
                src={`${BASE_URL}${imageData.imageData.url}`}
                style={{ display: 'block', width: '100%', height: 'auto', maxHeight:"800px", objectFit:"contain" }}
                />
          )}
            
        </Box>
        {taskType == "object_detection" || taskType == "image_segmentation" ? (
          <Box sx={{display:"flex", flexDirection:"column", overflowY:"scroll", width:"200px", margin:"20px 30px", padding:"10px", border:"1px solid #ccc" }}>
            <Typography>Choose a label:</Typography>
            {labelOptions.labelOptions.map((item, index) => (
              <Button
                key={index}
                onClick={() => setSelectedLabelIndex(index)}
                variant={selectedLabelIndex === index ? 'contained' : 'outlined'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:"space-between",
                  margin: '8px',
                  padding: '10px 20px',
                  border: selectedLabelIndex === index ? '2px solid' : '1px solid',
                  borderColor: selectedLabelIndex === index ? 'primary.main' : 'grey.400',
                  textTransform: 'none'
                }}
              >
                {item.name}
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: item.color,
                    marginLeft: '8px',
                  }}
                />
              </Button>
            ))}
          </Box>
        ) : (
          <Box sx={{display:"flex", flexDirection:"column", width:"400px", margin:"20px 30px", padding:"10px", border:"1px solid #ccc" }}>
            <Typography>Choose a label:</Typography>
            <FormControl fullWidth margin='normal'>
              <Select value={imageData.imageData.datasetLabel}
                      onChange={(e) => dispatch(setImageData({...imageData.imageData, datasetLabel: e.target.value}))}>
                {labelOptions.labelOptions.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.name}
                    sx={{
                      display: 'flex',
                      flexDirection:"row",
                      alignItems: 'center',
                      justifyContent:"space-between",
                      padding: '10px 20px'
                    }}
                  >
                    <Box sx={{display: 'flex',
                      width: "92%",
                      flexDirection:"row",
                      alignItems: 'center',
                      justifyContent:"space-between",
                      padding: '0px 20px'}}>
                      {item.name}
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: item.color,
                          marginLeft: '8px',
                        }}
                      />
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
          </Box>
        )}
      </Box>
    );
}

export default ImageCanvas