import styled from '@emotion/styled';
import { Add, ManageSearch } from '@mui/icons-material'
import { Alert, Box, Button, Chip, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../apis/requests';
import { api, dataset, imageDataset, model, ticket } from '../apis/urls';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../utils';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600, // Increase the width of the modal
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const categoriesDict = {
    "tabular_dataset": "Tabular Dataset",
    "image_dataset": "Image Dataset",
    "model": "Model",
    "api": "API"
}

const stagesDict = {
    "waiting": "Waiting",
    "in_progress": "In Progress",
    "completed": "Answered"
}

const Help = () => {

    const authToken = localStorage.getItem("authToken");

    const [selectedItem, setSelectedItem] = useState(null);
    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [selectedDescription, setSelectedDescription] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    
    const [imageDatasets, setImageDatasets] = useState([]);
    const [tabularDatasets, setTabularDatasets] = useState([]);
    const [models, setModels] = useState([]);
    const [apis, setApis] = useState([]);

    const [ticketsData, setTicketsData] = useState([]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        fetchTickets();
    }, [])

    const fetchImageDatasets = () => {
        const url = imageDataset.getByUserId();
        const headers = {
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                setImageDatasets(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchTabularDatasets = () => {
        const url = dataset.getByUserId();
        const headers = {
            "Content-Type": "application/json",
            "Authorization": authToken
        };

        getRequest(url, headers)
            .then(data => {
                setTabularDatasets(data);
            })
            .catch((error) => console.error("Error:", error));
    }

    const fetchModels = () => {
        const url = model.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setModels(data);
            })
    }

    const fetchApis = () => {
        const url = api.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setApis(data);
            })
    }

    const fetchTickets = () => {
        const url = ticket.getByUserId();
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                console.log(data);
                setTicketsData(data);
            })
    }

    const handleDisplayTicket = (item) => {
        setSelectedItem(item);
        setSelectedCategory(item["category"]);
        switch(item["category"]) {
            case "general":
                ;
            case "tabular_dataset":
                fetchTabularDatasets();
            case "image_dataset":
                fetchImageDatasets();
            case "model":
                fetchModels();
            case "api":
                fetchApis();
        }
        setSelectedObject(item["object_id"]);
        setSelectedTitle(item["title"]);
        setSelectedDescription(item["description"]);
        setSelectedStage(item["stage"]);

        setIsTicketModalOpen(true);
    }

    const handleTicketModalClose = () => {
        setSelectedItem(null);
        setSelectedCategory(null);
        setSelectedObject(null);
        setSelectedTitle(null);
        setSelectedDescription(null);
        setSelectedStage(null);

        setIsTicketModalOpen(false);
    }

    const handleSelectedCategoryChanged = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        
        switch(category) {
            case "general":
                ;
            case "tabular_dataset":
                fetchTabularDatasets();
            case "image_dataset":
                fetchImageDatasets();
            case "model":
                fetchModels();
            case "api":
                fetchApis();
        }
    }

    const handleSelectedObjectChanged = (e) => {
        const object = e.target.value;
        setSelectedObject(object);
    }

    const handleSave = () => {
        if (document.getElementById("selected-title-text-field").value 
            && selectedCategory 
            && (selectedObject == "general" || selectedCategory)) {

            const ticketData = {
                "id": selectedItem ? selectedItem["id"] : null,
                "title": document.getElementById("selected-title-text-field").value,
                "description": document.getElementById("selected-description-text-field").value,
                "category": selectedCategory,
                "object_id": selectedObject,
                "object_name": selectedCategory == "general" ? (null) : (
                    selectedCategory == "tabular_dataset" ? (tabularDatasets.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (selectedCategory == "image_dataset" ? (imageDatasets.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (selectedCategory == "model" ? (models.filter(item => item.id == selectedObject)[0]["name"]) : 
                    (apis.filter(item => item.id == selectedObject)[0]["name"])))
                ),
                "stage": selectedItem ? selectedStage : "waiting",                
            }
    
            const url = ticket.create();
            const headers = {
                "Authorization": authToken,
                "Content-Type": "application/json"
            }
            const body = JSON.stringify(ticketData);
    
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok) {
                        if(selectedItem) {
                            handleSnackbarOpen("success", "Ticket has been updated successfully.");
                        }
                        else {
                            handleSnackbarOpen("success", "New ticket has been created successfully.");
                        }
                        handleTicketModalClose();
                        fetchTickets();
                    }
                    else {
                        handleSnackbarOpen("error", "An error occurred.");
                    }
                })
        }
        else {
            handleSnackbarOpen("error", "Please fill all fields properly");
        }
    }

    const TicketModal = () => {
        return (
            <Modal
                open={isTicketModalOpen}
                onClose={handleTicketModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ticket
                    </Typography>

                    <Box sx={{display:"flex", flexDirection:"row", gap:"20px"}}>
                        <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Select category</InputLabel>
                                <Select
                                    value={selectedCategory}
                                    label="Select category"
                                    onChange={handleSelectedCategoryChanged}
                                    sx={{
                                        width: "100%",
                                    }}
                                    disabled={selectedItem}
                                >
                                    <MenuItem value={"general"}>General</MenuItem>
                                    <MenuItem value={"tabular_dataset"}>Tabular Dataset</MenuItem>
                                    <MenuItem value={"image_dataset"}>Image Dataset</MenuItem>
                                    <MenuItem value={"model"}>Model</MenuItem>
                                    <MenuItem value={"api"}>API</MenuItem>
                                </Select>
                            </FormControl>

                            {selectedCategory && selectedCategory != "general" ? (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select {categoriesDict[selectedCategory]}</InputLabel>
                                    <Select
                                        value={selectedObject}
                                        label={`Select ${categoriesDict[selectedCategory]}`}
                                        onChange={handleSelectedObjectChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                        disabled={selectedItem}
                                    >
                                        {selectedCategory == "tabular_dataset" ? (
                                            tabularDatasets.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (selectedCategory == "image_dataset") ? (
                                            imageDatasets.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (selectedCategory == "model" ? (
                                            models.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ) : (
                                            apis.map(item => {
                                                return (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl fullWidth margin="normal">
                                    <InputLabel>Select object</InputLabel>
                                    <Select
                                        value={selectedObject}
                                        label={`Select ${categoriesDict[selectedCategory]}`}
                                        onChange={handleSelectedObjectChanged}
                                        sx={{
                                            width: "100%",
                                        }}
                                        disabled
                                    >
                                        
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    </Box>

                    <FormControl fullWidth margin='normal'>
                        <TextField id='selected-title-text-field' label="Title" defaultValue={selectedTitle} disabled={selectedItem} />
                    </FormControl>

                    <FormControl fullWidth margin='normal'>
                        <TextField id='selected-description-text-field' label="Description" defaultValue={selectedDescription} multiline rows={3} disabled={selectedItem} />
                    </FormControl>

                    {selectedItem && selectedItem["answer"] ? (
                        <FormControl fullWidth margin='normal'>
                            <TextField id='selected-answer-text-field' label="Answer" defaultValue={selectedItem["answer"]} multiline rows={3} disabled={selectedItem} />
                        </FormControl>
                    ) : (
                        <></>
                    )}

                    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"flex-end", gap:"20px", marginTop:"10px"}}>
                        <Button variant="contained" color='error' onClick={handleTicketModalClose}>{selectedItem ? "Close" : "Cancel"}</Button>
                        <Button variant="contained" color='primary' onClick={handleSave} disabled={selectedItem}>Save</Button>
                    </Box>                    
                </Box>
            </Modal>
        )
    }

    const handleNewTicket = () => {
        setIsTicketModalOpen(true);
    }

    const columns = [
        { field: 'title', headerName: 'Title', flex: 3 },
        { field: 'category', headerName: 'Category', flex: 1, renderCell: (params) => categoriesDict[params.row["category"]] },
        { field: 'object_name', headerName: 'Object Name', flex: 1 },
        { field: 'created_at', headerName: 'Created At', flex: 1, renderCell: (params) => (formatDate(params.row["created_at"])) },
        { field: 'stage', headerName: 'Status', flex: 1, renderCell: (params) => (
            <Chip label={stagesDict[params.row["stage"]]} sx={{backgroundColor: params.row["stage"] == "completed" ? "limegreen" : params.row["stage"] == "in_progress" ? "gold" : null }} />
        )},
        { field: 'updated_at', headerName: 'Answered At', flex: 1, renderCell: (params) => (formatDate(params.row["updated_at"])) },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: "245",
            renderCell: (params) => {return (
                <Box>
                    <Tooltip title="Display">
                        <IconButton aria-label='Display' onClick={() => handleDisplayTicket(params.row)}>
                            <ManageSearch sx={{color:"#ffbf00", width:"35px", height:"35px"}} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        }
    ]

    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant='h5'>Support Tickets</Typography>
            <PageContainer>
                <Box sx={{ margin: "0px 0px 20px 0px"}}>
                    <Button
                        variant="contained"
                        endIcon={<Add />}
                        onClick={handleNewTicket}
                    >Create New Ticket</Button>
                </Box>
                {ticketsData ? (
                    <Box sx={{ height: "500px" }}>
                        <DataGrid
                            rows={ticketsData}
                            columns={columns}
                            checkboxSelection={false}
                            disableRowSelectionOnClick
                            isRowSelectable={() => false}
                            style={{fontWeight: 400, fontSize:"11pt", paddingLeft:"20px"}}
                        />
                    </Box>
                ) : (
                    <Box sx={{ width: "100%", height:"50vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress />
                    </Box>
                )}
                <TicketModal />
            </PageContainer>
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000}
                onClose={handleSnackbarClose}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    variant='filled'
                    sx={{width:"100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Help