import { useTheme } from '@emotion/react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, Snackbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { team } from '../../apis/urls';
import { deleteRequest, getRequest, putRequest } from '../../apis/requests';
import { DataGrid } from '@mui/x-data-grid';
import { Remove } from '@mui/icons-material';

const ManageTeamModal = ({open, handleClose, teamId}) => {
    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [teamData, setTeamData] = useState(null);
    const [gridData, setGridData] = useState(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleCloseLocally = () => {
        handleClose();
    }

    const handleRemoveMember = (userId) => {
        setSelectedItem(userId);
        setDialogOpen(true);
    }

    const DeleteDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to remove this user?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  When you remove a user you will not be able to share datasets or models with the user anymore. Be sure before perform this action.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Remove</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (deleteApproved) => {
        if(deleteApproved){
            const url = team.removeTeam(teamData["id"], selectedItem);
            putRequest(url, {"Authorization": authToken})
                .then(response => {
                    if (response.ok) {
                        handleSnackbarOpen("success", "User has been removed from your team.");
                    }
                    return response.json();
                })
                .then(data => {
                    if (data["error"]){
                        handleSnackbarOpen("error", data["error"]);
                    }
                    else{
                        setTeamData(data);
                        setGridData(data["members"]);
                    }
                });
        }
        setDialogOpen(false);
    };

    const columns = [
        { field: 'full_name', headerName: 'Full Name', width: "250", renderCell: (params) => `${params.row["first_name"]} ${params.row["last_name"]}`},
        { field: 'email', headerName: 'Email', width: "300", renderCell: (params) => params.row["email"] },
        { field: "actions", headerName: "Actions", width: "200", renderCell: (params) => {
                if ((params.row["id"] != localStorage.getItem("userId")) && (teamData["owner"] == localStorage.getItem("userId"))){
                    return (
                        <Button
                            variant="outlined"
                            color='error'
                            endIcon={<Remove />}
                            onClick={() => handleRemoveMember(params.row["id"])}>Remove</Button>
                    )
                }
                else{
                    return (
                        <Typography sx={{fontStyle:"italic", fontSize:"11pt", lineHeight:"50px"}}>No action for this user</Typography>
                    )
                }
            }
        }
    ];


    useEffect(() => {
        const url = team.getByTeamId(teamId);
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                setTeamData(data);
                setGridData(data["members"]);
            })
    }, []);

  return (
    <Box>
        <Modal
            open={open}
            onClose={handleCloseLocally}>
            {teamData ? (
                <Box sx={{position: 'absolute',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent:"space-between",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "900px",
                        height: "500px",
                        bgcolor: theme.palette.text.light,
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,}}>
                    <Box>
                        <Typography variant='h5'>Manage Team</Typography>
                        <hr />
                        <Box sx={{marginTop:"30px", marginBottom:"20px"}}>
                            <Typography variant='h7'>{teamData.name} Members</Typography>
                        </Box>
                        <Box sx={{ height: "400px" }}>
                            <DataGrid
                                rows={gridData}
                                columns={columns}
                                checkboxSelection
                                disableRowSelectionOnClick
                                isRowSelectable={() => false}
                                style={{fontWeight: 400, fontSize:"11pt"}}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                    <CircularProgress />
                </Box>
            )}
        </Modal>
        <DeleteDialog open={dialogOpen} handleClose={handleDialogClose} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ManageTeamModal