import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import faqImage from "../../assets/images/faq.svg";

const faqs = [
  {
    question: "What industries do your data scientists specialize in?",
    answer: "Our data scientists have experience working with a variety of industries, including healthcare, finance, retail, manufacturing and more. While they bring robust technical expertise, they collaborate closely with your domain experts to understand and address industry-specific challenges and opportunities. This partnership ensures that the solutions we develop are highly tailored and effective for your specific needs."
  },
  {
    question: "How does the dedicated data scientist service work?",
    answer: "Once you sign up, we will match you with an MSc or PhD data scientist who will integrate with your team. They will work closely with you to understand your business objectives and develop a customized data strategy. This process includes regular updates, collaborative planning, and hands-on execution to ensure the solutions align with your needs."
  },
  {
    question: "What is the cost of the service?",
    answer: "The base cost of our dedicated data scientist service is $495 per month that includes up to 10 hours of dedicated data scientist work. This covers initial consultations, data analysis, model development, regular progress updates, and basic support. Additional hours and specialized tasks can be arranged for an extra fee. For more specific needs or larger projects, we offer customized pricing plans. Contact us to discuss your requirements and receive a personalized quote."
  },
  {
    question: "Can I adjust or cancel the service?",
    answer: "Yes, our service is designed to be flexible. You can adjust the scope or duration as your business needs change. We also provide easy cancellation options without long-term commitments."
  }
];

const FAQ = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: {xs:"100px", md:"200px"},
        paddingBottom: "150px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: { xs: "90%", md: "60%" },
          alignItems: "center",
          gap: "20px",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h3"
            fontWeight={400}
            sx={{ fontSize: { xs: "7vw", md: "3vw" } }}
          >
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="h5"
            fontWeight={300}
            color="textSecondary"
            marginTop="20px"
            sx={{ fontSize: { xs: "5vw", md: "1.5vw" } }}
          >
            Find answers to some of the most common questions about our dedicated data scientist service.
          </Typography>
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <img
            src={faqImage}
            alt="FAQ"
            style={{
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              marginTop: { xs: "-20px", md: "-100px" },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", md: "60%" },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: "20px",
        }}
      >
        {faqs.map((faq, index) => (
          <Accordion key={index} sx={{ mb: 2, width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body1' fontWeight={300}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default FAQ;
