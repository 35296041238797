import React, { useEffect } from 'react'
import HomeNavbar from '../components/home/HomeNavbar'
import Welcome from '../components/home/Welcome'
import Testimonials from '../components/home/Testimonials'
import { Box } from '@mui/material'
import Footer from '../components/home/Footer'
import WhyUtimind from '../components/home/WhyUtimind'
import Product from '../components/home/Product'
import AllInOneSection from '../components/home/AllInOneSection'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Box>
        <HomeNavbar />
        <Welcome />
        <WhyUtimind />
        <AllInOneSection />
        <Product />
        <Testimonials />
        <Footer />
    </Box>
  )
}

export default Home