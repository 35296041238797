import React, { useState } from 'react';
import {
  AppBar, Box, Button, Grid, Toolbar, Typography, Container, Paper, IconButton, Drawer, List, ListItem, ListItemText, Chip, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import utienceLogo from "../../assets/images/utience_logo_secondary.svg";
import utienceLogoReverse from "../../assets/images/utience_logo_primary.svg";
import { Close, ExpandMore, KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const HomeNavbar = () => {

  const navigate = useNavigate();

  const [isDataAnalystsHovered, setIsDataAnalystsHovered] = useState(false);
  const [isPerformanceAgenciesHovered, setIsPerformanceAgenciesHovered] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMouseEnter = (e) => {
    if(e.target.classList.contains("use-cases-area")){
      setIsPerformanceAgenciesHovered(false);
      setIsDataAnalystsHovered(true);
    }
    else if (e.target.classList.contains("resources-area")) {
      setIsDataAnalystsHovered(false);
      setIsPerformanceAgenciesHovered(true);
    }
    
  };

  const handleMouseLeave = () => {
    setIsDataAnalystsHovered(false);
    setIsPerformanceAgenciesHovered(false);
  };

  const handleDrawerToggle = (e) => {
    if (e.target.closest('.accordion')) {
      return; // Do nothing if it's an Accordion click
    }
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", height:"95%", textAlign: 'center' }}>
      <Box>
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", paddingRight:"5%", justifyContent:"space-between", borderBottom:"1px solid #dddddd"}}>
          <img style={{ display: { xs: 'none', sm: 'block' }, height:"80px", width:"auto", marginTop:"10px", marginLeft:"3%", cursor:"pointer" }} src={utienceLogoReverse} onClick={() => navigate("/")} />
          <Close sx={{marginTop:"5px"}} />
        </Box>
        <List>
          <ListItem button onClick={() => navigate("/")}>
            <ListItemText primary="Product" sx={{ '& .MuiTypography-root': { fontSize: '14pt' }, borderBottom: "1px solid #dddddd" }} />
          </ListItem>
          <ListItem button onClick={() => navigate("/service")}>
            <ListItemText primary="Service" sx={{ '& .MuiTypography-root': { fontSize: '14pt' }, borderBottom: "1px solid #dddddd" }} />
          </ListItem>
          <ListItem button onClick={() => navigate("/pricing")}>
            <ListItemText primary="Pricing" sx={{ '& .MuiTypography-root': { fontSize: '14pt' }, borderBottom: "1px solid #dddddd" }} />
          </ListItem>
          <ListItem button sx={{ padding: 0 }}>
            <Accordion className="accordion" disableGutters elevation={0} square sx={{ width: "91%", marginLeft:"4.5%", '&:before': { display: 'none' }, boxShadow: 'none', borderBottom: "1px solid #dddddd" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordion"
                sx={{
                  padding: '0 0px', // Match ListItem padding
                  '& .MuiAccordionSummary-content': {
                    margin: 0, // Remove default margins
                  }
                }}
              >
                <Typography className="accordion" sx={{ fontSize: '14pt' }}>Use Cases</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion" sx={{ padding: '0' }}>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "manufacturing"}})}>
                  Manufacturing
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "qualityControl"}})}>
                  Quality Control
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "customerChurn"}})}>
                  Customer Churn
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "inventoryManagement"}})}>
                  Inventory Management
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "finance"}})}>
                  Finance
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "salesPrediction"}})}>
                  Sales Prediction
                </Typography>
                <Typography className="accordion" variant='body1' fontWeight={300} marginTop={"5px"} borderBottom={"1px solid #dddddd"} onClick={() => navigate("/use_case", {state: {caseField: "agriculture"}})}>
                  Agriculture
                </Typography>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Button variant="outlined" sx={{color:"#4e309c", borderColor:"#4e309c", width:"95%", height:"50px", fontSize:"12pt"}} onClick={() => navigate("/platform")}>Go To Platform</Button>
        <Button variant="contained" sx={{backgroundColor:"#4e309c", width:"95%", height:"50px", marginTop:"10px", fontSize:"12pt"}} onClick={() => navigate("/pricing")}>Get Started</Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: '#2e1866', height:"80px", justifyContent:"center" }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', marginLeft:{xs:"0%", sm:"0%", md:"10%"} }}>
          <img style={{ display: { xs: 'none', sm: 'block' }, height:"80px", width:"auto", cursor:"pointer" }} src={utienceLogo} onClick={() => navigate("/")} />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ display: { xs: 'none', sm: 'flex' }, 
            alignSelf:"stretch", 
            alignItems:"center",
            gap:"40px" }}>
            <Box
              sx={{
                alignSelf:"stretch",
                alignItems:"center",
                display:"flex",
                padding:0, 
                gap:"60px" }}>
              <Typography variant="button" sx={{cursor:"pointer", fontWeight:"400", fontSize:"14pt", textTransform:"none"}} onClick={() => navigate("/")}>Product</Typography>
              <Typography variant="button" sx={{cursor:"pointer", fontWeight:"400", fontSize:"14pt", textTransform:"none"}} onClick={() => navigate("/service")}>Service</Typography>
            </Box>
            <Box
              onMouseLeave={handleMouseLeave} 
              sx={{
                alignSelf:"stretch",
                alignItems:"center",
                display:"flex",
                padding:0, 
                gap:"15px" }}>
              <Box
                  className="use-cases-area"
                  onMouseEnter={handleMouseEnter}
                  sx={{ position: 'relative', padding:"0 20px 0 20px", display:"flex", alignItems:"center", cursor:"pointer"}}
                >
                <Typography
                  className="use-cases-area"
                  sx={{fontWeight:"400", fontSize:"14pt", textTransform:"none"}}>
                  Use Cases
                </Typography>
                <KeyboardArrowDown />
              </Box>
                {isDataAnalystsHovered && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: '100%',
                      left: '-12%',
                      boxSizing:"border-box",
                      width: '112%',
                      backgroundColor: 'white',
                      color: 'black',
                      padding: "2vw 20vw 5vw 20vw",
                      boxShadow: 1,
                      zIndex: 2
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "manufacturing"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Manufacturing</Typography>
                          <Chip label={"Tabular Data"} sx={{backgroundColor:"lavender", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Predict machine failures to reduce downtime and maintenance costs using historical data.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "qualityControl"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Quality Control</Typography>
                          <Chip label={"Image Data"} sx={{backgroundColor:"#ffe6ee", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Identify and classify defects in metal castings to improve product quality using image analysis.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "customerChurn"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Customer Churn</Typography>
                          <Chip label={"Tabular Data"} sx={{backgroundColor:"lavender", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Predict which customers are likely to leave the service to improve retention strategies using customer data.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "inventoryManagement"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Inventory Management</Typography>
                          <Chip label={"Image Data"} sx={{backgroundColor:"#ffe6ee", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Improve inventory management by detecting out-of-stock items and incorrect product placements on retail shelves.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "finance"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Finance</Typography>
                          <Chip label={"Tabular Data"} sx={{backgroundColor:"lavender", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Detect and prevent fraudulent transactions in real-time using machine learning models.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "salesPrediction"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Sales Prediction</Typography>
                          <Chip label={"Tabular Data"} sx={{backgroundColor:"lavender", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Accurately forecast future sales to optimize inventory, manage cash flow, and plan marketing campaigns effectively.</Typography>
                      </Grid>

                      <Grid onClick={() => navigate("/use_case", {state: {caseField: "agriculture"}})} item xs={12} sm={6} md={4} sx={{cursor:"pointer", padding:2, borderRadius:"5px", ":hover":{backgroundColor:"#eeeeee"}}}>
                        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginRight:"20px"}}>
                          <Typography variant="subtitle1" fontWeight={900}>Agriculture</Typography>
                          <Chip label={"Image Data"} sx={{backgroundColor:"#ffe6ee", height:"20px"}} />
                        </Box>
                        <Typography variant="body2">Protect crops by detecting pests early using drone or ground-level imagery and object detection models.</Typography>
                      </Grid>

                    </Grid>
                  </Box>
                )}
            </Box>
            <Box
              sx={{
                alignSelf:"stretch",
                alignItems:"center",
                display:"flex",
                padding:0, 
                gap:"45px" }}>
              <Typography variant="button" sx={{cursor:"pointer", fontWeight:"400", fontSize:"14pt", textTransform:"none"}} onClick={() => navigate("/pricing")}>Pricing</Typography>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: "20px", marginRight:"10%" }}>
            <Button variant="text" sx={{color:"white", fontSize:"12pt", ":hover":{backgroundColor:"#4e309c"}}} onClick={() => navigate("/platform")}>Go To Platform</Button>
            <Button variant="outlined" color="inherit" sx={{fontSize:"12pt", ":hover":{backgroundColor:"#4e309c"}}} onClick={() => navigate("/pricing")}>Get Started</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: "block",
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default HomeNavbar;
