import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { putRequest } from '../../apis/requests'
import { user } from '../../apis/urls'

const ChangePassword = () => {
    const theme = useTheme();
    const authToken = localStorage.getItem("authToken");

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");
    const [dialogOpen, setDialogOpen] = useState(false);

    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleConfirmation = () => {
        if (newPassword && newPassword != "" && newPassword === confirmNewPassword) {
            setDialogOpen(true);
        }
        else {
            handleSnackbarOpen("error", "Passwords does not match.");
        }
    }

    const handleSave = () => {
        const data = {
            "current_password": currentPassword,
            "new_password": newPassword,
        }

        const url = user.updateUserPassword();
        const headers = {
            "Authorization": authToken,
            "Content-Type": "application/json"
        };
        putRequest(url, headers, JSON.stringify(data))
            .then(response => {
                if(response.ok){
                    handleSnackbarOpen("success", "Your password has been successfully updated.");
                }
                else{
                    return response.json();
                }
            })
            .then(data => {
                if (data) {
                    handleSnackbarOpen("error", data["error"]);
                }
            })
    }

    const SaveChangesDialog = () => {

        const theme = useTheme();
    
        return (
          <Dialog
            open={dialogOpen}
            onClose={() => handleDialogClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{fontSize:"12pt", fontWeight:500, backgroundColor:theme.palette.background.light, pt:5}} id="alert-dialog-title">
                {"Are you sure want to save?"}
            </DialogTitle>
            <DialogContent 
              sx={{backgroundColor:theme.palette.background.light}}>
                <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
                  Are you sure want to save your personal information?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{backgroundColor:theme.palette.background.light, p:3}}>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleDialogClose(false)}>Cancel</Button>
                <Button variant="contained" sx={{color:theme.palette.text.light, fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleDialogClose(true)}>Yes</Button>
            </DialogActions>
          </Dialog>
        )
      }

    const handleDialogClose = (saveApproved) => {
        if(saveApproved){
            handleSave();
        }
        setDialogOpen(false);
      };

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"60%", padding:"50px 50px"}}>
        <Typography variant='h5'>Change Password</Typography>
        <Box sx={{display:"flex", flexDirection:"column", marginTop:"50px", gap:"30px"}}>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField sx={{width:"300px"}} type="password" label="Current Password"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            handleConfirmation();
                        }} />
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField sx={{width:"300px"}} type="password" label="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            handleConfirmation();
                        }} />
            </Box>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <TextField sx={{width:"300px"}} type="password" label="Confirm New Password" 
                    onChange={(e) => setConfirmNewPassword(e.target.value)} 
                    onKeyDown={(e) => {
                        if (e.key === "Enter")
                            handleConfirmation();
                        }} />
            </Box>

            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"right"}}>
                <Button
                    sx={{backgroundColor:theme.palette.primary.main, marginTop:"20px", height:"50px", width:"150px"}}
                    variant="contained"
                    onClick={handleConfirmation}
                >Save Changes</Button>
            </Box>
        </Box>
        <SaveChangesDialog open={dialogOpen} handleClose={handleDialogClose} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ChangePassword