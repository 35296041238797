import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';

const ProblemStatement = ({ problemsData }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        paddingX: { xs: '5%', md: '15%' }, // Responsive padding
        paddingY: { xs: '100px', md: '100px' }, // Adjust padding for different screen sizes
        backgroundColor: theme.palette.background.lighter,
      }}
    >
      <Typography variant='h3' fontSize={{xs:"24pt", md:"36pt"}}>Key Challenges</Typography>
      <Typography
        variant='h5'
        fontWeight={300}
        width={{ xs: '100%', md: '60%' }}
        marginTop={{ xs: '10px', md: '20px' }} // Responsive margin
        fontSize={{xs:"16pt", md:"18pt"}}
      >
        Businesses encounter significant obstacles in managing data, building models, and deploying AI solutions effectively.
      </Typography>

      <Grid container spacing={4} marginTop={{ xs: '20px', md: '30px' }}>
        {problemsData.map((problem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                padding: { xs: '20px', md: '30px 40px' }, // Responsive padding
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ height: '50px', width: '50px', marginBottom: '0px' }}>
                <img src={problem.icon} alt={problem.title} width={'100%'} height={'auto'} />
              </Box>
              <Typography
                variant='h6'
                fontSize={{ xs: '14pt', md: '16pt' }} // Responsive font size
                sx={{ marginTop: '10px', marginBottom: '20px' }}
              >
                {index + 1}. {problem.title}
              </Typography>
              <Typography variant='body1' fontWeight={300} fontSize={{ xs: '13pt', md: '15pt' }}>
                {problem.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProblemStatement;
