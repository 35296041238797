import { createTheme } from "@mui/material";

export const theme = createTheme({
    typography: {
        fontFamily: '"Reddit Sans Condensed", sans-serif',
    },
    palette: {
        primary: {
            main: "#1760a5",
            light: "#1760a5",
        },
        secondary: {
            main: "#15c630",
            light: "#999"
        },
        background: {
            lighter: "#f0f0f2",
            light: "#deded9",
            blue: "#007FFF",
            lighterblue: "#50a5fa",
            dark: "#343538"
        },
        text: {
            dark: "#333333",
            light: "#faf7f7"
        },
        warning: {
            main: "#D75A4A"
        },
        attention: {
            main: "#D7B740"
        }
    }
})