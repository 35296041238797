import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import serviceIntroImage from "../../assets/images/service_intro.svg";
import { useNavigate } from 'react-router-dom';

const ServiceIntro = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: {xs:"100%", md:"100%"},
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "60%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: { xs: "50px", md: "100px" }
        }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          width={{ xs: "100%", md: "80%" }}
          fontWeight={400}
          fontSize={{ xs: "7vw", md: "3.5vw" }} // Responsive font size
        >
          Empower Your Business with Our Dedicated Data Scientists
        </Typography>
        <Typography
          variant="h5"
          textAlign="center"
          width={{ xs: "100%", md: "80%" }}
          fontWeight={300}
          marginTop={{ xs: "20px", md: "40px" }}
          fontSize={{ xs: "6vw", md: "1.5vw" }} // Responsive font size
        >
          Our experts help you leverage advanced analytics, machine learning, and AI to drive informed decisions, optimize operations, and fuel innovation.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" }, // Stack buttons vertically on small screens
            justifyContent: "center",
            gap: "20px",
            marginTop: "50px",
            zIndex: 1,
            width: "100%", // Ensure buttons take full width on smaller screens
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#2e1866',
              height: "50px",
              width: { xs: "100%", sm: "200px" }, // Full width on small screens
              ":hover": { backgroundColor: "#7956d1" },
            }}
            onClick={() => navigate("/pricing")}
          >
            Get Started
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#2e1866",
              color: "#2e1866",
              height: "50px",
              width: { xs: "100%", sm: "200px" }, // Full width on small screens
              ":hover": { borderColor: "#7956d1", color: "#7956d1" },
            }}
            onClick={() => navigate("/request_demo")}
          >
            Learn More
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%", marginTop: { xs: "-5%", md: "-15%" }, position: 'relative', overflow: 'hidden' }}>
        <img
          src={serviceIntroImage}
          style={{
            width: "100%",
            height: "auto",
            position: 'relative',
          }}
          alt="Service Introduction"
        />
      </Box>
    </Box>
  );
};

export default ServiceIntro;
