import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import utienceLogo from "../assets/images/utience_logo_primary.svg"
import HomeNavbar from '../components/home/HomeNavbar'
import { demoRequest } from '../apis/urls'
import { postRequest } from '../apis/requests'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/home/Footer'

const Demo = () => {

  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [organizationSize, setOrganizationSize] = useState(null);
  const [role, setRole] = useState(null);
  const [caseDescription, setCaseDescription] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("Message");

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
  }

  const handleRequest = () => {
    if(email && email != ""
        && firstName && firstName != ""
        && lastName && lastName != ""
        && organizationName && organizationName != ""
        && organizationSize && organizationSize != ""
        && role && role != ""
        && caseDescription && caseDescription != "") {
      if (validateEmail(email)) {

        const headers = {
            'Content-Type': 'application/json'
        }

        const body = JSON.stringify({
            email: email,
            first_name: firstName,
            last_name: lastName,
            organization_name: organizationName,
            organization_size: organizationSize,
            role: role,
            case_description: caseDescription,
        });

        const url = demoRequest.create();
        postRequest(url, headers, body)
            .then(response => {
                if(response.ok){
                  resetFields();
                  handleSnackbarOpen("success", "Your demo request has been received. You will be contacted shortly to schedule your demo.");
                  setTimeout(
                      () => navigate("/"), 
                      3000
                  );
                }
                else{
                  handleSnackbarOpen("error", "Error receiving demo request. You can directly email to contact@utience.com");
                }
            })
      }
    }
    else {
      handleSnackbarOpen("error", "Please fill all fields.");
    }

  }

  const resetFields = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setOrganizationName("");
    setOrganizationSize(null);
    setRole("");
    setCaseDescription("");
  }

  const genericDomains = [
      "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", 
      "live.com", "aol.com", "icloud.com", "mail.com"
  ];

  // Function to validate the email
  const validateEmail = (email) => {
      // Basic email format regex
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (!emailRegex.test(email)) {
        handleSnackbarOpen("error", "Please enter a valid email address");
        return false;
      }

      // Extract the domain from the email
      const domain = email.split('@')[1];

      // Check if the domain is in the list of generic domains
      if (genericDomains.includes(domain)) {
        handleSnackbarOpen("error", "Please enter a business email address");
        return false;
      }

      return true; // Valid organizational email
  };

  return (
    <Box sx={{height:{xs:"300vw", md:"91%"}, width:"100%"}}>
      <HomeNavbar />
      <Box sx={{height:"100%", width:"100%", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#eeeeee"}}>
        <Paper elevation={3} sx={{height:"90%", width:{xs:"95%", md:"70vw"}, display:"flex", flexDirection:{xs:"column", md:"row"}}}>
          <Box sx={{flex:1, display:"flex", flexDirection:"column", paddingY:{xs:"0px", md:"50px"}, paddingX:{xs:"20px", md:"100px"}}}>
            <Box sx={{display:{xs:"none", md:"block"}}}>
              <img src={utienceLogo} alt="logo" style={{width:"50%", height:"auto"}}/>
            </Box>
            <Typography variant='h4' fontWeight={900} marginTop={"30px"}>Book a Demo</Typography>
            <Typography fontSize={{xs:"11pt", md:"15pt"}} fontWeight={300} sx={{color:"#555555"}} marginTop={"10px"}>Ready to see how our platform can transform your business? Schedule a personalized demo today and discover the power of our tools in action. Our experts will walk you through the features tailored to your needs, answer your questions, and show you how to get the most out of our solutions. Don’t miss the opportunity to elevate your projects—book your demo now!</Typography>
          </Box>
          <Box sx={{flex:1, display:"flex", flexDirection:"column", paddingY:{xs:"20px", md:"50px"}, paddingX:{xs:"20px", md:"100px"}}}>
            <Typography variant='h4' fontWeight={500}>See Utimind in action</Typography>
            <TextField value={email} onChange={(e) => {setEmail(e.target.value)}} label="Email" type='email' variant="outlined" sx={{
                mt: 4,
                width:{xs:"100%", md:"90%"}
                }} />
            <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} label="First Name" type='text' variant="outlined" sx={{
                mt: 2,
                width:{xs:"100%", md:"90%"}
                }} />
            <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} label="Last Name" type='text' variant="outlined" sx={{
                mt: 2,
                width:{xs:"100%", md:"90%"}
                }} />
            <TextField value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} label="Organization Name" type='text' variant="outlined" sx={{
                mt: 2,
                width:{xs:"100%", md:"90%"}
                }} />
            <FormControl margin='normal' sx={{width:{xs:"100%", md:"90%"}}}>
              <InputLabel>Organization Size</InputLabel>
              <Select
                label="Organization Size"
                value={organizationSize}
                onChange={(e) => setOrganizationSize(e.target.value)}
              >
                <MenuItem value={"oneToTen"}>1-10 People</MenuItem>
                <MenuItem value={"tenToThirty"}>10-30 People</MenuItem>
                <MenuItem value={"moreThanThirty"}>30+ People</MenuItem>
              </Select>
            </FormControl>
            <TextField value={role} onChange={(e) => setRole(e.target.value)} label="Your Role" type='text' variant="outlined" sx={{
                mt: 1,
                width:{xs:"100%", md:"90%"}
                }} />
            <TextField value={caseDescription} onChange={(e) => setCaseDescription(e.target.value)} label="Describe your case" type='text' variant="outlined" multiline rows={3} sx={{
                mt: 2,
                width:{xs:"100%", md:"90%"}
                }} />
            <Button variant='contained' sx={{
                mt: 2,
                height: "55px",
                width: {xs:"100%", md:"90%"},
                backgroundColor: "#383a6e",
                ":hover": {
                  backgroundColor:"#686dba"
                }
              }}
            onClick={handleRequest}>
                Request Demo
            </Button>
          </Box>
        </Paper>
      </Box>
      <Footer />
      <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Demo