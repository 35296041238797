import { Alert, Box, Button, CircularProgress, InputLabel, MenuItem, Modal, Select, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../apis/requests';
import { api, model, team } from '../../apis/urls';

const CreateApiModal = ({open, handleClose}) => {

    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [apiName, setApiName] = useState(null);
    const [models, setModels] = useState(null);
    const [selectedModelId, setSelectedModelId] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleCloseLocally = () => {
        handleClose();
    }

    useEffect(() => {
        fetchModels();
    }, []);

    const fetchModels = () => {
        const url = model.getByUserId();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setModels(data);
            })
    };

    const handleCreate = () => {
        if(apiName && apiName != "" && selectedModelId){
            const url = api.create();
            const headers = {
                "Content-Type": "application/json",
                "Authorization": authToken
            };
            const body = JSON.stringify({
                "model_id": selectedModelId,
                "api_name": apiName
            });
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok){
                        handleSnackbarOpen("success", "API created successfully.")
                        handleCloseLocally();
                        return;
                    }
                    else{
                        return response.json();
                    }
                })
                .then(data => {
                    if (data) {
                        handleSnackbarOpen("error", data["error"]);
                    }
                })
        }
        else{
            handleSnackbarOpen("error", "Please provide an API name and choose a model.");
        }
    }

  return (
    <Box>
        <Modal
            open={open}
            onClose={handleCloseLocally}>
            <Box sx={{position: 'absolute',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"space-between",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "500px",
                    height: "350px",
                    bgcolor: theme.palette.text.light,
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,}}>
                <Box>
                    <Typography variant='h5'>Create New API</Typography>

                    <InputLabel id="model-select-label" sx={{mt:4}}>Select a model to work</InputLabel>
                    <Select
                        labelId="model-select-label"
                        id="model-select"
                        value={selectedModelId}
                        label="Select a model to work"
                        onChange={(e) => {setSelectedModelId(e.target.value)}}
                        sx={{
                            width:500,
                        }}
                    >
                        {models ? (
                            models.map((model) => {
                                return (<MenuItem value={model["id"]}>{model["name"]}</MenuItem>)
                            })
                        ) : (
                            <CircularProgress sx={{color: theme.palette.primary.main}} />
                        )}
                    </Select>
                    
                    <TextField onChange={(e) => setApiName(e.target.value)}
                        label="API Name" type='text' variant="outlined" sx={{
                            mt: 6,
                            width: "100%"
                    }} />
                </Box>

                <Button onClick={handleCreate} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: "30%",
                    alignSelf: "flex-end"
                }}>
                    Create
                </Button>
            </Box>
        </Modal>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default CreateApiModal