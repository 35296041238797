import { BASE_URL } from "./apis/urls";

export const formatDate = (dateString) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    
    return `${month} ${day}, ${year}`;
}

export const javascriptCodeAsString = (api_id, api_token, data) => {
    const keysString = Object.keys(data).map(key => `"${key}": ""`).join(",\n\t\t\t\t");
    return `// Function to make the API POST request
const makeApiPostRequest = async () => {
    // Define the API URL
    const apiUrl = '${BASE_URL}/api/make_prediction/';

    // Data to be sent in the POST request
    const postData = {
        api_id: ${api_id},
        api_token: "${api_token}",
        X_input_data: [
            {
                ${keysString}
            }
        ]
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // Set the request method
            headers: {
                'Content-Type': 'application/json' // Set the headers to inform the server about the type of the content
            },
            body: JSON.stringify(postData) // Convert the JavaScript object to a JSON string
        });

        if (!response.ok) {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data["error"]); // Log success and data to the console
        } else {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data); // Log success and data to the console
        }
    } catch (error) {
        console.error('Error:', error); // Log errors to the console
    }
};

# Call the function to make the request
makeApiPostRequest();`;
}

export const pythonCodeAsString = (api_id, api_token, data) => {
    const keysString = Object.keys(data).map(key => `"${key}": ""`).join(",\n\t\t\t\t");
    return `import requests
import json

# Function to make the API POST request
def make_api_post_request():
    # Define the API URL
    api_url = '${BASE_URL}/api/make_prediction/';

    # Data to be sent in the POST request
    post_data = {
        "api_id": ${api_id},
        "api_token": "${api_token}",
        "X_input_data": [
            {
                ${keysString}
            }
        ]
    }

    try:
        response = requests.post(api_url, headers={'Content-Type': 'application/json'}, data=json.dumps(post_data))
        if response.status_code != 200:
            data = response.json()  # Parse JSON response into a Python object
            print("Error:", data.get("error"))  # Log error to the console
        else:
            data = response.json()  # Parse JSON response into a Python object
            print(data)  # Log success and data to the console
    except requests.exceptions.RequestException as error:
        print('Error:', error)  # Log exceptions to the console

# Call the function to make the request
make_api_post_request()`
}

export const javascriptObjectDetectionCodeAsString = (api_id, api_token) => {
    return `// Function to make the API POST request
const makeApiPostRequest = async () => {
    // Define the API URL
    const apiUrl = '${BASE_URL}/api/make_image_prediction/';

    // Data to be sent in the POST request
    const formData = new FormData();
    formData.append("api_id", ${api_id})
    formData.append("api_token", "${api_token}")
    formData.append("conf", 0.25); //Between 0.0 and 1.0
    formData.append("iou", 0.75); //Between 0.0 and 1.0
    for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // Set the request method
            body: formData
        });

        if (!response.ok) {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data["error"]); // Log success and data to the console
        } else {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data); // Log success and data to the console
        }
    } catch (error) {
        console.error('Error:', error); // Log errors to the console
    }
};

# Call the function to make the request
makeApiPostRequest();`;
}

export const pythonObjectDetectionCodeAsString = (api_id, api_token) => {
    return `import requests

# Define the API URL
api_url = '${BASE_URL}/api/make_image_prediction/'

# Data to be sent in the POST request
data = {
    "api_id": ${api_id},
    "api_token": "${api_token}",
    "conf": 0.25,  # Between 0.0 and 1.0
    "iou": 0.75   # Between 0.0 and 1.0
}

# Assuming 'images' is a list of file paths
images = ['path_to_image1.jpg', 'path_to_image2.jpg']

# Files to be uploaded
files = [('images', open(image, 'rb')) for image in images]

# Make the API POST request
try:
    response = requests.post(api_url, data=data, files=files)
    
    if response.ok:
        print(response.json())  # Log success and data to the console
    else:
        print(response.json().get("error", "Unknown error"))  # Log error message to the console

except requests.exceptions.RequestException as e:
    print('Error:', e)  # Log any request errors to the console

# Close the opened image files
for _, file in files:
    file.close()`
}

export const javascriptImageClassificationCodeAsString = (api_id, api_token) => {
    return `// Function to make the API POST request
const makeApiPostRequest = async () => {
    // Define the API URL
    const apiUrl = '${BASE_URL}/api/make_image_prediction/';

    // Data to be sent in the POST request
    const formData = new FormData();
    formData.append("api_id", ${api_id})
    formData.append("api_token", "${api_token}")
    for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
    }

    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // Set the request method
            body: formData
        });

        if (!response.ok) {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data["error"]); // Log success and data to the console
        } else {
            const data = await response.json(); // Parse JSON response into a JavaScript object
            console.log(data); // Log success and data to the console
        }
    } catch (error) {
        console.error('Error:', error); // Log errors to the console
    }
};

# Call the function to make the request
makeApiPostRequest();`;
}

export const pythonImageClassificationCodeAsString = (api_id, api_token) => {
    return `import requests

# Define the API URL
api_url = '${BASE_URL}/api/make_image_prediction/'

# Data to be sent in the POST request
data = {
    "api_id": ${api_id},
    "api_token": "${api_token}"
}

# Assuming 'images' is a list of file paths
images = ['path_to_image1.jpg', 'path_to_image2.jpg']

# Files to be uploaded
files = [('images', open(image, 'rb')) for image in images]

# Make the API POST request
try:
    response = requests.post(api_url, data=data, files=files)
    
    if response.ok:
        print(response.json())  # Log success and data to the console
    else:
        print(response.json().get("error", "Unknown error"))  # Log error message to the console

except requests.exceptions.RequestException as e:
    print('Error:', e)  # Log any request errors to the console

# Close the opened image files
for _, file in files:
    file.close()`
}
