import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import HomeNavbar from '../components/home/HomeNavbar'
import Footer from '../components/home/Footer'
import { Link } from 'react-router-dom'

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

  return (
    <Box>
        <HomeNavbar />
        <Box sx={{display:"flex", flexDirection:"column", paddingX:{xs:"2%", md:"25%"}, paddingY:{xs:"20px", md:"100px"}, alignItems:"flex-start"}}>
            <Typography variant="h4" gutterBottom>
                Terms of Service
            </Typography>

            <Typography variant="body1" paragraph>
                Welcome to Utimind, a brand of Utience Technology Inc. These Terms of Service ("Terms") govern your access to and use of our platform, services, and products provided by Utience Technology Inc. ("we," "our," or "us"). By accessing or using our services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our services.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Acceptance of Terms
            </Typography>
            <Typography variant="body1" paragraph>
                By registering for, accessing, or using Utimind, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy, which is incorporated by reference.
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. Use of Services
            </Typography>
            <Typography variant="body1" paragraph>
                You agree to use the platform and services in compliance with all applicable laws and regulations. You are responsible for your use of the platform, and you must not misuse or abuse the services, including but not limited to engaging in unauthorized access, disrupting the integrity or performance of the services, or using the services in a manner that infringes on the rights of others.
            </Typography>

            <Typography variant="h6" gutterBottom>
                3. User Account
            </Typography>
            <Typography variant="body1" paragraph>
                To access certain features of our platform, you may need to create an account. You agree to provide accurate and complete information during registration and keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Data Handling and Security
            </Typography>
            <Typography variant="body1" paragraph>
                Utimind uses SSL (Secure Sockets Layer) to encrypt data during transmission to ensure secure communication between you and our platform. However, please be aware that data stored on our servers is not encrypted. While we implement various security measures to protect your data, including ongoing efforts to improve our data encryption practices, no method of transmission over the Internet or method of electronic storage is completely secure, and we cannot guarantee absolute security of your data.
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Intellectual Property
            </Typography>
            <Typography variant="body1" paragraph>
                All content, software, and other materials on the platform, including but not limited to text, graphics, logos, and interfaces, are owned by or licensed to Utience Technology Inc. You may not copy, modify, distribute, or create derivative works based on any content from the platform without our explicit written consent.
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Fees and Payment
            </Typography>
            <Typography variant="body1" paragraph>
                Certain features of our platform may require payment of fees. You agree to pay all applicable fees and charges associated with your use of such features. All fees are non-refundable except as required by law or as otherwise specified in writing by Utience Technology Inc.
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Termination
            </Typography>
            <Typography variant="body1" paragraph>
                We reserve the right to suspend or terminate your access to the platform at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of the platform, us, or third parties, or for any other reason.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                To the fullest extent permitted by law, Utience Technology Inc. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of, or inability to access or use, the platform.
            </Typography>

            <Typography variant="h6" gutterBottom>
                9. Changes to Terms
            </Typography>
            <Typography variant="body1" paragraph>
                We may revise these Terms from time to time. If we make changes, we will notify you by revising the date at the top of these Terms and, in some cases, we may provide you with additional notice. Your continued use of the platform after the changes have been made will constitute your acceptance of the changes.
            </Typography>

            <Typography variant="h6" gutterBottom>
                10. Governing Law
            </Typography>
            <Typography variant="body1" paragraph>
                These Terms and any action related thereto will be governed by the laws of Turkey, without regard to its conflict of laws principles.
            </Typography>

            <Typography variant="h6" gutterBottom>
                11. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions about these Terms, please contact us at <Link href="mailto:contact@utience.com">contact@utience.com</Link>.
            </Typography>

            <Typography variant="body1" paragraph>
                Utience Technology Inc., Istanbul, Turkey.
            </Typography>
        </Box>
        <Footer />
    </Box>
  )
}

export default Terms