import styled from '@emotion/styled';
import { Alert, Box, Button, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

import { postRequest } from "../apis/requests";
import { user } from "../apis/urls";
import { useNavigate } from 'react-router-dom';

import utienceLogo from "../assets/images/utience_logo_primary.svg";
import loginBackground from "../assets/images/login_background.jpg";

const PasswordBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "550px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    gap: "50px"
  }));

const ResetPassword = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState("");

    const handleChangePassword = () => {

        if(password == passwordValidation) {
            const url = user.reset_password_confirmation() + window.location.href.split("reset_password_confirm/")[1];

            const headers = {
                'Content-Type': 'application/json'
            }
            const body = JSON.stringify({
                "new_password": password
            })
            postRequest(url, headers, body)
                .then(response => {
                    if (response.ok){
                        handleSnackbarOpen("success", "Password reset successfully. You'll be redirected to login page.");
                        setTimeout(
                            () => navigate("/platform/login"), 
                            3000
                        );
                    }
                })
        }
        else{
            handleSnackbarOpen("success", "Passwords do not match.")
        }
        
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

  return (
    <Box sx={{ width:"100%", height:"100%", backgroundImage:`url(${loginBackground})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
        <PasswordBox bgcolor={theme.palette.background.lighter}>
            <Box sx={{width:"90%", height:"100px", display:"flex", justifyContent:"center"}}>
                <img src={utienceLogo} alt='logo' style={{height:"100px", width:"auto"}} />
            </Box>
            <Box sx={{display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                    borderRadius: "10px"}}>
                <Typography variant="h6" sx={{color: theme.palette.text.dark}}>Reset Password Here</Typography>
                <TextField onChange={(e) => setPassword(e.target.value)} label="Password" type='password' variant="outlined" sx={{
                    mt: 4,
                    width:"90%"
                    }} />
                <TextField onChange={(e) => setPasswordValidation(e.target.value)} label="Password (Again)" type='password' variant="outlined" sx={{
                    mt: 2,
                    width:"90%"
                    }} />
                <Button onClick={handleChangePassword} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: "90%"
                }}>
                    Change Password
                </Button>
            </Box>
        </PasswordBox>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ResetPassword