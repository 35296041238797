import { Alert, Box, Button, Chip, Modal, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../../apis/requests';
import { team } from '../../apis/urls';

const JoinRequestsModal = ({open, handleClose, teamJoinRequestData}) => {
    const authToken = localStorage.getItem("authToken");
    const theme = useTheme();

    const [requests, setRequests] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    const handleCloseLocally = () => {
        handleClose();
    }

    useEffect(() => {
        const url = team.getTeamJoinRequests();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setRequests(data.map(request => {
                    return {...request, status:"pending"}
                }));
            })    
    }, [])

    const handleRequest = (request, isAccepted) => {
        if (isAccepted){
            const url = team.acceptTeamJoinRequest()
            const headers = {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
            const body = JSON.stringify(request);
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok) {
                        handleSnackbarOpen("success", `${request.user.first_name} ${request.user.last_name} has been added to ${request.team.name}`);
                        const updatedRequests = requests.map(req => {
                            if (req.id === request.id) {
                            return { ...req, status: "Accepted" };
                            }
                            return req;
                        });
                        setRequests(updatedRequests);
                    }
                    else{
                        handleSnackbarOpen("error", `Error adding ${request.user.first_name} ${request.user.last_name} to ${request.team.name}`);
                    }
                })
        }
        else{
            const url = team.rejectTeamJoinRequest()
            const headers = {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
            const body = JSON.stringify(request);
            postRequest(url, headers, body)
                .then(response => {
                    if(response.ok) {
                        handleSnackbarOpen("success", `${request.user.first_name} ${request.user.last_name} has been rejected from joining ${request.team.name}`);
                        const updatedRequests = requests.map(req => {
                            if (req.id === request.id) {
                            return { ...req, status: "Rejected" };
                            }
                            return req;
                        });
                        setRequests(updatedRequests);
                    }
                    else{
                        handleSnackbarOpen("error", `Error rejecting ${request.user.first_name} ${request.user.last_name} from joining ${request.team.name}`);
                    }
                })
        }
        
    }

  return (
    <Box>
        <Modal
            open={open}
            onClose={handleCloseLocally}>
            <Box sx={{position: 'absolute',
                    display: "flex",
                    flexDirection: "column",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "900px",
                    height: "400px",
                    bgcolor: theme.palette.text.light,
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,}}>
                <Typography variant='h5'>Join Requests</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {(requests && requests.length > 0) ? (
                                requests.map(request => (
                                    <TableRow>
                                        <TableCell>{request.user.first_name} {request.user.last_name}</TableCell>
                                        <TableCell>{request.user.email}</TableCell>
                                        <TableCell>{request.team.name}</TableCell>
                                        <TableCell>{request.team.code}</TableCell>
                                        {request.status == "pending" ? (
                                            <>
                                                <TableCell>
                                                    <Button variant="contained"
                                                        sx={{color:theme.palette.text.light, 
                                                            fontSize:"12pt", 
                                                            backgroundColor:theme.palette.success.main}} 
                                                        onClick={() => handleRequest(request, true)}
                                                        >Accept</Button>
                                                </TableCell>
                                                <TableCell>
                                                    <Button variant="contained"
                                                        sx={{color:theme.palette.text.light, 
                                                            fontSize:"12pt", 
                                                            backgroundColor:theme.palette.warning.main}} 
                                                        onClick={() => handleRequest(request, false)}
                                                        >Reject</Button>
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell>
                                                <Chip label={request.status} color={request.status=="Accepted" ? ('success') : ('error')} />
                                            </TableCell>
                                        )}
                                        
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell>No new join request</TableCell>
                                </TableRow>
                            )}
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default JoinRequestsModal