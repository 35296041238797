import { Box, CircularProgress, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getRequest } from '../apis/requests';
import { user } from '../apis/urls';
import LicenseDetails from '../components/user/LicenseDetails';
import UpgradeLicense from '../components/user/UpgradeLicense';
import LicenseManagement from '../components/user/LicenseManagement';

const Account = () => {

    const authToken = localStorage.getItem('authToken');
    const theme = useTheme();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const url = user.getUser();
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setUserData(data);
            });
    }, [])

    const [tabIndex, setTabIndex] = React.useState(0);
    
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"100%"}}>
        <Typography variant='h5' sx={{marginTop:"30px"}}>My Account</Typography>
        <Box sx={{display:"flex", flexDirection:"row", marginTop:"30px"}}>
            {userData ? (
                <>
                    <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                        <Box>
                            <List>
                                <ListItem disablePadding onClick={() => setTabIndex(0)}>
                                    <ListItemButton selected={tabIndex === 0}>
                                        <ListItemText primary="License Details" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding onClick={() => setTabIndex(1)}>
                                    <ListItemButton selected={tabIndex === 1}>
                                        <ListItemText primary="License Sharing" />
                                    </ListItemButton>
                                </ListItem>
                                {/* <ListItem disablePadding onClick={() => setTabIndex(2)}>
                                    <ListItemButton selected={tabIndex === 2}>
                                        <ListItemText primary="Invoices" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding onClick={() => setTabIndex(3)}>
                                    <ListItemButton selected={tabIndex === 3}>
                                        <ListItemText primary="Payment Methods" />
                                    </ListItemButton>
                                </ListItem>
                                {((userData["user_license"]["license_type"] == "TRIAL" || userData["user_license"]["license_type"] == "PRO") 
                                    && (userData["user_license"]["license_key"] == userData["user_license"]["license_origin"])) ? (
                                    <ListItem disablePadding onClick={() => setTabIndex(4)}>
                                        <ListItemButton selected={tabIndex === 4}>
                                            <ListItemText primary="Upgrade License" />
                                        </ListItemButton>
                                    </ListItem>
                                ) : (<></>)} */}
                            </List>
                        </Box>
                    </Box>
                    <hr />
                    <Box sx={{flex:4, display:"flex", flexDirection:"column"}}>
                        <TabPanel value={tabIndex} index={0}>
                            <LicenseDetails userData={userData} clickToUpgrade={() => setTabIndex(4)} shareYourLicense={() => setTabIndex(1)} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <LicenseManagement userData={userData} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <p>Invoices</p>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <p>Payment Methods</p>
                        </TabPanel>
                        {((userData["user_license"]["license_type"] == "TRIAL" || userData["user_license"]["license_type"] == "STARTER" || userData["user_license"]["license_type"] == "PRO")
                            && (userData["user_license"]["license_key"] == userData["user_license"]["license_origin"])) ? (
                            <TabPanel value={tabIndex} index={4}>
                                <UpgradeLicense />
                            </TabPanel>
                        ) : (<></>)}
                    </Box>
                </>
            ) : (
                <CircularProgress />
            )}
        </Box>
    </Box>
  )
}

export default Account