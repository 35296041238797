import { Box, Button, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { BASE_URL } from '../../apis/urls';
import { useDispatch, useSelector } from 'react-redux';
import { setImageData } from '../../store/imageDataSlice';

function hexToRGBA(hex, alpha) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const PolygonDrawer = ({ imageUrl }) => {
  const authToken = localStorage.getItem("authToken");
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state.imageData);
  const labelOptions = useSelector((state) => state.labelOptions);
  const [selectedLabelIndex, setSelectedLabelIndex] = useState(0);
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const [labels, setLabels] = useState([]);
  const [points, setPoints] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(800);
  const [canvasHeight, setCanvasHeight] = useState(800);
  const [draggingPoint, setDraggingPoint] = useState(null); // Track the point being dragged
  const [isDragging, setIsDragging] = useState(false); // Separate dragging state

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();

    image.src = imageUrl;
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  }, [imageUrl]);

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    const drawPolygonWithCorners = (polygonPoints, color) => {
      if (polygonPoints.length > 0) {
        ctx.beginPath();
        ctx.moveTo(polygonPoints[0].x, polygonPoints[0].y);
        polygonPoints.forEach((point, index) => {
          if (index !== 0) {
            ctx.lineTo(point.x, point.y);
          }
        });
        ctx.closePath();
        ctx.fillStyle = hexToRGBA(color, 0.3);
        ctx.fill();
        ctx.strokeStyle = color;
        ctx.stroke();

        // Draw squares at each corner
        polygonPoints.forEach((point) => {
          ctx.fillStyle = color;
          ctx.fillRect(point.x - 3, point.y - 3, 6, 6); // Draw a small square (6x6 pixels)
        });
      }
    };

    labels.forEach((label) => {
      drawPolygonWithCorners(label["points"], labelOptions.labelOptions.filter(labelOption => labelOption["name"] == label["label"])[0]["color"]);
    });

    drawPolygonWithCorners(points, labelOptions.labelOptions[selectedLabelIndex]["color"]);

    dispatch(setImageData({...imageData.imageData, labels: labels}));

  }, [labels, points, isDrawing, imageUrl, selectedLabelIndex, labelOptions]);

  useEffect(() => {
      setLabels(imageData.imageData.labels)
  }, [imageData.imageData.labels]);

  useEffect(() => {
    const actualAspectRatio = imageData.imageData.actualWidth / imageData.imageData.actualHeight;
    let canvasAspectRatio = 1;
    //image is wider than canvas
    if (actualAspectRatio > 1) {
      canvasAspectRatio = 800 / imageData.imageData.actualWidth;
    }
    else {
      canvasAspectRatio = 800 / imageData.imageData.actualHeight;
    }
    setCanvasWidth(canvasAspectRatio * imageData.imageData.actualWidth);
    setCanvasHeight(canvasAspectRatio * imageData.imageData.actualHeight);
    dispatch(setImageData({...imageData.imageData, aspectRatio: 1 / canvasAspectRatio}));
  }, [imageData.imageData.url]);

  const handleImageClick = (e) => {
    if (isDragging || draggingPoint !== null) return; // Ignore clicks during dragging
    const rect = imageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setPoints([...points, { x, y }]);
    setIsDrawing(true);
  };

  const handleImageRightClick = (e) => {
    e.preventDefault();
    if (isDragging) {
      // Prevent completing the polygon if currently dragging
      setIsDragging(false);
      return;
    }
    if (isDrawing && !isDragging) {
      setLabels([...labels, {"points": points, "label":labelOptions.labelOptions[selectedLabelIndex]["name"]} ]);
      setPoints([]);
      setIsDrawing(false);
    }
  };

  const handleMouseDown = (e) => {
    const rect = imageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Check if a handle (square) is clicked
    labels.forEach((label, labelIndex) => {
      label["points"].forEach((point, pointIndex) => {
        if (Math.abs(point.x - x) < 5 && Math.abs(point.y - y) < 5) {
          setDraggingPoint({ labelIndex, pointIndex });
          setIsDrawing(false); // Stop drawing when dragging a point
          setIsDragging(true); // Start dragging
        }
      });
    });

    points.forEach((point, pointIndex) => {
      if (Math.abs(point.x - x) < 5 && Math.abs(point.y - y) < 5) {
        setDraggingPoint({ polygonIndex: -1, pointIndex }); // -1 indicates current drawing polygon
        setIsDrawing(false); // Stop drawing when dragging a point
        setIsDragging(true); // Start dragging
      }
    });
  };

  const handleMouseMove = (e) => {
    if (!draggingPoint) return;

    const rect = imageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const updatedLabels = [...labels];
    const updatedPoints = [...points];

    if (draggingPoint.polygonIndex === -1) {
      // Update current drawing polygon
      updatedPoints[draggingPoint.pointIndex] = { x, y };
      setPoints(updatedPoints);
    } else {
      // Update an existing polygon
      updatedLabels[draggingPoint.polygonIndex]["points"][draggingPoint.pointIndex] = { x, y };
      setLabels(updatedLabels);
    }
  };

  const handleMouseUp = () => {
    if (draggingPoint) {
      setDraggingPoint(null);
      setIsDrawing(false); // Ensure drawing is not started after releasing drag
      setIsDragging(false); // Stop dragging
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          height: "800px",
          width: "800px",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={`${BASE_URL}${imageData.imageData.url}`}
            ref={imageRef}
            onClick={handleImageClick}
            onContextMenu={handleImageRightClick}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onDragStart={(e) => e.preventDefault()}
            style={{
              display: 'block',
              width: canvasWidth,
              height: canvasHeight,
              maxHeight: "800px",
              objectFit: "contain",
              cursor: "crosshair"
            }}
          />
          <canvas
            ref={canvasRef}
            width={canvasWidth}
            height={canvasHeight}
            style={{ position: 'absolute', pointerEvents: 'none', border: "1px solid black" }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", overflowY: "scroll", width: "200px", margin: "20px 30px", padding: "10px", border: "1px solid #ccc" }}>
        <Typography>Choose a label:</Typography>
        {labelOptions.labelOptions.map((item, index) => (
          <Button
            key={index}
            onClick={() => setSelectedLabelIndex(index)}
            variant={selectedLabelIndex === index ? 'contained' : 'outlined'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: "space-between",
              margin: '8px',
              padding: '10px 20px',
              border: selectedLabelIndex === index ? '2px solid' : '1px solid',
              borderColor: selectedLabelIndex === index ? 'primary.main' : 'grey.400',
              textTransform: 'none'
            }}
          >
            {item.name}
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: item.color,
                marginLeft: '8px',
              }}
            />
          </Button>
        ))}
        {isDrawing ? (
          <Button onClick={handleImageRightClick}>
            Click to finish <br />(or right click on the image)
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default PolygonDrawer;
