import styled from '@emotion/styled';
import { Alert, Box, Button, CircularProgress, Snackbar, TextField, Typography, Checkbox, FormControlLabel, Link, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { postRequest } from "../apis/requests";
import { user } from "../apis/urls";
import { useNavigate } from 'react-router-dom';

import utienceLogo from "../assets/images/utience_logo_primary.svg";
import loginBackground from "../assets/images/login_background.jpg";

const Signup = () => {

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [agreeToTerms, setAgreeToTerms] = useState(false); // State for agreement checkbox

    const [isInProgress, setIsInProgress] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");

    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const genericDomains = [
        "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", 
        "live.com", "aol.com", "icloud.com", "mail.com"
    ];

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!emailRegex.test(email)) {
          handleSnackbarOpen("error", "Please enter a valid email address");
          return false;
        }

        const domain = email.split('@')[1];
        if (genericDomains.includes(domain)) {
          handleSnackbarOpen("error", "Please enter a business email address");
          return false;
        }
        return true; // Valid organizational email
    };

    const handleSignup = () => {
        if (!agreeToTerms) {
            handleSnackbarOpen("error", "You must agree to the terms and conditions.");
            return;
        }

        setIsInProgress(true);
        if (email && firstName && lastName && password.length > 7) {
            if (validateEmail(email)) {
                const headers = {
                    'Content-Type': 'application/json'
                };

                const body = JSON.stringify({
                    username: email,
                    first_name: firstName,
                    last_name: lastName,
                    password: password
                });

                const url = user.signup();
                postRequest(url, headers, body)
                    .then(response => {
                        if (response.ok) {
                            navigate('/platform/post-signup', { state: { isInAppNavigation: true } });
                        }
                        return response.json();
                    })
                    .then(data => {
                        setIsInProgress(false);
                        if (data.username) {
                            alert(data.username[0]);
                        }
                    });
            }
        } else {
            setIsInProgress(false);
            handleSnackbarOpen("error", "Please fill all fields and password length at least 8 characters long.");
        }
    }

  return (
    <Box sx={{ width:"100%", height:"100%", backgroundImage:`url(${loginBackground})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
        <Box sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)",
            width: {xs:"75vw", md:"350px"},
            height: {xs:"160vw", md:"650px"},
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: 24,
            p: 4,
            gap: "20px"
        }}>
            <Box sx={{width:"90%", height:"100px", display:"flex", justifyContent:"center"}}>
                <img src={utienceLogo} alt='logo' style={{height:"100px", width:"auto"}} onClick={() => navigate("/")}/>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: {xs:"100%", md:"100%"},
                borderRadius: "10px"
            }}>
                <Typography variant="h6" sx={{color: theme.palette.text.dark}}>Sign Up</Typography>
                <TextField onChange={(e) => setEmail(e.target.value)} label="Business Email" type='email' variant="outlined" sx={{
                    mt: 2,
                    width:{xs:"100%", md:"90%"}
                }} />
                <TextField onChange={(e) => setFirstName(e.target.value)} label="First Name" type='text' variant="outlined" sx={{
                    mt: 2,
                    width:{xs:"100%", md:"90%"}
                }} />
                <TextField onChange={(e) => setLastName(e.target.value)} label="Last Name" type='text' variant="outlined" sx={{
                    mt: 2,
                    width:{xs:"100%", md:"90%"}
                }} />
                <TextField onChange={(e) => setPassword(e.target.value)} label="Password" type='password' variant="outlined" sx={{
                    mt: 2,
                    width:{xs:"100%", md:"90%"}
                }} />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                            color="primary"
                        />
                    }
                    label={<Typography variant="body2">I agree to the <Link href="/terms" target="_blank">Terms of Service</Link> and <Link href="/privacy" target="_blank">Privacy Policy</Link>.</Typography>}
                    sx={{ mt: 2, width: {xs:"100%", md:"90%"}}}
                />

                <Button onClick={handleSignup} disabled={isInProgress || !agreeToTerms} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: {xs:"100%", md:"90%"}
                }}>
                    Signup
                </Button>
                {isInProgress && (
                    <CircularProgress />
                )}
                <Typography sx={{mt:1}} fontWeight={300}>
                    <Link href='login' style={{textDecoration:"none", color:"blue"}}>Already have an account?</Link>
                </Typography>
            </Box>
        </Box>
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default Signup;
