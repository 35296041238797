import { FormatQuote } from '@mui/icons-material';
import { Avatar, Box, Container, Grid, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import person1 from "../../assets/images/man.png";
import person2 from "../../assets/images/man (2).png";
import person3 from "../../assets/images/woman.png";

const testimonials = [
  {
    text: "Utimind transformed our approach to data. Without a data science background, we built and deployed AI models that drove significant business growth.",
    name: "John Doe",
    title: "CEO, TechCorp",
    avatar: person1
  },
  {
    text: "With Utimind, we quickly prototyped AI models and deployed them within hours. It's an invaluable tool for our team.",
    name: "Jane Smith",
    title: "Data Scientist, Innovate Ltd.",
    avatar: person2
  },
  {
    text: "Utimind's no-code platform allowed us to implement AI solutions effortlessly, saving us time and costs.",
    name: "Emily Johnson",
    title: "CTO, NextGen Solutions",
    avatar: person3
  },
];

const Testimonials = () => {

  const theme = useTheme();

  return (
    <Box 
      sx={{ 
        width: "100%", 
        display: "flex", 
        flexDirection: "column", 
        alignItems: "center", 
        paddingY: { xs: "100px", md: "100px" },
        backgroundColor: "white" 
      }}
    >
      <Container sx={{ mt: 4 }}>
        <Typography 
          variant="h3" 
          align="center" 
          gutterBottom 
          sx={{ fontSize: { xs: '7.5vw', sm: '7.5vw', md: '48px' } }}
        >
          What Our Clients Say
        </Typography>
        <Typography 
          variant="h5" 
          align="center" 
          color="textSecondary" 
          gutterBottom 
          sx={{ marginTop: { xs: '20px', md: '50px' }, fontSize: { xs: '18px', sm: '20px', md: '24px' } }}
        >
          Join Utimind and leverage data-driven decision making to scale your businesses.
        </Typography>
        <Grid container spacing={4} sx={{ marginTop: "30px" }}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  padding: { xs: 2, sm: 3 }, 
                  textAlign: 'center',
                  border: '1px solid #e0e0e0',
                  borderRadius: 2,
                  height: { xs: 'auto', md: 350 }, // Responsive height
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: "center"
                }}
              >
                <Box>
                  <FormatQuote sx={{ fontSize: 50, color: '#4B4F8A' }} />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      marginY: 1, 
                      fontSize: { xs: '5vw', sm: '5vw', md: '19px' },
                    }}
                  >
                    {testimonial.text}
                  </Typography>
                </Box>
                <Box>
                  <Avatar 
                    src={testimonial.avatar} 
                    alt={testimonial.name} 
                    sx={{ 
                      width: 60, 
                      height: 60, 
                      margin: '10px auto' 
                    }} 
                  />
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      marginTop: 1, 
                      fontSize: { xs: '16px', md: '18px' } 
                    }}
                  >
                    {testimonial.name}
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    color="textSecondary"
                    sx={{ fontSize: { xs: '14px', md: '16px' } }}
                  >
                    {testimonial.title}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Testimonials;
