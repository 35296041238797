import { Box, Paper, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import React from 'react';

import welcomeImage from "../../assets/images/1.jpg";
import welcomeImage2 from "../../assets/images/2.jpg";
import welcomeImage3 from "../../assets/images/3.svg";
import welcomeImage4 from "../../assets/images/4.svg";
import welcomeImage5 from "../../assets/images/5.svg";
import welcomeImage6 from "../../assets/images/6.svg";
import { useNavigate } from 'react-router-dom';

const ImageCarousel = ({ items }) => {
  return (
    <Carousel
      indicators={true}
      navButtonsAlwaysVisible={true}
    >
      {items.map((item, index) => (
        <Item key={index} item={item} />
      ))}
    </Carousel>
  );
};

const Item = ({ item }) => {
  return (
    <Paper sx={{ position: 'relative', height: { xs: '200px', sm: '300px', md: '450px' } }}>
      <Box
        component="img"
        src={item.image}
        alt={item.title}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Ensure the image covers the container while maintaining aspect ratio
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          padding: '10px',
          borderRadius: '5px',
          fontSize: { xs: '12px', sm: '14px', md: '16px' }, // Responsive font size
        }}
      >
        <p>{item.description}</p>
      </Box>
    </Paper>
  );
};

const Welcome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const items = [
    {
      image: welcomeImage,
      description: 'Annotate your image dataset'
    },
    {
      image: welcomeImage2,
      description: 'Collaborate with your team'
    },
    {
      image: welcomeImage3,
      description: 'Manage your data'
    },
    {
      image: welcomeImage4,
      description: 'Build and train your models'
    },
    {
      image: welcomeImage5,
      description: 'Analyze model results'
    },
    {
      image: welcomeImage6,
      description: 'Deploy APIs for real time predictions'
    }
  ];

  return (
    <Box sx={{
      width: "100%",
      height: "auto",
      display: "flex",
      justifyContent: "center",
      paddingTop: { xs: "30px", md: "100px" },
      background: 'linear-gradient(to bottom, #2e1866, #343538)',
      paddingBottom: "180px", // Add padding for smaller screens
    }}>
      <Box sx={{
        width: { xs: "90%", md: "80%" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: "30px",
        alignItems: { xs: "center", md: "flex-start" }, // Center content on smaller screens
      }}>
        <Box sx={{ flex: 1, textAlign: 'left' }}>
          <Typography variant='h3' fontWeight={700} color={"white"} sx={{ fontSize: { xs: '7.5vw', sm: '36px', md: '48px' } }}>
            Transform your business <br />by leveraging AI <br />No data science team needed
          </Typography>
          <Typography variant='h5' fontWeight={300} color={"white"} sx={{ marginTop: "20px", fontSize: { xs: '18px', sm: '20px', md: '24px' } }}>
            Upload, transform and annotate your data, create predictive models, collaborate with your teams, and deploy APIs for real-time predictions. Don't have a data science team? Our dedicated experts will help you with anything you need to build cutting-edge AI models.
          </Typography>
          <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: {xs:"10px", md:"20px"},
                marginTop: "50px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  width: {xs:"100%", md:"200px"},
                  fontSize: "12pt",
                  backgroundColor: "#686dba",
                  ":hover": { backgroundColor: "#383A6E" },
                }}
                onClick={() => navigate("/pricing")}
              >
                Try it for Free
              </Button>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  width: {xs:"100%", md:"200px"},
                  fontSize: "12pt",
                  color: theme.palette.text.light,
                  borderColor: "#686dba",
                  ":hover": { backgroundColor: "#686dba", borderColor: "#686dba" },
                }}
                onClick={() => navigate("/request_demo")}
              >
                See it in Action
              </Button>
            </Box>
        </Box>
        <Box sx={{ flex: 1, width: { xs: '100%', md: 'auto' } }}>
          <ImageCarousel items={items} />
        </Box>
      </Box>
    </Box>
  );
}

export default Welcome;
