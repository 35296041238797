import { Analytics, History, Javascript, PriorityHighTwoTone } from '@mui/icons-material'
import { Box, Chip, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { BASE_URL, api, apiLog } from '../apis/urls';
import { getRequest } from '../apis/requests';
import CodeBox from "../components/api/CodeBox";
import { javascriptCodeAsString, javascriptImageClassificationCodeAsString, javascriptObjectDetectionCodeAsString, pythonCodeAsString, pythonImageClassificationCodeAsString, pythonObjectDetectionCodeAsString } from '../utils';

const IntegrationDetails = ({apiData}) => {

    const theme = useTheme();

    const [fields, setFields] = useState([]);
    const [codeStringJavascript, setCodeStringJavascript] = useState("");
    const [codeStringPython, setCodeStringPython] = useState("");

    const [currentCodeTabIndex, setCurrentCodeTabIndex] = React.useState(0);

    const handleCodeTabChange = (e, tabIndex) => {
        setCurrentCodeTabIndex(tabIndex);
    }

    useEffect(() => {
        let fieldsList = []
        if (["classification", "regression"].includes(apiData["model"]["target_type"])){
            Object.keys(apiData["model"]["prediction_column_options"]).map(key => {
                let typeVal = "";
                if (typeof apiData["model"]["prediction_column_options"][key] == "object"){
                    typeVal = "string";
                }
                else if (typeof apiData["model"]["prediction_column_options"][key] == "number"){
                    typeVal = "number";
                }
                else {
                    typeVal = "other";
                }
                fieldsList.push({
                    "key": key,
                    "value": typeVal,
                    "example": apiData["model"]["prediction_column_options"][key].length ? (apiData["model"]["prediction_column_options"][key].join("; ")) : (apiData["model"]["prediction_column_options"][key])
                })
            })
            setCodeStringJavascript(javascriptCodeAsString(apiData["id"], apiData["token"], apiData["model"]["prediction_column_options"]));
            setCodeStringPython(pythonCodeAsString(apiData["id"], apiData["token"], apiData["model"]["prediction_column_options"]));
        }
        else {
            fieldsList = [
                {"key":"images", "value": "Image File(s)", "example": "2 files selected"}]

            if (apiData["model"]["target_type"] == "object_detection") {
                setCodeStringJavascript(javascriptObjectDetectionCodeAsString(apiData["id"], apiData["token"]));
                setCodeStringPython(pythonObjectDetectionCodeAsString(apiData["id"], apiData["token"]));

                fieldsList.push({"key":"conf (optional)", "value": "number", "example": "0.25"});
                fieldsList.push({"key":"iou (optional)", "value": "number", "example": "0.75"});
            }
            else {
                setCodeStringJavascript(javascriptImageClassificationCodeAsString(apiData["id"], apiData["token"]));
                setCodeStringPython(pythonImageClassificationCodeAsString(apiData["id"], apiData["token"]));
            }
        }
        setFields(fieldsList);
    }, []);
    
    return (
        <Box sx={{display:"flex", flexDirection:"column", padding:"20px", gap:"20px"}}>
            <Box sx={{display:"flex", 
                      flexDirection:"column", 
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>{apiData["name"]} Integration Details</Typography>
                
                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px",
                    marginTop:"20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>API Details</Typography>
                    <Box sx={{display:"flex", 
                                flexDirection:"column", 
                                backgroundColor:theme.palette.background.default, 
                                borderRadius:"5px",
                                marginTop:"10px",
                                padding:"10px"}}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>API Url:</TableCell>
                                        <TableCell>{["classification", "regression"].includes(apiData["model"]["target_type"]) ? `${BASE_URL}/api/make_prediction/` : `${BASE_URL}/api/make_image_prediction/`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>ID (api_id):</TableCell>
                                        <TableCell>{apiData["id"]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Token (api_token):</TableCell>
                                        <TableCell>{apiData["token"]}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>

                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px",
                    marginTop:"20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Fields must be included in POST request</Typography>
                    <Box sx={{display:"flex", 
                                flexDirection:"column", 
                                backgroundColor:theme.palette.background.default, 
                                borderRadius:"5px",
                                marginTop:"10px",
                                padding:"10px",
                                maxHeight:"300px"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Field Name</TableCell>
                                        <TableCell>Data Type</TableCell>
                                        <TableCell>Example</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields ? (
                                        fields.map(field => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{field.key}</TableCell>
                                                    <TableCell>{field.value}</TableCell>
                                                    <TableCell>{field.example}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>

                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px",
                    marginTop:"20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Example POST request in different languages</Typography>
                    <Box sx={{display:"flex", 
                                        flexDirection:"column", 
                                        backgroundColor:theme.palette.background.default, 
                                        borderRadius:"5px",
                                        marginTop:"10px",
                                        padding:"10px"}}>
                        <React.Fragment>
                            <Tabs value={currentCodeTabIndex} onChange={handleCodeTabChange} >
                                <Tab label='Javascript' className='left' />
                                <Tab label='Python' className='right' />
                            </Tabs>
                    
                            {currentCodeTabIndex === 0 && (
                                <CodeBox codeString={codeStringJavascript} language={"javascript"} />
                            )}
                        
                            {currentCodeTabIndex === 1 && (
                                <CodeBox codeString={codeStringPython} language={"python"} />
                            )}
                        </React.Fragment>
                    </Box>
                </Box>

                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px",
                    marginTop:"20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Expected responses</Typography>
                    <Box sx={{display:"flex", 
                                flexDirection:"column", 
                                backgroundColor:theme.palette.background.default, 
                                borderRadius:"5px",
                                marginTop:"10px",
                                padding:"10px"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Response Type</TableCell>
                                        <TableCell>HTTP Status</TableCell>
                                        <TableCell>Data (response.json)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><Chip label={"Successful"} color='success' /></TableCell>
                                        <TableCell>200 OK</TableCell>
                                        <TableCell>
                                            {apiData["model"]["target_type"] == "classification" ? (
                                                <CodeBox codeString={`[{"prediction": "predicted_class", "probability": 0.96}]`} language={"javascript"} />
                                            ) : (
                                                apiData["model"]["target_type"] == "regression" ? (
                                                    <CodeBox codeString={`[{"prediction": "predicted_value"}]`} language={"javascript"} />
                                                ) : (
                                                    apiData["model"]["target_type"] == "image_classification" ? (
                                                        <CodeBox codeString={`{"predicted_images": [{"name": "image_name", "class": "predicted_class", "confidence": "96.55%", "image_buffer": "image_as_buffer"}]}`} language={"javascript"} />
                                                    ) : (
                                                        apiData["model"]["target_type"] == "object_detection" ? (
                                                            <CodeBox codeString={`{"predicted_images": [{"name": "image_name", "boxes": [{"class": "predicted_class", "coordinates": ["top_left_x", "top_left_y", "bottom_right_x", "bottom_right_y"], "confidence": "96.55%" }], "image_buffer": "image_as_buffer"}]}`} language={"javascript"} />
                                                        ) : (<></>)
                                                    )
                                                )
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><Chip label={"Failed"} color='error' /></TableCell>
                                        <TableCell>400 BAD REQUEST</TableCell>
                                        <TableCell><CodeBox codeString={`{'error': 'Error detail'}`} language={"javascript"} /></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const ApiHistory = ({apiId, apiName}) => {
    const theme = useTheme();
    const authToken = localStorage.getItem('authToken');
    const [apiLogData, setApiLogData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchApi();
    }, [apiId]);

    const fetchApi = () => {
        setLoading(true);
        const url = apiLog.getByApiId(apiId);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                if(data["error"]){
                    setApiLogData(null);
                }
                else {
                    setApiLogData(data);
                }
                setLoading(false);
        })
    };

    return (
        <Box sx={{display:"flex", flexDirection:"column", padding:"20px", gap:"20px"}}>
            <Box sx={{display:"flex", 
                      flexDirection:"column", 
                      padding:"20px",
                      border: "1px solid gray",
                      borderRadius: "5px"}}>
                <Typography variant='h6'>{apiName} History</Typography>
                
                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px",
                    marginTop:"20px",
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Api Request Logs</Typography>
                    
                    <Box sx={{display:"flex", 
                                flexDirection:"column", 
                                backgroundColor:theme.palette.background.default, 
                                borderRadius:"5px",
                                marginTop:"10px",
                                padding:"10px",
                                maxHeight:"500px"}}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Request Date - Time</TableCell>
                                        <TableCell>Client IP</TableCell>
                                        <TableCell>Response Status</TableCell>
                                        <TableCell>Reponse Details</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {apiLogData.map(item => {
                                        return (
                                            <TableRow>
                                                <TableCell>{item["requested_at"]}</TableCell>
                                                <TableCell>{item["client_ip"]}</TableCell>
                                                <TableCell><Chip label={item["response_status"]} color={item["response_status"] == "200" ? 'success' : "error"} /></TableCell>
                                                <TableCell>{item["response_details"]}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const ApiOverview = () => {

    const authToken = localStorage.getItem('authToken');
    const location = useLocation();
    const api_id = location.state["api_id"];

    const [apiData, setApiData] = useState(null);

    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    }

    useEffect(() => {
        fetchApi();
    }, [api_id]);

    const fetchApi = () => {
        const url = api.getByApiId(api_id);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setApiData(data);
        })
    };

  return (
    <Box sx={{display:"flex", flexDirection:"column", width:"100%"}}>
        {
            apiData ? (
                <React.Fragment>
                    <Tabs value={currentTabIndex} onChange={handleTabChange} >
                        <Tab label='Integration Details' className='left' icon={<Analytics />}  iconPosition='start' />
                        <Tab label='History' className='right' icon={<History />} iconPosition='start' />
                    </Tabs>
                
                    {/* Overview Tab */}
                    {currentTabIndex === 0 && (
                        <IntegrationDetails apiData={apiData} />
                    )}
                
                    {/* Details Tab */}
                    {currentTabIndex === 1 && (
                        <ApiHistory apiId={api_id} apiName={apiData["name"]} />
                    )}
                </React.Fragment>
            ) : (
                <>

                </>
            )
        }
    </Box>
  )
}

export default ApiOverview