import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import businessProblemsImage from "../../assets/images/business_problems.svg";
import dataCollectionImage from "../../assets/images/data_collection.svg";
import dataAnalysisImage from "../../assets/images/data_analysis.svg";
import modelTrainingImage from "../../assets/images/model_training.png";
import integrationImage from "../../assets/images/integration.svg";
import reportingImage from "../../assets/images/reporting.svg";

const steps = [
  {
    number: 1,
    title: "Defining Business Problems",
    description: "Collaborating with you to understand and articulate your business challenges and objectives.",
    image: businessProblemsImage,
  },
  {
    number: 2,
    title: "Data Collection and Preparation",
    description: "Gathering and cleaning data from various sources to ensure accuracy and usability.",
    image: dataCollectionImage,
  },
  {
    number: 3,
    title: "Exploratory Data Analysis",
    description: "Identifying patterns, trends, and insights from your data to inform decision-making.",
    image: dataAnalysisImage,
  },
  {
    number: 4,
    title: "Model Development and Training",
    description: "Building and training custom AI and machine learning models tailored to your specific needs.",
    image: modelTrainingImage,
  },
  {
    number: 5,
    title: "Implementation and Integration",
    description: "Deploying models and solutions into your existing systems for seamless integration.",
    image: integrationImage,
  },
  {
    number: 6,
    title: "Reporting and Visualization",
    description: "Creating interactive dashboards and automated reports for real-time insights.",
    image: reportingImage,
  },
];

const HowItWorks = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "-175px",
        paddingTop: "200px",
        paddingBottom: "100px",
        backgroundColor: theme.palette.background.lighter,
        
      }}
    >
      <Typography
        variant="h3"
        fontWeight={400}
        textAlign="center"
        sx={{ fontSize: { xs: "6vw", md: "3vw" } }}
      >
        How Our Data Scientists Will Work with You
      </Typography>
      <Typography
        variant="h5"
        fontWeight={300}
        color="textSecondary"
        marginTop="20px"
        textAlign="center"
        sx={{ fontSize: { xs: "5vw", md: "1.5vw" } }}
      >
        Our expert data scientists will handle anything from your business problems to continuous optimization.
      </Typography>
      <Box
        sx={{
          width: { xs: "90%", md: "80%" },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: "50px",
          gap: "30px", // Adds space between steps
        }}
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: index % 2 === 0 ? 'row' : 'row-reverse' },
              alignItems: 'center',
              width: {xs:"100%", md:"70%"},
              gap: {xs:"20px", md:"0"},
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xs: "80px", md: "200px" },
                height: { xs: "80px", md: "200px" },
                border: "2px solid black",
                borderRadius: "50%",
                backgroundColor: "white",
                zIndex: 1,
                flexShrink: 0, // Prevents the circle from shrinking
              }}
            >
              <img src={step.image} alt={step.title} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }} />
            </Box>
            <Box
              sx={{
                flex: 1,
                height:{xs:"auto", md:"150px"},
                borderRadius: "20px",
                border: "2px solid black",
                padding: { xs: "15px", md: "20px" },
                paddingRight: {xs: "0", md: index % 2 === 0 ? '30px' : '100px'},
                paddingLeft: {xs: "0", md: index % 2 === 0 ? '120px' : '30px'},
                marginRight: {xs: "0", md: index % 2 === 0 ? '0px' : '-100px'},
                marginLeft: {xs: "0", md: index % 2 === 0 ? '-100px' : '0px'},
                boxSizing: "border-box",
                backgroundColor: "white",
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography variant="h5" sx={{ marginTop: 1 }}>
                Step {step.number}: {step.title}
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1, fontWeight: 300, fontSize: { xs: "12pt", md: "14pt" } }}>
                {step.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HowItWorks;
