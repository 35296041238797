import { Copyright, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import utienceLogo from "../../assets/images/utience_logo_secondary.svg";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.text.light,
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "60%" },
          height: { xs: "450px", md: "350px" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          textAlign: "center",
          marginTop: "100px",
          boxSizing: "border-box",
          padding: "30px",
          border: "2px solid #686dba",
          borderRadius: "20px",
          backgroundColor: "#383A6E",
        }}
      >
        <Typography
          variant="h3"
          fontSize={{ xs: "24pt", md: "32pt" }}
          fontWeight={400}
        >
          Get Started Now
        </Typography>
        <Typography variant="h5" fontWeight={300} fontSize={{ xs: "14pt", md: "18pt" }}>
          Unlock AI insights from your data with Utimind No-Code AI Platform.
          Effortlessly upload, analyze, and create predictive models. Start
          making data-driven decisions today!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: {xs:"20px", md:"40px"},
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "200px",
              fontSize: "14pt",
              backgroundColor: "#686dba",
              ":hover": { backgroundColor: "#383A6E" },
            }}
            onClick={() => navigate("/pricing")}
          >
            Try it for Free
          </Button>
          <Button
            variant="outlined"
            sx={{
              textTransform: "none",
              width: "200px",
              fontSize: "14pt",
              color: theme.palette.text.light,
              borderColor: "#686dba",
              ":hover": { backgroundColor: "#686dba", borderColor: "#686dba" },
            }}
            onClick={() => navigate("/request_demo")}
          >
            Book a Call
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", md: "60%" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "30px", md: "50px" },
          marginTop: "100px",
          alignItems: { xs: "center", md: "flex-start" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box
          sx={{
            flex: 3,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            marginRight: { md: "50px" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box sx={{ marginTop: "-25px" }}>
            <img src={utienceLogo} style={{ height: "80px", width: "auto" }} />
          </Box>
          <Typography fontWeight={300}>
            No-code AI platform that transforms your data into valuable insights.
          </Typography>
          <Typography fontWeight={300}>
            © 2024 Utience Technology Inc.
          </Typography>
          <Typography fontWeight={300}>
            <Link to="/terms" style={{ color: theme.palette.text.light }}>
              Terms
            </Link>{" "}
            &{" "}
            <Link to="/privacy" style={{ color: theme.palette.text.light }}>
              Privacy
            </Link>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Twitter sx={{ width: "40px", height: "40px", cursor: "pointer" }} />
            <LinkedIn sx={{ width: "40px", height: "40px", cursor: "pointer" }} />
            <YouTube sx={{ width: "40px", height: "40px", cursor: "pointer" }} />
          </Box>
        </Box>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography variant="h6">Utimind</Typography>
          <Typography variant="body1" fontWeight={300}>
            Product
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Service
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Pricing
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Demo
          </Typography>
          
        </Box>
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography variant="h6">Use Cases</Typography>
          <Typography variant="body1" fontWeight={300}>
            Manufacturing
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Finance
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Retail
          </Typography>
          <Typography variant="body1" fontWeight={300}>
            Healthcare
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
