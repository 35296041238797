import React from 'react';
import { Container, Box, Typography, Grid, useTheme, Chip, Paper } from '@mui/material';
import buildModelsImage from "../../assets/images/error.png";
import dataTransformationImage from "../../assets/images/data-transformation.png";
import imageAnnotationImage from "../../assets/images/object.png";
import realTimePredictionsImage from "../../assets/images/api.png";
import collaborationImage from "../../assets/images/teamwork.png";
import scalabeImage from "../../assets/images/maximize.png";

const features = [
  {
    title: "No-Code Platform",
    header: "Build AI Models Quickly!",
    description: "Easily create AI models without any programming skills. Our user-friendly platform makes it simple for anyone to get started with AI.",
    image: buildModelsImage,
    objPosition: "50% 50%",
    color: "#AEDFF7"
  },
  {
    title: "Data Transformation",
    header: "Make Your Data Useful!",
    description: "Clean and organize your data effortlessly. Get it ready for AI analysis without needing to be a data expert.",
    image: dataTransformationImage,
    objPosition: "50% 50%",
    color: "#C8E6C9"
  },
  {
    title: "Image Annotation",
    header: "Label Images Easily!",
    description: "Quickly add labels to images to help AI understand them. Perfect for tasks like identifying objects in photos.",
    image: imageAnnotationImage,
    objPosition: "50% 40%",
    color: "#FFF9C4"
  },
  {
    title: "Real-Time Predictions",
    header: "Get Instant Answers!",
    description: "Use AI to make predictions and get answers in real time. Great for making quick, informed decisions.",
    image: realTimePredictionsImage,
    objPosition: "50% 50%",
    color: "#F8BBD0"
  },
  {
    title: "Collaboration Tools",
    header: "Work Together Easily!",
    description: "Share your AI projects with your team and collaborate effortlessly. Everyone can contribute, no matter their skill level.",
    image: collaborationImage,
    objPosition: "50% 25%",
    color: "#E1BEE7"
  },
  {
    title: "Scalable Solutions",
    header: "Grow With Confidence!",
    description: "As your business grows, our platform grows with you. Handle more data and more users without any hassle.",
    image: scalabeImage,
    objPosition: "50% 50%",
    color: "#FFE0B2"
  }
];

const Product = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingY: "100px",
        backgroundColor: theme.palette.background.lighter,
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "80%" }, display: "flex", flexDirection: "column" }}>
        <Container sx={{ mt: 4 }}>
          <Typography variant='h3' fontWeight={400} textAlign="center" sx={{ fontSize: { xs: '7.5vw', sm: '7.5vw', md: '48px' } }}>
            Product Features
          </Typography>
          <Grid container spacing={4} marginTop={{xs:"10px", md:"50px"}}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: { xs: "20px 20px", md: "20px 20px" },
                    textAlign: 'left',
                    backgroundColor: 'white',
                    borderRadius: "10px",
                    height: { xs: 'auto', md: 220 }, // Responsive height
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box sx={{display:"flex", width:"100%", justifyContent:"space-between"}}>
                    <Box sx={{ width: { xs: "40px", md: "50px" }, height: { xs: "40px", md: "50px" }, backgroundColor:feature.color, borderRadius:"50%", padding:"10px", marginBottom: '10px' }}>
                      <img src={feature.image} alt={feature.title} style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: feature.objPosition }} />
                    </Box>
                    <Chip label={feature.title} sx={{ backgroundColor: feature.color, fontSize: { xs: "10pt", md: "12pt" }, marginBottom: "20px" }} />
                  </Box>
                  <Typography sx={{ marginBottom: 1, fontSize: { xs: "14pt", md: "18pt" } }}>
                    {feature.header}
                  </Typography>
                  <Typography variant="h7" fontWeight={300} sx={{ color: '#555555', fontSize: { xs: "12pt", md: "14pt" } }}>
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Product;
