import styled from '@emotion/styled';
import { Alert, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, ImageList, ImageListItem, Snackbar, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { getRequest, putRequest } from '../apis/requests';
import { BASE_URL, imageDataset } from '../apis/urls';
import ImageCanvas from '../components/imageDataset/ImageCanvas';
import LabelPanel from '../components/imageDataset/LabelPanel';
import { useSelector, useDispatch } from 'react-redux';
import { setImageData } from "../store/imageDataSlice"
import { setLabelOptions } from "../store/labelOptionsSlice"
import AddLabelModal from '../components/imageDataset/AddLabelModal';
import RemoveLabelModal from '../components/imageDataset/RemoveLabelModal';
import EditLabelModal from '../components/imageDataset/EditLabelModal';
import { Approval, Beenhere } from '@mui/icons-material';
import PolygonCanvas from '../components/imageDataset/PolygonCanvas';

const PageContainer = styled(Box)(( { theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 30,
}));

const taskTypesDict = {
    "image_classification": "IMAGE CLASSIFICATION",
    "object_detection": "OBJECT DETECTION",
    "image_segmentation": "IMAGE SEGMENTATION",
}


const ImageDatasetOverview = () => {
    const authToken = localStorage.getItem("authToken");
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.imageData);
    const labelOptions = useSelector((state) => state.labelOptions);
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const image_dataset_id = location.state["image_dataset_id"];
    const image_dataset_name = location.state["image_dataset_name"];
    const image_dataset_task_type = location.state["image_dataset_task_type"]
    const [isAddLabelModalOpen, setIsAddLabelModalOpen] = useState(false);
    const [isRemoveLabelModalOpen, setIsRemoveLabelModalOpen] = useState(false);
    const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);

    const [thumbnails, setThumbnails] = useState([]);

    const handleImageClick = (image) => {
        thumbnails.map((thumbnail, index) => {
            if (thumbnail["id"] == image["id"]){
                setSelectedImageIndex(index);
            }
        })
        fetchSelectedImage(image);
        setSelectedImageId(image["id"]);
    };

    const handleClose = () => {
        dispatch(setImageData({ url: '', datasetLabel: null, labels: [], actualHeight: null, actualWidth: null, aspectRatio: null, annotated: false }));
        setSelectedImageId(null);
        setSelectedImageIndex(null);
    };

    const handleSave = () => {
        const url = imageDataset.setLabels(selectedImageId);
        const headers = {
            "Authorization": authToken,
            "Content-Type": "application/json"
        }
        const body = JSON.stringify({
            dataset_label: imageData.imageData.datasetLabel,
            labels: imageData.imageData.labels,
            aspect_ratio: imageData.imageData.aspectRatio
        })
        putRequest(url, headers, body)
        .then(response => {
            if (response.ok){
                handleSnackbarOpen("success", "Successfully saved.");
            }
            else{
                handleSnackbarOpen("error", "An error occurred.");
            }
        })
    }

    const handleMarkAnnotated = () => {
        const url = imageDataset.markAnnotated(selectedImageId);
        const headers = {
            "Authorization": authToken,
        }
        putRequest(url, headers)
            .then(response => {
                if (response.ok){
                    fetchThumbnails();
                    handleSnackbarOpen("success", "Successfully set as completed.");
                    dispatch(setImageData({...imageData.imageData, annotated: true}));
                }
                else{
                    handleSnackbarOpen("error", "An error occurred.");
                }
            })
    }

    const handleMarkNotAnnotated = () => {
        const url = imageDataset.markNotAnnotated(selectedImageId);
        const headers = {
            "Authorization": authToken,
        }
        putRequest(url, headers)
            .then(response => {
                if (response.ok){
                    fetchThumbnails();
                    handleSnackbarOpen("success", "Successfully set as not completed.");
                    dispatch(setImageData({...imageData.imageData, annotated: false}));
                }
                else{
                    handleSnackbarOpen("error", "An error occurred.");
                }
            })
    }

    const handleNext = () => {
        thumbnails.map((thumbnail, index) => {
            if (index == selectedImageIndex + 1){
                setSelectedImageIndex(selectedImageIndex + 1);
                fetchSelectedImage(thumbnail);
                setSelectedImageId(thumbnail["id"]);
            }
        })
    }

    const handlePrev = () => {
        thumbnails.map((thumbnail, index) => {
            if (index == selectedImageIndex - 1){
                setSelectedImageIndex(selectedImageIndex - 1);
                fetchSelectedImage(thumbnail);
                setSelectedImageId(thumbnail["id"]);
            }
        })
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("Message");
    
    const handleSnackbarOpen = (severity, message) => {
        setSnackbarSeverity(severity);
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

    useEffect(() => {
        fetchLabelOptions();
        fetchThumbnails();
    }, []);

    const fetchLabelOptions = () => {
        const url = imageDataset.getLabelOptions(image_dataset_id);
        const headers = {
            "Authorization": authToken
        }
        getRequest(url, headers)
            .then(data => {
                dispatch(setLabelOptions(data));
            })
    }

    const fetchThumbnails = () => {
        const url = imageDataset.getThumbnailsByDatasetId(image_dataset_id);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                setThumbnails(data["thumbnails"]);
            })
    }

    const fetchSelectedImage = (image) => {
        const url = imageDataset.getImageById(image["id"]);
        getRequest(url, {"Authorization": authToken})
            .then(data => {
                dispatch(setImageData(
                    {  
                        url: data["image"],
                        datasetLabel: data["dataset_label"],
                        labels: data["labels"],
                        annotated: data["annotated"],
                        actualHeight: data["height"],
                        aspect_ratio: null,
                        actualWidth: data["width"]
                    }
                ));
            });
    }

    const handleOpenAddLabelModal = () => {
        setIsAddLabelModalOpen(true);
    };

    const handleCloseAddLabelModal = () => {
        setIsAddLabelModalOpen(false);
    };

    const handleSaveLabel = (label) => {
        const labelOptionsUpdated = [...labelOptions.labelOptions, label];
        saveLabelOptions(labelOptionsUpdated);
    };

    const handleOpenRemoveLabelModal = () => {
        setIsRemoveLabelModalOpen(true);
    };

    const handleCloseRemoveLabelModal = () => {
        setIsRemoveLabelModalOpen(false);
    };

    const handleRemoveLabel = (labelName) => {
        const labelOptionsUpdated = labelOptions.labelOptions.filter(label => label.name !== labelName);
        saveLabelOptions(labelOptionsUpdated);
    };

    const handleOpenEditLabelModal = () => {
        setIsEditLabelModalOpen(true);
    };

    const handleCloseEditLabelModal = () => {
        setIsEditLabelModalOpen(false);
    };

    const handleEditLabel = ({ oldName, newName, color }) => {
        const labelOptionsUpdated = labelOptions.labelOptions.map(label => label.name === oldName ? { name: newName, color } : label);
        saveLabelOptions(labelOptionsUpdated);
    };

    const saveLabelOptions = (labelOptionsUpdated) => {
        dispatch(setLabelOptions(labelOptionsUpdated));
        const url = imageDataset.setLabelOptions(image_dataset_id);
        const headers = {
            "Authorization": authToken,
            "Content-Type": "application/json"
        }
        const body = JSON.stringify({
            label_options: labelOptionsUpdated
        });
        putRequest(url, headers, body)
            .then(response => {
                if(response.ok) {
                    handleSnackbarOpen("success", "Successfully saved.");
                }
                else {
                    handleSnackbarOpen("error", "An error occurred.");
                }
            })
    }

  return (
    <Box sx={{ width: "97%" }}>
        <Typography variant='h5'>{`${image_dataset_name} Image Dataset`}</Typography>
        <PageContainer>
            <Box sx={{width: "100%", 
                     height: "150px",
                     display:"flex",
                     flexDirection:"row",
                     borderRadius: "5px",
                     gap:"10px"}}>
                <Box sx={{flex:2, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px", 
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    <Typography>Labels</Typography>
                    <Box sx={{ 
                            backgroundColor:theme.palette.background.default,
                            height:"100px",
                            borderRadius:"5px",
                            marginTop:"10px",
                            padding:"5px 10px",
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent:"flex-start",
                            alignItems:"flex-start",
                            gap:"10px",
                            overflowY:"scroll"}}>
                        {labelOptions.labelOptions.map(label => {
                            return (
                                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px", borderRadius:"20px", padding:"5px 10px", backgroundColor:theme.palette.background.light}}>
                                    <Typography sx={{fontWeight:100}}>{label["name"]}</Typography>
                                    <Box sx={{height:"20px", width:"20px", backgroundColor:`${label["color"]}`}}></Box>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <Box sx={{flex:1, 
                    display:"flex", 
                    flexDirection:"column", 
                    padding:"10px", 
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px",
                    gap:"5px",
                    justifyContent:"center"}}>
                    <Button variant="contained" color="primary" onClick={handleOpenAddLabelModal}>
                        Add Label
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleOpenRemoveLabelModal}>
                        Remove Label
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleOpenEditLabelModal}>
                        Edit Label
                    </Button>
                </Box>
                <Box sx={{flex:4, 
                    display:"flex", 
                    flexDirection:"column",
                    justifyContent:"center",
                    padding:"10px", 
                    backgroundColor:theme.palette.background.lighter,
                    border:"1px solid gray",
                    borderRadius:"5px"}}>
                    {thumbnails ? (
                        <>
                            <Typography fontSize={"14pt"}><b>Image Dataset:</b> {image_dataset_name}</Typography>
                            <Typography fontSize={"14pt"}><b>Task Type:</b> {taskTypesDict[image_dataset_task_type]}</Typography>
                            <Typography fontSize={"14pt"}><b>Number of Images:</b> {thumbnails.length}</Typography>
                            <Typography fontSize={"14pt"}><b>Annotation Progress:</b> {thumbnails.filter(item => item["annotated"]).length} / {thumbnails.length}</Typography>
                        </>
                    ) : (
                        <CircularProgress />
                    )}
                </Box>
            </Box>
            <Box>
                <ImageList variant="masonry" cols={10} gap={8} sx={{padding:"20px 0px"}}>
                    {thumbnails.map((thumbnail) => (
                        <ImageListItem key={thumbnail["id"]} style={{ cursor: 'pointer', width: '100%', height: 'auto' }} onClick={() => handleImageClick(thumbnail)}>
                            <img
                                src={`${BASE_URL}${thumbnail["thumbnail_url"]}`}
                                alt={thumbnail["name"]}
                                loading="lazy"
                            />
                            {thumbnail["annotated"] ? (
                                <Beenhere sx={{position:"absolute", top:0, right:0, width:"30px", height:"30px", color:"green"}} />
                            ) : (
                                <></>
                            )}
                            <Typography sx={{fontSize:"9pt", fontWeight:100, color:"gray", textAlign:"center"}}>{thumbnail["name"]}</Typography>
                        </ImageListItem>
                    ))}
                </ImageList>

                {imageData.imageData && imageData.imageData.url !== "" ? (
                    <Dialog open={imageData.imageData.url !== ""} onClose={handleClose} maxWidth="xl" fullWidth PaperProps={{
                        sx: {
                          height: "900px"
                        }
                      }}
                      >
                        <DialogContent>
                            <Box sx={{display:"flex", flexDirection:"row"}}>
                                <Box>
                                    {image_dataset_task_type == "image_segmentation" ? (
                                        <PolygonCanvas />
                                    ) : (
                                        <ImageCanvas taskType={image_dataset_task_type} />
                                    )}
                                </Box>
                                {image_dataset_task_type == "object_detection" || image_dataset_task_type == "image_segmentation" ? (
                                    <Box>
                                        <LabelPanel />
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePrev} variant='contained' color="primary">Prev</Button>
                            <Button onClick={handleClose} variant='contained' color="error">Close</Button>
                            <Button onClick={handleSave} variant='contained' color="info">Save</Button>
                            {imageData.imageData.annotated ? (
                                <Button onClick={handleMarkNotAnnotated} variant='contained' sx={{backgroundColor:"#e08104"}}>Mark Not Completed</Button>
                            ) : (
                                <Button onClick={handleMarkAnnotated} variant='contained' color="success">Mark Completed</Button>
                            )}
                            <Button onClick={handleNext} variant='contained' color="primary">Next</Button>
                        </DialogActions>
                    </Dialog>
                ) : (
                    <></>
                )}
                
            </Box>
        </PageContainer>
        <AddLabelModal open={isAddLabelModalOpen} onClose={handleCloseAddLabelModal} onSave={handleSaveLabel} />
        <RemoveLabelModal open={isRemoveLabelModalOpen} onClose={handleCloseRemoveLabelModal} onRemove={handleRemoveLabel} labels={labelOptions.labelOptions} />
        <EditLabelModal open={isEditLabelModalOpen} onClose={handleCloseEditLabelModal} onSave={handleEditLabel} labels={labelOptions.labelOptions} />
        <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ImageDatasetOverview