import { Box, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'

const Arrow = ({ length, direction = "right" }) => {
  const arrowStyle = {
    display: "block",
    width: 400
  };

  const lineProps = direction === "right"
    ? { x1: 200, y1: "10", x2: 200 + length, y2: "10" }
    : direction === "left" ? { x1: 200, y1: "10", x2: 200 - length, y2: "10" }
    : { x1: 200 + length / 2, y1: "10", x2: 200 - length /2, y2: "10" };

  const polygonPoints = direction === "right"
    ? `${200 + length},5 ${200 + length + 10},10 ${200 + length},15`
    : direction === "left" ? `${200 - length},5 ${200 - length - 10},10 ${200 - length},15`
    : `0,0 0,0 0,0`

  const color = direction === "right"
    ? "green"
    : direction === "left" ? "red"
    : "black";

  return (
    <svg
      width={length + 10}
      height="20"
      style={arrowStyle}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        {...lineProps}
        stroke={color}
        strokeWidth="5"
      />
      <polygon
        points={polygonPoints}
        fill={color}
      />
    </svg>
  );
};

const Effects = ({modelData}) => {

    const theme = useTheme();
    const effects = modelData["effects"];

    const [column, setColumn] = useState(Object.keys(effects)[0]);
    const [class_, setClass_] = useState(Object.keys(effects[Object.keys(effects)[0]][Object.keys(effects[Object.keys(effects)[0]])[0]])[0]);
    const [options, setOptions] = useState(effects[Object.keys(effects)[0]]);

  return (
    <Box sx={{display:"flex", flexDirection:"row", gap:"20px", marginTop:"20px"}}>
        <Box sx={{flex:1, 
            display:"flex", 
            flexDirection:"column", 
            padding:"10px", 
            backgroundColor:theme.palette.background.lighter,
            border:"1px solid gray",
            borderRadius:"5px"}}>
            <Typography>Key Predictors</Typography>
            <Typography variant='subtitle2' fontWeight={300} fontSize={"11pt"}>Choose a feature to see how it affects the prediction outcome.</Typography>
            <Box sx={{display:"flex", 
                        flexDirection:"column", 
                        backgroundColor:theme.palette.background.default, 
                        borderRadius:"5px",
                        marginTop:"10px",
                        padding:"10px",
                        height:"100%"}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px", boxSizing:"border-box"}}>
                                            <Typography>When</Typography>
                                            <Select
                                                sx={{height:"24pt"}}
                                                value={column}
                                                onChange={(event) => {
                                                    setColumn(event.target.value)
                                                    setOptions(effects[event.target.value]);
                                                }}>
                                                {Object.keys(effects).map(col => {
                                                    return (
                                                        <MenuItem value={col}>{col}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {modelData["target_type"] == "classification" ? (
                                            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"10px", boxSizing:"border-box"}}>
                                                <Typography>Likelihood of {modelData["target_feature"]} being</Typography>
                                                <Select
                                                    sx={{height:"24pt"}}
                                                    value={class_}
                                                    onChange={(event) => {
                                                        setClass_(event.target.value)
                                                    }}>
                                                    {Object.keys(effects[Object.keys(effects)[0]][Object.keys(effects[Object.keys(effects)[0]])[0]]).map(cls => {
                                                        return (
                                                            <MenuItem value={cls}>{cls}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Box>
                                        ) : (
                                            <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", gap:"10px", boxSizing:"border-box", width:400,}}>
                                                <Typography>{modelData["target_feature"]}</Typography>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(options).map(opt => {
                                    let ratio = 0;
                                    let change = 0;
                                    let maxAbs = -1;
                                    if (modelData["target_type"] == "classification") {
                                        ratio = (100 * (options[opt][class_] - 1.0)).toFixed(2);
                                    }
                                    else {
                                        change = options[opt][modelData["target_feature"]].toFixed(2);
                                        Object.keys(options).map(key => {
                                            if (Math.abs(options[key][modelData["target_feature"]]) > maxAbs){
                                                maxAbs = Math.abs(options[key][modelData["target_feature"]]);
                                            }
                                        })
                                    }
                                    
                                    return (
                                        <TableRow>
                                            <TableCell>{opt}</TableCell>
                                            <TableCell>
                                                {modelData["target_type"] == "classification" ? (
                                                    <Box>
                                                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:400}}>
                                                            {Math.abs(ratio)}% {ratio > 0 ? "increase" : ratio < 0 ? "decrease" : "no change"}
                                                        </Box>
                                                        {ratio > 0 ? (
                                                            <Arrow length={(Math.abs(ratio) * 5) + 5} direction='right'/>
                                                        ) : (ratio < 0 ? (
                                                            <Arrow length={(Math.abs(ratio) * 5) + 5} direction='left'/>
                                                        ) : (
                                                            <Arrow length={10} direction='up' />
                                                        ))} 
                                                    </Box>
                                                ) : (
                                                    <Box>
                                                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", width:400}}>
                                                            {Math.abs(change)} {change > 0 ? "increase" : change < 0 ? "decrease" : "no change"}
                                                        </Box>
                                                        {change > 0 ? (
                                                            <Arrow length={(Math.abs(change) * 50 / maxAbs) + 5} direction='right'/>
                                                        ) : (change < 0 ? (
                                                            <Arrow length={(Math.abs(change) * 50 / maxAbs) + 5} direction='left'/>
                                                        ) : (
                                                            <Arrow length={10} direction='up' />
                                                        ))} 
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default Effects