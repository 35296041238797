import React, { useState } from 'react'

import { Box, Button, Select, Grid, Paper, Typography, Modal, MenuItem, useTheme, InputLabel, TextField, Snackbar, Alert, List, ListItem, ListItemText, Stepper, Step, StepLabel, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { useRef } from "react";
import { useDropzone } from 'react-dropzone';
import { dataset, integration } from "../../apis/urls"
import { deleteRequest, postRequest, putRequest } from "../../apis/requests";
import { DataGrid } from '@mui/x-data-grid';
import { Check } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "120vh",
  minHeight: '70vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const contentStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: "30px",
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 'auto',
};

const AddDatasetModal = ({open, handleClose, onTriggerSnackbar}) => {

  const authToken = localStorage.getItem('authToken');

  const [page, setPage] = useState(0);
  const [quitDialogOpen, setQuitDialogOpen] = useState(false);

  const [steps, setSteps] = useState([
    'Choose Data Source',
    'Upload or Connect Data',
    'Columns and Data Types'
  ]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleCloseLocally = () => {
    setQuitDialogOpen(true);
  }

  const handleQuitDialogClose = (quitApproved) => {
    if(quitApproved){
        resetFields();
        handleClose();
    }
    setQuitDialogOpen(false);
  }

  const resetFields = () => {
    setPage(0);
    setFileRejectionMessages([]);
    setRequirementsMet(false);
    if (datasetId){
      const url = dataset.delete(datasetId);
      const headers = {
        "Authorization": authToken
      }
      deleteRequest(url, headers)
        .then(response => {
        })
    }
    handleClose();
  };

  const file_requirements = [
    "File size is less than 25 MB",
    "First row is column names",
    "First column is an ID or equivalent"
  ]
  const [requirementsMet, setRequirementsMet] = useState(false);

  const theme = useTheme();

  const maxSize = 26214400;

  const [uploadedObject, setUploadedObject] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileRejectionMessages, setFileRejectionMessages] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      fileRejections.forEach((file) => {
        file.errors.forEach((error) => {
          setFileRejectionMessages([...fileRejectionMessages, error["message"]]);
        });
      })
      setUploadedFiles(acceptedFiles);
    },
    accept: {
      "text/csv": [".csv"]
    },
    minSize:0,
    maxSize:maxSize,
    maxFiles:1
  });

  const [progress, setProgress] = useState(false);

  const [datasetId, setDatasetId] = useState(null);

  const [dataTypeRows, setDataTypeRows] = useState([]);

  const [fileType, setFileType] = useState(null);

  const [mysqlCredentials, setMysqlCredentials] = useState({
    "dbname":null,
    "dbuser":null,
    "dbpassword":null,
    "dbhost":null,
    "dbport":null
  });

  const [mysqlTables, setMysqlTables] = useState([]);
  const [selectedMysqlTableIndex, setSelectedMysqlTableIndex] = useState(null);

  const handleMysqlTableListItemClick = (e, index) => {
    setSelectedMysqlTableIndex(index);
  };

  const handleDataTypeChange = (newType, id) => {
    const newDataTypeRows = dataTypeRows.map(row => {
      if (row.id === id) {
        return { ...row, dataType: newType };
      }
      return row;
    })

    setDataTypeRows(newDataTypeRows);
  }

  const dataTypeColumns = [
    { field: 'columnName', headerName: 'Feature Name', flex: 1 },
    { field: 'dataType', headerName: 'Data Type', flex: 1, renderCell: (params) => {
      return (
        <Select
          id="demo-simple-select"
          value={params.row.dataType}
          sx={{width:"200px", height: "40px", padding:0 }}
          onChange={(event) => handleDataTypeChange(event.target.value, params.row.id)}
        >
          <MenuItem value={"Category"}>Category</MenuItem>
          <MenuItem value={"Number"}>Number</MenuItem>
          <MenuItem value={"DateTime"}>DateTime</MenuItem>
          <MenuItem value={"Boolean"}>Boolean</MenuItem>
        </Select>
      )
    }},
  ]

  const onDataSourceTypeClick = (file_type) => {
    if (file_type === "csv") {
      setFileType("csv");
    }
    else if (file_type === "mysql"){
      setFileType("mysql");
    }
  }

  const handleUpload = () => {
    const dataset_name = document.getElementById("dataset-name-input").value;

    if (dataset_name.length > 0 && requirementsMet) {
      var formdata = new FormData();
      formdata.append('name', dataset_name);

      if(uploadedObject) {
        formdata.append('file', JSON.stringify(uploadedObject));
        formdata.append("file_type", "json");
      }
      else{
        formdata.append('file', uploadedFiles[0], uploadedFiles[0].name);

        let filename = uploadedFiles[0].name;
        let parts = filename.split('.');
        let extension = parts[parts.length - 1];
        formdata.append("file_type", extension);
      }

      setProgress(true);

      const url = dataset.create();
      postRequest(url, {"Authorization": authToken}, formdata)
          .then(response => (response.json()))
          .then(data => {
            if (data && data["dataset_id"]){
              setDatasetId(data["dataset_id"]);
              const descriptive_info = data["descriptive_info"];
              const dataTypes = JSON.parse(descriptive_info)["Data Type"];
              const transformedDataTypes = Object.keys(dataTypes).map(key => {
                  return {
                      id: key,
                      columnName: key,
                      dataType: dataTypes[key]
                  };
              });
              setDataTypeRows(transformedDataTypes);

              handleNext();
            }
            else{
              onTriggerSnackbar("error", data["error"]);
            }
            setProgress(false);            
          })
    }
    else{
      onTriggerSnackbar("error", "Please provide a valid dataset name and meet all requirements.");
    }
    
  }

  const handleMysqlConnection = () => {
    setProgress(true);

    const url = integration.queryDatabase();
    const headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
    const body = JSON.stringify({
      "credentials": mysqlCredentials,
      "query": "SHOW TABLES;"
    })
    postRequest(url, headers, body)
      .then(response => (response.json()))
      .then(data => {
        if(data["data"]){
          const tableNames = []
          Object.keys(data["data"]).map(key => {
            tableNames.push(Object.values(data["data"][key])[0])
          })
          onTriggerSnackbar("success", "Database connection established successfully")
          setMysqlTables(tableNames);
        }
        else{
          onTriggerSnackbar("error", data["error"])
        }
        setProgress(false);
      })
  }

  const fetchMysqlData = () => {
    setProgress(true);

    const url = integration.queryDatabase();
    const headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
    const body = JSON.stringify({
      "credentials": mysqlCredentials,
      "query": `SELECT * FROM ${mysqlTables[selectedMysqlTableIndex]};`
    })
    postRequest(url, headers, body)
      .then(response => {
        if (response.ok){
          return response.json();
        }
      })
      .then(data => {
        if(data){
          if(data["data"]){
            onTriggerSnackbar("success", "Data fetched successfully")
            setUploadedObject((data["data"]));
          }
          else{
            onTriggerSnackbar("error", data["error"])
          }
        }
        setProgress(false);
      })
  }

  const handleSave = () => {
    const url = dataset.setDataTypes();
    
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": authToken
    };
    const body = JSON.stringify({
        dataset_id: datasetId,
        data_types: dataTypeRows
    });

    putRequest(url, headers, body)
      .then(response => {
        if(response.ok) {
          onTriggerSnackbar("success", "Dataset has been saved successfully");
          handleClose();
        }
        else{
          return response.json().then(data => {
            onTriggerSnackbar("error", data);
          });
        }
      });  
  }

  const BoxItem = ({ imageSrc, file_type, title, description }) => {
    return (
      <Paper elevation={3} sx={{ padding: 2, cursor: description != "Coming soon" ? "pointer" : null, border: fileType == file_type ? "2px solid #1760a5" : null }} onClick={() => description != "Coming soon" ? onDataSourceTypeClick(file_type) : null}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={require(`./../../assets/images/${imageSrc}`)} alt="Box Image" style={{ width: 'auto', height: '50px' }} />
          <Typography variant="h6" sx={{ marginTop: 2, fontWeight:400, color:theme.palette.text.dark }}>
            {title}
          </Typography>
          <Box sx={{height:"50px", mt:3}}>
            <Typography variant='h7' fontWeight={300}>
              {description}
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  };

  const boxes = [
    { imageSrc: 'csv.png', file_type:"csv", title: 'CSV Files', description: "Upload a CSV file from your local computer" }, 
    { imageSrc: 'mysql.png', file_type:"mysql", title: 'MySQL', description: "Connect to your MySQL database and use your tables" },
    { imageSrc: 'sheets.png', file_type:"google_sheet", title: 'Google Sheets', description: "Coming soon" },
    { imageSrc: 'sql-server.png', file_type:"sql_server", title: 'SQL Server', description: "Coming soon" },
    { imageSrc: 'dropbox.png', file_type:"dropbox", title: 'Dropbox', description: "Coming soon" },
    { imageSrc: 'airtable.png', file_type:"airtable", title: 'Airtable', description: "Coming soon" },
  ];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Choose Data Source
            </Typography>
            <Grid sx={{mt:2, width:"100%"}} container spacing={2}>
              {boxes.map((box, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <BoxItem imageSrc={box.imageSrc} file_type={box.file_type} title={box.title} description={box.description} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )

      case 1:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Upload or Connect Data
            </Typography>
            {fileType == "csv" ? (
              <Box sx={{display: "flex", flexDirection: "row", height:"50vh"}}>
                <Box sx={{flex:1}}>
                  <Typography variant='h6' sx={{fontWeight:400}}>
                    Requirements
                  </Typography>
                  {file_requirements.map((req) => {
                    return (
                      <Typography variant="subtitle2" fontWeight={300} sx={{display:"flex", color:theme.palette.text.dark, alignItems:"center", mt:1}}>
                        <CheckCircleIcon sx={{mr:1}} />
                        {req}
                      </Typography>
                      )
                  })}
                </Box>
                <Box sx={{ flex:3, }}>
                  <Box sx={{display:"flex", alignItems:"center", justifyContent:"center", width: "100%", height: "10vh", gap:"20px"}}>
                    <Box {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button variant='contained' sx={{textTransform:"none", width:"120px"}}>Upload File</Button>
                    </Box>
                    <Box {...getRootProps()} style={{height: "100%", width: "100%", display:"flex", alignItems:"center", justifyContent:"center", border:"2px dashed gray", backgroundColor: theme.palette.background.lighter}}>
                      <input {...getInputProps()} />
                      <p style={{fontSize:"12pt", color:theme.palette.text.dark}}>or drag and drop here</p>
                    </Box>
                  </Box>
                  {fileRejectionMessages.length > 0 ? (
                    <ul>
                      {fileRejectionMessages.map((message) => {
                        return (<li style={{fontSize:"11pt", color:"red"}}>{message}</li>)
                      })}
                    </ul>
                  ) : (
                    <></>
                  )}
                  {uploadedFiles.length > 0 ? (
                    <>
                      <Box sx={{display:"flex", alignItems:"center", fontSize:"12pt", gap:"5px", marginTop:"20px", fontWeight:100}}>
                        Selected File: <Typography sx={{textDecoration:"underline", color:"textSecondary", fontWeight:100}}>{uploadedFiles[0].name}</Typography> <Typography sx={{color:"gray", fontWeight:100}}>{Math.floor(uploadedFiles[0].size / 1024)} kB</Typography>
                      </Box>
                      <Box sx={{mt:5}}>
                        <TextField id="dataset-name-input" label="Dataset Name" variant="outlined" sx={{width: 500}} size='small' />
                        <InputLabel id="requirement-select-label" sx={{mt:2}}>Are all requirements met?</InputLabel>
                        <Select
                          labelId="requirement-select-label"
                          id="requirement-select"
                          value={requirementsMet}
                          label="Are all requirements met?"
                          onChange={(event) => {
                            setRequirementsMet(event.target.value)
                          }}
                          sx={{
                            width:500,
                          }}
                          size='small'
                        >
                          <MenuItem value={false}>No</MenuItem>
                          <MenuItem value={true}>Yes</MenuItem>
                        </Select>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>) : 
            (fileType == "mysql" ? (
                <Box sx={{display: "flex", flexDirection: "row", height:"50vh"}}>
                  <Box sx={{flex:1}}>
                    <Typography variant='h6' sx={{fontWeight:400}}>
                      Requirements
                    </Typography>
                    {file_requirements.map((req) => {
                      return (
                        <Typography variant="subtitle2" fontWeight={300} sx={{display:"flex", color:theme.palette.text.dark, alignItems:"center", mt:1}}>
                          <CheckCircleIcon sx={{mr:1}} />
                          {req}
                        </Typography>
                        )
                    })}
                  </Box>
                  <Box sx={{ flex:3, position: "relative", display:"flex", flexDirection:"row", gap:"50px", width: "100%", height: "50vh"}}>
                    <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                      <Typography variant='h6' fontWeight={400}>1. Connect to database</Typography>
                      <Typography variant='subtitle2' sx={{fontSize:"10pt", fontWeight:100, color:"#333333"}}>To enable the connection to your database, please ensure that your firewall accepts incoming requests from the following IP address: 188.132.210.9</Typography>
                      <TextField size='small' onChange={(e) => setMysqlCredentials(prevData => ({...prevData, "dbhost": e.target.value}))}
                          label="Database Host" type='text' variant="outlined" sx={{
                              mt: "20px",
                              width:"300px"
                          }} />
                      <TextField size='small' onChange={(e) => setMysqlCredentials(prevData => ({...prevData, "dbport": e.target.value}))}
                          label="Database Port" type='text' variant="outlined" sx={{
                              mt: "20px",
                              width:"300px"
                          }} />
                      <TextField size='small' onChange={(e) => setMysqlCredentials(prevData => ({...prevData, "dbuser": e.target.value}))}
                          label="Database User" type='text' variant="outlined" sx={{
                              mt: "20px",
                              width:"300px"
                          }} />
                      <TextField size='small' onChange={(e) => setMysqlCredentials(prevData => ({...prevData, "dbpassword": e.target.value}))}
                          label="User Password" type='password' variant="outlined" sx={{
                              mt: "20px",
                              width:"300px"
                          }} />
                      <TextField size='small' onChange={(e) => setMysqlCredentials(prevData => ({...prevData, "dbname": e.target.value}))}
                          label="Database Name" type='text' variant="outlined" sx={{
                              mt: "20px",
                              width:"300px"
                          }} />
                      <Box sx={{marginTop:"20px", display:"flex", gap:"20px", alignItems:"center"}}>
                        <Button
                          sx={{backgroundColor:theme.palette.primary.main, alignSelf:"flex-start", width:"120px"}}
                          variant="contained"
                          onClick={() => handleMysqlConnection()}
                        >CONNECT</Button>
                        {progress ? (
                          <CircularProgress />
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>

                    <Box sx={{flex:1, display:"flex", flexDirection:"column"}}>
                      <Typography variant='h6' fontWeight={400}>2. Choose table to work with</Typography>
                      <Typography variant='subtitle2' sx={{fontSize:"10pt", fontWeight:100, color:"#333333"}}>Select table after connection</Typography>
                      <List sx={{maxHeight:"390px", height:"228px", width:"350px", overflow:"scroll", backgroundColor:"white"}}>
                        {mysqlTables ? (
                            mysqlTables.map((table, index) => {
                                return (<ListItem 
                                            sx={{cursor:"pointer", ":hover":{backgroundColor:theme.palette.background.lighter}}}
                                            selected={selectedMysqlTableIndex === index}
                                            onClick={(event) => handleMysqlTableListItemClick(event, index)}>
                                          <ListItemText primary={table} />
                                          {selectedMysqlTableIndex === index ? (
                                            <Check />
                                          ) : (
                                            <></>
                                          )}
                                        </ListItem>)
                            })
                        ) : (
                            <CircularProgress sx={{color: theme.palette.primary.main}} />
                        )}
                      </List>
                      <Button
                        sx={{backgroundColor:theme.palette.primary.main, marginTop:"20px", alignSelf:"flex-start", width:"120px"}}
                        variant="contained"
                        onClick={() => fetchMysqlData()}
                      >FETCH</Button>
                      {uploadedObject ? (
                        <>
                          <Typography fontSize={"10pt"} marginTop={"10px"} color={"green"}>Data fetched successfully</Typography>
                          <Box sx={{mt:1}}>
                            <TextField id="dataset-name-input" label="Dataset Name" variant="outlined" sx={{width: 300}} size='small' />
                            <InputLabel id="requirement-select-label" sx={{mt:1}}>Are all requirements met?</InputLabel>
                            <Select
                              labelId="requirement-select-label"
                              id="requirement-select"
                              value={requirementsMet}
                              label="Are all requirements met?"
                              onChange={(event) => {
                                setRequirementsMet(event.target.value)
                              }}
                              sx={{
                                width:300,
                              }}
                              size='small'
                            >
                              <MenuItem value={false}>No</MenuItem>
                              <MenuItem value={true}>Yes</MenuItem>
                            </Select>
                          </Box>
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <></>
              )
            )}
          </Box>
        )

      case 2:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Columns and Data Types
            </Typography>
            <Box sx={{display: "flex", flexDirection: "column", height: "50vh"}}>
              <DataGrid
                rows={dataTypeRows}
                columns={dataTypeColumns}
                checkboxSelection={false}
                disableRowSelectionOnClick
                isRowSelectable={() => false}
                sx={{fontWeight: 400, fontSize:"11pt", backgroundColor:"white", height:"500px" }}
                />
            </Box>
          </Box>
        )
    }
  }

  return (
    <>
      <Modal open={open} onClose={handleCloseLocally}>
        <Box sx={style}>
          <Stepper activeStep={page}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(page)}
          <Box sx={footerStyle}>
            <Button onClick={handlePrev} disabled={page === 0}>
              Previous
            </Button>
            {(page < steps.length - 2) ? (
              <Button onClick={handleNext}>Next</Button>
            ) : (
              page == steps.length - 2 ? (
                <Button onClick={handleUpload}>Upload</Button>
              ) : (
                <Button onClick={handleSave}>Save</Button>
              )
            )}
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={quitDialogOpen}
        onClose={() => handleQuitDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:"12pt", fontWeight:600, backgroundColor:theme.palette.background.main, pt:5}} id="alert-dialog-title">
            {"Are you sure want to quit? All steps will be discarded."}
        </DialogTitle>
        <DialogContent 
          sx={{backgroundColor:theme.palette.background.main}}>
            <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
              When you quit creating a dataset, you will have to start all steps from the beginning. Be sure before perform this action.
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{backgroundColor:theme.palette.background.main, p:3}}>
            <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleQuitDialogClose(false)}>Cancel</Button>
            <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleQuitDialogClose(true)}>Quit</Button>
        </DialogActions>
      </Dialog>
    </>
    
  )
}

export default AddDatasetModal