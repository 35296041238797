import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slider,
  CircularProgress,
  useTheme,
  Snackbar,
  Alert,
} from '@mui/material';
import { getRequest, postRequest } from '../../apis/requests';
import { dataset, imageDataset, model } from '../../apis/urls';
import { buildConfig } from "./ConfigBuilder";
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  minHeight: '50vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
};

const contentStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: "30px",
};

const footerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 'auto',
};

const layerContainerStyle = {
  display: 'flex',
  flexDirection: "row",
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '20px',
  paddingLeft: "50px",
  paddingRight: "50px",
  overflowX: 'scroll',
};

const layerBoxStyle = {
  width: '150px',
  height: "300px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: '10px',
  margin: '0 10px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  textAlign: 'center',
  backgroundColor: "#eee"
};

const ModelBuildModal = ({ open, handleClose, modelDataParam }) => {

  const authToken = localStorage.getItem("authToken");
  const theme = useTheme();
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("Message");

  const handleSnackbarOpen = (severity, message) => {
      setSnackbarSeverity(severity);
      setSnackbarMessage(message);
      setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
    
        setSnackbarOpen(false);
  }

  const [modelData, setModelData] = useState(modelDataParam);
  const [page, setPage] = useState(0);
  const [dataType, setDataType] = useState('');
  const [mode, setMode] = useState('');
  const [modelName, setModelName] = useState('');
  const [tabularDatasetList, setTabularDatasetList] = useState([]);
  const [imageDatasetList, setImageDatasetList] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [columnToPredict, setColumnToPredict] = useState('');
  const [dataColumns, setDataColumns] = useState([]);
  const [taskTypesByColumn, setTaskTypesByColumn] = useState({});
  const taskTypes = {
      "classification": "CLASSIFICATION",
      "regression": "REGRESSION",
      "object_detection": "OBJECT DETECTION",
      "image_classification": "IMAGE CLASSIFICATION",
      "image_segmentation": "IMAGE SEGMENTATION",
      "unknown": "UNKNOWN"
  };
  const [learningAlgorithms, setLearningAlgorithms] = useState({});
  const [learningAlgorithm, setLearningAlgorithm] = useState(null);
  const [optimizationMetrics, setOptimizationMetrics] = useState({});
  const [optimizationMetric, setOptimizationMetric] = useState(null);

  const validationTypes = {
    "cross_validation": "CROSS VALIDATION",
    "train_validation_holdout": "TRAIN VALIDATION HOLDOUT"
  }
  const [validationType, setValidationType] = useState(null);


  const [taskType, setTaskType] = useState(null);
  const [validationSize, setValidationSize] = useState(20);
  const [testSize, setTestSize] = useState(20);
  const [foldCount, setFoldCount] = useState(5);
  const [quitDialogOpen, setQuitDialogOpen] = useState(false);

  const [layers, setLayers] = useState([{ type: 'dense', params: {} }]);
  const [layerType, setLayerType] = useState('');

  const [steps, setSteps] = useState([
    'Select Data Type',
    'Select Mode',
    'Model Configuration'
  ]);

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const handleChangeDataType = (selectedDataType) => {
    setDataType(selectedDataType);
    if (selectedDataType == "tabular"){
      fetchTabularDatasets();
    }
    else {
      fetchImageDatasets();
    }
  }

  const fetchTabularDatasets = () => {
    const url = dataset.getByUserId();
        const headers = {
            "Content-Type": "application/json",
            "Authorization": localStorage.getItem("authToken"),
        };

        getRequest(url, headers)
            .then(data => {
                setTabularDatasetList(data);
            })
            .catch((error) => console.error("Error:", error));
  }

  const fetchImageDatasets = () => {
    const url = imageDataset.getByUserId();
        const headers = {
            "Authorization": localStorage.getItem("authToken"),
        };

        getRequest(url, headers)
            .then(data => {
                setImageDatasetList(data);
            })
            .catch((error) => console.error("Error:", error));
  }

  const handleSelectedDatasetChanged = (datasetId) => {
    setSelectedDataset(datasetId);
    if (dataType === "tabular") {
      fetchTabularDataInsights(datasetId);
    }
    else if (dataType === "image") {
      const datasetItem = imageDatasetList.find(obj => obj.id === datasetId);
      setTaskType(datasetItem["task_type"]);
      if(datasetItem["task_type"] == "image_classification") {
        setOptimizationMetric("accuracy");
      }
      else {
        setOptimizationMetric("map");
      }
      setValidationType("train_validation_holdout");
      if(mode === "manual"){
        handleTaskTypeChange(datasetItem["task_type"]);
      }
    }
  }

  const fetchTabularDataInsights = (selectedDatasetId) => {
    const url = dataset.getInsightsForModeling(selectedDatasetId);
    const headers = {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("authToken"),
    };

    getRequest(url, headers)
        .then(data => {
            const columnsIdealForPrediction = data["columns_ideal_for_prediction"];
            setTaskTypesByColumn(data["task_types_by_column"]);

            const columnList = [];
            Object.keys(columnsIdealForPrediction).forEach(column => {
                if (taskTypesByColumn[column] == "regression" || columnsIdealForPrediction[column]) {
                    columnList.push(column);
                }
            });

            setDataColumns(columnList);   
        })
        .catch((error) => console.error("Error:", error));
  }

  const handleChangeColumnToPredict = (column) => {
    const selectedColumn = column;
    
    setColumnToPredict(selectedColumn);
    setTaskType(taskTypesByColumn[selectedColumn]);

    if(mode === "manual") {
      handleTaskTypeChange(taskTypesByColumn[selectedColumn]);
    }
}

const handleTaskTypeChange = (taskType) => {

  if (taskType == "classification") {
      setOptimizationMetrics({
          "accuracy": "ACCURACY",
          //"f1": "F1",
          "log_loss": "LOG_LOSS",
          //"roc_auc": "ROC_AUC"
      })

      setLearningAlgorithms({
          "logistic_regression": "LOGISTIC REGRESSION",
          "k_neighbors_classifier": "K-NEAREST NEIGHBORS",
          "svm_classifier": "SUPPORT VECTOR MACHINES",
          "decision_tree_classifier": "DECISION TREES",
          "random_forest_classifier": "RANDOM FOREST",
          "gradient_boosting_classifier": "GRADIENT BOOSTING",
          "ada_boost_classifier": "ADA BOOST",
          "naive_bayes_classifier": "NAIVE BAYES",
          "mlp_classifier": "MULTI LAYER PERCEPTRON"
      })
  }
  else if (taskType == "regression") {
      setOptimizationMetrics({
          "mae": "MAE",
          "mse": "MSE",
          "rmse": "RMSE",
          "r2": "R2"
      })

      setLearningAlgorithms({
          "linear_regression": "LINEAR REGRESSION",
          "lasso_regression": "LASSO REGRESSION",
          "elastic_net": "ELASTIC NET",
          "k_neighbors_regressor": "K-NEAREST NEIGHBORS",
          "svm_regressor": "SUPPORT VECTOR MACHINES",
          "decision_tree_regressor": "DECISION TREES",
          "random_forest_regressor": "RANDOM FOREST",
          "gradient_boosting_regressor": "GRADIENT BOOSTING",
          "mlp_regressor": "MULTI LAYER PERCEPTRON"
      })
  }
  else if (taskType == "object_detection" || taskType == "image_segmentation") {
    setOptimizationMetrics({
      "map": "mAP",
      //"f1": "F1",
      //"log_loss": "LOG_LOSS",
      //"roc_auc": "ROC_AUC"
    })

    setLearningAlgorithms({
        //"custom_cnn": "CUSTOM CNN",
        "yolov8": "YOLOv8",
        "yolov10": "YOLOv10"
    })
  }

  else if (taskType == "image_classification") {
    setOptimizationMetrics({
      "accuracy": "ACCURACY",
      //"f1": "F1",
      //"log_loss": "LOG_LOSS",
      //"roc_auc": "ROC_AUC"
    })

    setLearningAlgorithms({
        //"custom_cnn": "CUSTOM CNN",
        "yolov8": "YOLOv8"
    })
  }
}

const handleChangeLearningAlgorithm = (event) => {
  setLearningAlgorithm(event.target.value);
  if(event.target.value == "custom_cnn") {
    setSteps([
      'Select Data Type',
      'Select Mode',
      'Model Configuration',
      "Model Details",
      "CNN Configuration"
    ]);
  }
}

  const handleCloseLocally = () => {
    setQuitDialogOpen(true);
}

  const handleQuitDialogClose = (quitApproved) => {
    if(quitApproved){
        resetFields();
        handleClose();
    }
    setQuitDialogOpen(false);
}

  const resetFields = () => {
    setPage(0);
    setDataType(null);
    setMode(null);
    setModelName(null);
    setTabularDatasetList([]);
    setSelectedDataset(null);
    setColumnToPredict(null);
    setTaskType(null);
    setOptimizationMetric(null);
    setValidationType(null);
    setValidationSize(20);
    setTestSize(20);
    setFoldCount(5);
    setLearningAlgorithm(null);
    setLayers([{ type: 'dense', params: {} }]);
    setLayerType(null);
    setModelData(null);
  };

  useEffect(() => {
    setModelData(modelDataParam);
  }, [open])

  useEffect(() => {
    if (modelData) {
      handleChangeDataType("tabular");
      setMode("manual");
      setSteps([
        'Select Data Type',
        'Select Mode',
        'Model Configuration',
        "Model Details"
      ]);
      setPage(2);
      setSelectedDataset(modelData["dataset"]);
      fetchTabularDataInsights(modelData["dataset"]);
    }
  }, [modelData]);

  useEffect(() => {
    if (modelData) {
      handleChangeColumnToPredict(modelData["target_feature"]);
    }
  }, [taskTypesByColumn, dataColumns]);

  const handleStartBuildingModel = () => {
    if (selectedDataset && modelName && dataType && (dataType == "image" || columnToPredict) && taskType && mode && (mode == "auto" || (optimizationMetric && validationType && learningAlgorithm))) {
      const modelData = buildConfig({"selectedDataset":selectedDataset, "modelName":modelName, "dataType":dataType, "columnToPredict":columnToPredict, "taskType":taskType, "mode":mode, "optimizationMetric":optimizationMetric, "validationType":validationType, "foldCount":foldCount, "validationSize":validationSize, "testSize":testSize, "learningAlgorithm":learningAlgorithm, "layers":layers})
      const url = model.create();

      const data = JSON.stringify(modelData);
      
      const headers = {
          "Content-Type": "application/json",
          "Authorization": authToken
      };

      postRequest(url, headers, data)
        .then(response => {
            if(response.ok) {
                handleSnackbarOpen("success", "Model is built and ready for training.")
                if(modelDataParam){
                  navigate("/platform/models");
                }
                handleClose();
            }
            else{
                return response.json();
            }
        })
        .then(data => {
        })
        .catch((error) => console.error("Error:", error));
    }
    else{
      handleSnackbarOpen("error", "Please fill all fields with proper values.");
    }
  }

  const addLayer = () => {
    setLayers([...layers, { type: layerType, params: {} }]);
  };

  const removeLayer = (index) => {
    setLayers(layers.filter((_, i) => i !== index));
  };

  const updateLayer = (index, key, value) => {
    const newLayers = [...layers];
    newLayers[index].params[key] = value;
    setLayers(newLayers);
  };

  const renderLayerConfig = (layer, index) => {
    switch (layer.type) {
      case 'dense':
        return (
          <Box key={index} sx={layerBoxStyle}>
            <Typography variant="subtitle1">Dense Layer</Typography>
            <TextField
              label="Number of Neurons"
              type="number"
              fullWidth
              margin="normal"
              value={layer.params.neurons || ''}
              onChange={(e) => updateLayer(index, 'neurons', e.target.value)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Activation Function</InputLabel>
              <Select
                label="Activation Function"
                value={layer.params.activation || ''}
                onChange={(e) => updateLayer(index, 'activation', e.target.value)}
              >
                <MenuItem value="relu">ReLU</MenuItem>
                <MenuItem value="sigmoid">Sigmoid</MenuItem>
                <MenuItem value="tanh">Tanh</MenuItem>
                <MenuItem value="softmax">Softmax</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={() => removeLayer(index)} color="error">Remove Layer</Button>
          </Box>
        );
      case 'bn':
        return (
          <Box key={index} sx={layerBoxStyle}>
            <Typography variant="subtitle1">BN Layer</Typography>
            <TextField
              label="Penalty"
              type="number"
              fullWidth
              margin="normal"
              value={layer.params.penalty || ''}
              onChange={(e) => updateLayer(index, 'penalty', e.target.value)}
            />
            <Button onClick={() => removeLayer(index)} color="error">Remove Layer</Button>
          </Box>
        );
      case 'dropout':
        return (
          <Box key={index} sx={layerBoxStyle}>
            <Typography variant="subtitle1">Dropout Layer</Typography>
            <TextField
              label="Dropout Rate"
              type="number"
              fullWidth
              margin="normal"
              value={layer.params.rate || ''}
              onChange={(e) => updateLayer(index, 'rate', e.target.value)}
            />
            <Button onClick={() => removeLayer(index)} color="error">Remove Layer</Button>
          </Box>
        );
      default:
        return null;
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Select Data Type
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                onClick={() => {
                  handleChangeDataType("tabular");
                  setSteps([
                    'Select Data Type',
                    'Select Mode',
                    'Model Configuration'
                  ]);
                  handleNext();
                }}
                style={{ margin: '0 20px' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={require('./../../assets/images/tabular.png')}
                    alt="Tabular Data"
                    style={{ width: 100, height: 100 }}
                  />
                  <Typography>Tabular Data</Typography>
                </Box>
              </Button>
              <Button
                onClick={() => {
                  handleChangeDataType('image');
                  setSteps([
                    'Select Data Type',
                    'Select Mode',
                    'Model Configuration'
                  ]);
                  handleNext();
                }}
                style={{ margin: '0 20px' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={require('./../../assets/images/image.png')}
                    alt="Image Data"
                    style={{ width: 100, height: 100 }}
                  />
                  <Typography>Image Data</Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Select Mode
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                onClick={() => {
                  setMode('manual');
                  setSteps([
                    'Select Data Type',
                    'Select Mode',
                    'Model Configuration',
                    "Model Details"
                  ]);
                  handleNext();
                }}
                style={{ margin: '0 20px' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={require('./../../assets/images/manual.png')}
                    alt="Manual Mode"
                    style={{ width: 100, height: 100 }}
                  />
                  <Typography>Manual Mode</Typography>
                </Box>
              </Button>
              <Button
                onClick={() => {
                  setMode('auto');
                  setSteps([
                    'Select Data Type',
                    'Select Mode',
                    'Model Configuration',
                  ]);
                  handleNext();
                }}
                style={{ margin: '0 20px' }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={require('./../../assets/images/auto.png')}
                    alt="Auto Mode"
                    style={{ width: 100, height: 100 }}
                  />
                  <Typography>Auto Mode</Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Model Configuration
            </Typography>
            <TextField
              label="Model Name"
              fullWidth
              margin="normal"
              value={modelName}
              onChange={(e) => setModelName(e.target.value)}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Selected Dataset</InputLabel>
              <Select
                label="Selected Dataset"
                value={selectedDataset}
                onChange={(e) => handleSelectedDatasetChanged(e.target.value)}
              >
                {dataType === "tabular" ? (
                  tabularDatasetList ? (
                    tabularDatasetList.map(dataset => {
                      return (
                        <MenuItem value={dataset["id"]}>{dataset["name"]}</MenuItem>
                      )
                    })
                  ) : (
                    <CircularProgress />
                  )
                ) : (
                  imageDatasetList ? (
                    imageDatasetList.map(dataset => {
                      return (
                        <MenuItem value={dataset["id"]}>{dataset["name"]}</MenuItem>
                      )
                    })
                  ) : (
                    <CircularProgress />
                  )
                )}
              </Select>
            </FormControl>
            {dataType === 'tabular' && (
              <>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Column to Predict</InputLabel>
                  <Select
                    label="Column to Predict"
                    value={columnToPredict}
                    onChange={(e) => handleChangeColumnToPredict(e.target.value)}
                  >
                    {dataColumns ? (
                      dataColumns.map(columnName => {
                        return (<MenuItem value={columnName}>{columnName}</MenuItem>)
                      })
                    ) : (
                      <CircularProgress />
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Selected Task Type</InputLabel>
                  <Select
                    label="Selected Task Type"
                    value={taskType || ''}
                    onChange={(e) => setTaskType(e.target.value)}
                    disabled={taskType !== 'UNKNOWN'}
                  >
                  {taskTypes ? (
                      Object.keys(taskTypes).map((key) => {
                          return (<MenuItem value={key}>{taskTypes[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                  </Select>
                </FormControl>
              </>
            )}
            {dataType === 'image' && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Selected Task Type</InputLabel>
                <Select
                  label="Selected Task Type"
                  value={taskType || ''}
                  onChange={(e) => setTaskType(e.target.value)}
                  disabled={taskType !== 'UNKNOWN'}
                >
                  {taskTypes ? (
                      Object.keys(taskTypes).map((key) => {
                          return (<MenuItem value={key}>{taskTypes[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                </Select>
              </FormControl>
            )}
          </Box>
        );
      case 3:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              Model Details
            </Typography>
            {dataType == "tabular" ? (
              <FormControl fullWidth margin="normal">
                <InputLabel>Optimization Metric</InputLabel>
                <Select
                  label="Optimization Metric"
                  value={optimizationMetric}
                  onChange={(event) => {setOptimizationMetric(event.target.value);}}
                >
                  {optimizationMetrics ? (
                      Object.keys(optimizationMetrics).map((key) => {

                          return (<MenuItem value={key}>{optimizationMetrics[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth margin="normal">
                <InputLabel>Optimization Metric</InputLabel>
                <Select
                  label="Optimization Metric"
                  value={optimizationMetric}
                  onChange={(event) => {setOptimizationMetric(event.target.value);}}
                  disabled
                >
                  {optimizationMetrics ? (
                      Object.keys(optimizationMetrics).map((key) => {

                          return (<MenuItem value={key}>{optimizationMetrics[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                </Select>
              </FormControl>
            )}
            {dataType == "tabular" ? (
              <FormControl fullWidth margin="normal">
                <InputLabel>Validation Type</InputLabel>
                <Select
                  label="Validation Type"
                  value={validationType}
                  onChange={(event) => {setValidationType(event.target.value)}}
                >
                  {validationTypes ? (
                      Object.keys(validationTypes).map((key) => {
                          return (<MenuItem value={key}>{validationTypes[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                </Select>
              </FormControl>
            ) : (
              <FormControl fullWidth margin="normal">
                <InputLabel>Validation Type</InputLabel>
                <Select
                  label="Validation Type"
                  value={validationType}
                  onChange={(event) => {setValidationType(event.target.value)}}
                  disabled
                >
                  {validationTypes ? (
                      Object.keys(validationTypes).map((key) => {
                          return (<MenuItem value={key}>{validationTypes[key]}</MenuItem>)
                      })
                  ) : (
                      <CircularProgress />
                  )}
                </Select>
              </FormControl>
            )}
            {validationType === 'train_validation_holdout' && (
              <>
                <Typography gutterBottom fontSize={12} marginLeft={"12px"} marginTop={"5px"} marginBottom={0} color={"#555555"}>Validation Size</Typography>
                <Slider
                  value={validationSize}
                  onChange={(e, newValue) => setValidationSize(newValue)}
                  aria-labelledby="validation-size-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={5}
                  max={40}
                  sx={{border:"1px solid #bbbbbb", paddingY:"25px", borderRadius:"5px", marginTop:0}}
                />
                <Typography gutterBottom fontSize={12} marginLeft={"12px"} marginTop={"5px"} marginBottom={0} color={"#555555"}>Test Size</Typography>
                <Slider
                  value={testSize}
                  onChange={(e, newValue) => setTestSize(newValue)}
                  aria-labelledby="test-size-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={5}
                  max={40}
                  sx={{border:"1px solid #bbbbbb", paddingY:"25px", borderRadius:"5px", marginTop:0}}
                />
              </>
            )}
            {validationType === 'cross_validation' && (
              <>
                <TextField
                  label={"Fold Number"}
                  type="number"
                  value={foldCount}
                  onChange={(e) => setFoldCount(e.target.value)}
                  inputProps={{ min: 2, max: 300 }}
                  fullWidth
                  margin="normal"
                />
                <Typography gutterBottom fontSize={12} marginLeft={"12px"} marginTop={"5px"} marginBottom={0} color={"#555555"}>Test Size</Typography>
                <Slider
                  value={testSize}
                  onChange={(e, newValue) => setTestSize(newValue)}
                  aria-labelledby="test-size-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={5}
                  max={40}
                  sx={{border:"1px solid #bbbbbb", paddingY:"25px", borderRadius:"5px", marginTop:0}}
                />
              </>
            )}
            <FormControl fullWidth margin="normal">
              <InputLabel>Select a Learning Algorithm</InputLabel>
              <Select
                value={learningAlgorithm}
                label="Select a Learning Algorithm"
                onChange={handleChangeLearningAlgorithm}
              >
                {learningAlgorithms ? (
                    Object.keys(learningAlgorithms).map((key) => {

                        return (<MenuItem value={key}>{learningAlgorithms[key]}</MenuItem>)
                    })
                ) : (
                    <CircularProgress />
                )}
              </Select>
            </FormControl>
          </Box>
        );
      case 4:
        return (
          <Box sx={contentStyle}>
            <Typography variant="h6" align="center" gutterBottom>
              CNN Configuration
            </Typography>
            <Box sx={layerContainerStyle}>
              <Box sx={layerBoxStyle}>
                <Typography variant="subtitle1">Input Layer</Typography>
              </Box>
              {layers.map((layer, index) => (
                <Box key={index}>
                  {renderLayerConfig(layer, index)}
                </Box>
              ))}
              <Box sx={layerBoxStyle}>
                <Typography variant="subtitle1">Output Layer</Typography>
              </Box>
            </Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Layer Type</InputLabel>
              <Select
                label="Layer Type"
                value={layerType}
                onChange={(e) => setLayerType(e.target.value)}
              >
                <MenuItem value="dense">Dense</MenuItem>
                <MenuItem value="bn">Batch Normalization</MenuItem>
                <MenuItem value="dropout">Dropout</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={addLayer} disabled={!layerType}>
              Add Layer
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleCloseLocally}>
        <Box sx={style}>
          <Stepper activeStep={page}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent(page)}
          <Box sx={footerStyle}>
            <Button onClick={handlePrev} disabled={page === 0}>
              Previous
            </Button>
            {(page < steps.length - 1) ? (
              (page == 3 && dataType === "image" && mode === "manual") ? (
                <Button onClick={handleNext}>Next</Button>
              ) : (
                <Button onClick={handleNext}>Next</Button>
              )
            ) : (
              <Button onClick={handleStartBuildingModel}>Finish</Button>
            )}
          </Box>
        </Box>
      </Modal>

      <Snackbar 
            open={snackbarOpen} 
            autoHideDuration={5000}
            onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                variant='filled'
                sx={{width:"100%"}}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>

      <Dialog
        open={quitDialogOpen}
        onClose={() => handleQuitDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:"12pt", fontWeight:600, backgroundColor:theme.palette.background.main, pt:5}} id="alert-dialog-title">
            {"Are you sure want to quit? All steps will be discarded."}
        </DialogTitle>
        <DialogContent 
          sx={{backgroundColor:theme.palette.background.main}}>
            <DialogContentText sx={{fontSize:"12pt"}} id="alert-dialog-description">
              When you quit building the model, you will have to start all steps from the beginning. Be sure before perform this action.
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{backgroundColor:theme.palette.background.main, p:3}}>
            <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.warning.main}} onClick={() => handleQuitDialogClose(false)}>Cancel</Button>
            <Button variant="contained" sx={{fontSize:"12pt", backgroundColor:theme.palette.primary.main}} onClick={() => handleQuitDialogClose(true)}>Quit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModelBuildModal;
