import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import overwhelmedImage from "../../assets/images/overwhelmed.jpg";
import lackExperienceImage from "../../assets/images/lack_experience.jpg";
import complexProblemsImage from "../../assets/images/complex_problems.jpg";
import { useNavigate } from 'react-router-dom';

const reasonsForHiring = [
  {
    image: overwhelmedImage,
    title: "Overwhelmed by Data",
    content: "Struggling to make sense of vast amounts of data and extract meaningful insights."
  },
  {
    image: lackExperienceImage,
    title: "Lack of In-House Expertise",
    content: "Missing the specialized skills required for advanced data analysis, AI, and machine learning."
  },
  {
    image: complexProblemsImage,
    title: "Complex Business Problems",
    content: "Facing intricate business challenges that require sophisticated data solutions."
  },
];

const NeedScientist = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: {xs:"80px", md:"200px"},
        marginBottom: "100px",
      }}
    >
      <Typography
        variant="h3"
        fontWeight={400}
        textAlign="center"
        fontSize={{ xs: "7vw", md: "3vw" }} // Responsive font size
      >
        When to Leverage Our Data Scientist Service
      </Typography>
      <Typography
        variant="h5"
        fontWeight={300}
        color="textSecondary"
        marginTop="20px"
        width={{xs:"95%", md:"100%"}}
        fontSize={{ xs: "5vw", md: "1.5vw" }} // Responsive font size
        textAlign="center"
      >
        Consider our expert data scientist service if you encounter any of the following scenarios.
      </Typography>
      <Box sx={{ width: { xs: "90%", md: "60%" }, marginTop: "30px" }}>
        <Grid container spacing={4}>
          {reasonsForHiring.map((reason, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  padding: 2,
                  textAlign: 'center',
                  border: '1px solid #e0e0e0',
                  borderRadius: 2,
                  height: { xs: 'auto', md: 450 }, // Responsive height
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: "center",
                }}
              >
                <img src={reason.image} alt={reason.title} style={{ width: "100%", height: "auto", borderRadius: "5px" }} />
                <Typography variant="h6" sx={{ marginY: 1, fontSize: { xs: "18px", md: "20px" } }}>
                  {reason.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: { xs: "14px", md: "16px" } }}>
                  {reason.content}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
          marginTop: "60px",
          gap: "20px", // Adjust gap for smaller screens
          width: { xs: "90%", md: "60%" },
          paddingY: {xs:"30px", md:"50px"},
          paddingX: {xs:"5%", md:"0"},
          boxSizing: {xs:"border-box", md:"inherit"},
          borderRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e3dcf5",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={400}
          sx={{ fontSize: { xs: "18px", md: "24px" }, marginBottom: { xs: "20px", sm: "0" } }}
        >
          Start driving success with our experts
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#2e1866',
            height: "50px",
            width: { xs: "100%", sm: "200px" }, // Full width on small screens
            ":hover": { backgroundColor: "#7956d1" },
          }}
          onClick={() => navigate("/request_demo")}
        >
          Book a Call
        </Button>
      </Box>
    </Box>
  );
};

export default NeedScientist;
