import React, { useState } from 'react';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  MenuItem, Select, Typography
} from '@mui/material';

const RemoveLabelModal = ({ open, onClose, onRemove, labels }) => {
  const [selectedLabel, setSelectedLabel] = useState('');

  const handleRemove = () => {
    onRemove(selectedLabel);
    setSelectedLabel('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Label</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Select
            value={selectedLabel}
            onChange={(e) => setSelectedLabel(e.target.value)}
            displayEmpty
            renderValue={(value) =>
              value ? (
                <Typography>{labels.find(label => label.name === value)?.name}</Typography>
              ) : (
                <Typography>Select Label to Remove</Typography>
              )
            }
          >
            {labels.map((label) => (
              <MenuItem key={label.name} value={label.name}>
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: label.color,
                    marginRight: 1
                  }}
                />
                {label.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleRemove} disabled={!selectedLabel}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveLabelModal;
