import React from 'react'
import HomeNavbar from '../components/home/HomeNavbar'
import Footer from '../components/home/Footer'
import UseCaseHeader from '../components/useCase/UseCaseHeader'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import ProblemStatement from '../components/useCase/ProblemStatement';

import manufacturingImage from "../assets/images/manufacturing.jpg";
import downtimeImage from "../assets/images/history.png";
import maintenanceCostImage from "../assets/images/maintenance.png";
import lackInsightsImage from "../assets/images/slow.png";
import AllInOneUtimind from '../components/useCase/AllInOneUtimind'
import SolutionVideoDemo from '../components/useCase/SolutionVideoDemo'


const data = {
    "manufacturing": {
        "industry": "Manufacturing",
        "title": "Predictive Maintenance",
        "image": manufacturingImage,
        "problems": [
            {
                "title": "Unexpected Downtime",
                "description": "Machine failures often occur without warning, disrupting production schedules, causing delays, and leading to significant revenue losses.",
                "icon": downtimeImage,
            },
            {
                "title": "High Maintenance Costs",
                "description": "Frequent and unplanned maintenance not only incurs high repair costs but also requires expensive emergency service calls and parts replacement.",
                "icon": maintenanceCostImage,
            },
            {
                "title": "Lack of Predictive Insights",
                "description": "Traditional maintenance strategies are reactive, relying on fixed schedules or post-failure responses, which miss opportunities to prevent breakdowns.",
                "icon": lackInsightsImage
            }
        ]

    },
    "qualityControl": {
        "industry": "",
        "title": "",
        "image": "",

    },
    "customerChurn": {
        "industry": "",
        "title": "",
        "image": "",

    },
    "inventoryManagement": {
        "industry": "",
        "title": "",
        "image": "",

    },
    "finance": {
        "industry": "",
        "title": "",
        "image": "",

    },
    "salesPrediction": {
        "industry": "",
        "title": "",
        "image": "",

    },
    "agriculture": {
        "industry": "",
        "title": "",
        "image": "",

    }
}

const UseCase = () => {

    const location = useLocation();
    let caseField = null;
    if (location.state && location.state["caseField"]) {
        caseField = location.state["caseField"];
    }
    else {
        caseField = "manufacturing";
    }
    caseField = "manufacturing";
    const caseData = data[caseField];
  
    return (
        <Box>
            <HomeNavbar />
            <UseCaseHeader caseIndustry={caseData["industry"]} caseTitle={caseData["title"]} caseSubtitle={caseData["subtitle"]} caseImage={caseData["image"]} />
            <ProblemStatement problemsData={caseData["problems"]} />
            <SolutionVideoDemo />
            <AllInOneUtimind />
            <Footer />
        </Box>
    )
}

export default UseCase