import { Analytics, Api, Assignment, Dashboard, Dataset, Group, Home, Image, ManageHistory, Settings, Support } from '@mui/icons-material'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'

const Sidebar = () => {
  return (
    <Box
      flex={1}
      padding={2}
      sx={{ display: { xs: "none", sm: "block" } }}>
      <List>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/'>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/datasets'>
            <ListItemIcon>
              <Dataset />
            </ListItemIcon>
            <ListItemText primary="Tabular Datasets" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/image_datasets'>
            <ListItemIcon>
              <Image />
            </ListItemIcon>
            <ListItemText primary="Image Datasets" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/models'>
            <ListItemIcon>
              <Analytics />
            </ListItemIcon>
            <ListItemText primary="Models" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/apis'>
            <ListItemIcon>
              <Api />
            </ListItemIcon>
            <ListItemText primary="APIs" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/project_management'>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary="Project Management" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/team_management'>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText primary="Team Management" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component="a" href='/platform/help'>
            <ListItemIcon>
              <Support />
            </ListItemIcon>
            <ListItemText primary="Support Tickets" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}

export default Sidebar