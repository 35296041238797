import { Alert, Box, Button, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react'
import { postRequest } from "../apis/requests";
import { user } from "../apis/urls";
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import utienceLogo from "../assets/images/utience_logo_primary.svg";
import loginBackground from "../assets/images/login_background.jpg";

const EmailBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "450px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    gap: "50px"
  }));

const ForgotPassword = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isInProgress, setIsInProgress] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSendPasswordResetLink = () => {
        setIsInProgress(true);
        const url = user.send_password_reset_token();
        const headers = {
            'Content-Type': 'application/json'
        }
        const body = JSON.stringify({
            "email": email
        })
        postRequest(url, headers, body)
            .then(response => {
                if (response.ok){
                    setSnackbarOpen(true);
                    setTimeout(
                        () => navigate("/platform/login"), 
                        3000
                    );
                }
            })
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
      
          setSnackbarOpen(false);
    }

  return (
    <Box sx={{ width:"100%", height:"100%", backgroundImage:`url(${loginBackground})`, backgroundRepeat:"no-repeat", backgroundSize:"cover"}}>
        <EmailBox bgcolor={theme.palette.background.lighter}>
            <Box sx={{width:"90%", height:"100px", display:"flex", justifyContent:"center"}}>
                <img src={utienceLogo} style={{height:"100px", width:"auto"}} />
            </Box>
            <Box sx={{display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                    borderRadius: "10px"}}>
                <Typography variant="h6" sx={{color: theme.palette.text.dark}}>Password Reset Here</Typography>
                <TextField onChange={(e) => setEmail(e.target.value)} label="Email" type='email' variant="outlined" sx={{
                    mt: 4,
                    width:"90%"
                    }} />
                <Button onClick={handleSendPasswordResetLink} disabled={isInProgress} variant='contained' sx={{
                    mt: 2,
                    height: "55px",
                    width: "90%"
                }}>
                    Send Password Reset Link
                </Button>
            </Box>
        </EmailBox>
        <Snackbar open={snackbarOpen} onClose={handleSnackbarClose}>
            <Alert
                onClose={handleSnackbarClose}
                severity='success'
                variant='filled'
                sx={{width:"100%"}}
            >
                Password reset link is sent. Check your email. You'll be redirected to login page.
            </Alert>
        </Snackbar>
    </Box>
  )
}

export default ForgotPassword