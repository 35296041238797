import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import HomeNavbar from '../components/home/HomeNavbar'
import Footer from '../components/home/Footer'
import { Link } from 'react-router-dom'

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

  return (
    <Box>
        <HomeNavbar />
        <Box sx={{display:"flex", flexDirection:"column", paddingX:{xs:"2%", md:"25%"}, paddingY:{xs:"20px", md:"100px"}, alignItems:"flex-start"}}>
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>

            <Typography variant="body1" paragraph>
                Effective Date: September 9, 2024 
            </Typography>

            <Typography variant="body1" paragraph>
                Welcome to Utimind, a brand of Utience Technology Inc. ("we," "our," or "us"). Your privacy is important to us. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our website, platform, and services (collectively, "Services"). By accessing or using our Services, you consent to the collection and use of your information as described in this Privacy Policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
                We collect various types of information to provide and improve our Services:
            </Typography>
            <Typography variant="body1" component="ul">
                <li><strong>Personal Information:</strong> Information that identifies you, such as your name, email address, phone number, and payment information.</li>
                <li><strong>Account Information:</strong> Details you provide when you create an account, including username, password, and profile information.</li>
                <li><strong>Usage Data:</strong> Information about how you interact with our Services, including your IP address, browser type, pages visited, and actions taken within the platform.</li>
                <li><strong>Data You Provide:</strong> Any data you upload, annotate, or otherwise handle within the Utimind platform, including datasets and files.</li>
            </Typography>

            <Typography variant="h6" gutterBottom>
                2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
                We use your information for the following purposes:
            </Typography>
            <Typography variant="body1" component="ul">
                <li>To provide, maintain, and improve our Services.</li>
                <li>To manage your account and provide customer support.</li>
                <li>To communicate with you about updates, promotions, and other relevant information.</li>
                <li>To analyze usage and performance to enhance user experience.</li>
                <li>To ensure the security and integrity of our Services.</li>
            </Typography>

            <Typography variant="h6" gutterBottom>
                3. How We Share Your Information
            </Typography>
            <Typography variant="body1" paragraph>
                We do not sell your personal information to third parties. We may share your information with:
            </Typography>
            <Typography variant="body1" component="ul">
                <li><strong>Service Providers:</strong> Third-party vendors who assist us in operating our Services, such as payment processors, cloud storage providers, and customer support tools.</li>
                <li><strong>Legal Compliance:</strong> If required by law, we may disclose your information to comply with legal obligations, protect our rights, or respond to government requests.</li>
                <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of that business change.</li>
            </Typography>

            <Typography variant="h6" gutterBottom>
                4. Data Security
            </Typography>
            <Typography variant="body1" paragraph>
                We take the security of your data seriously and implement various measures to protect your information:
            </Typography>
            <Typography variant="body1" component="ul">
                <li><strong>SSL Encryption:</strong> We use SSL (Secure Sockets Layer) to encrypt data during transmission between your device and our platform.</li>
                <li><strong>Data Storage:</strong> While data is encrypted during transmission, data stored on our servers is not encrypted. We are committed to continuously improving our data security practices, including exploring enhanced encryption options to better protect your data.</li>
            </Typography>

            <Typography variant="h6" gutterBottom>
                5. Data Retention
            </Typography>
            <Typography variant="body1" paragraph>
                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
            </Typography>

            <Typography variant="h6" gutterBottom>
                6. Your Rights and Choices
            </Typography>
            <Typography variant="body1" paragraph>
                Depending on your location, you may have the following rights regarding your personal information:
            </Typography>
            <Typography variant="body1" component="ul">
                <li><strong>Access and Update:</strong> You can access and update your personal information through your account settings.</li>
                <li><strong>Deletion:</strong> You may request the deletion of your personal information, subject to our legal obligations.</li>
                <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications by following the unsubscribe instructions in our emails.</li>
            </Typography>

            <Typography variant="h6" gutterBottom>
                7. Cookies and Tracking Technologies
            </Typography>
            <Typography variant="body1" paragraph>
                We use cookies and similar technologies to collect usage data, enhance your experience, and analyze trends. You can manage your cookie preferences through your browser settings.
            </Typography>

            <Typography variant="h6" gutterBottom>
                8. Third-Party Links
            </Typography>
            <Typography variant="body1" paragraph>
                Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies.
            </Typography>

            <Typography variant="h6" gutterBottom>
                9. Children's Privacy
            </Typography>
            <Typography variant="body1" paragraph>
                Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected information from a child under 13, we will take steps to delete such information.
            </Typography>

            <Typography variant="h6" gutterBottom>
                10. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide additional notice. Your continued use of the Services after the changes become effective will constitute your acceptance of the revised Privacy Policy.
            </Typography>

            <Typography variant="h6" gutterBottom>
                11. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions or concerns about this Privacy Policy, please contact us at <Link href="mailto:contact@utience.com">contact@utience.com</Link>.
            </Typography>

            <Typography variant="body1" paragraph>
                Utience Technology Inc., Istanbul, Turkey.
            </Typography>
        </Box>
        <Footer />
    </Box>
  )
}

export default Privacy